import {
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
  Input as ChakraInput,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Checkbox,
  Spinner
} from '@chakra-ui/react'
import { SolidButton } from '../../../components/Buttons/SolidButton'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../../services/api'
import { useHistory } from 'react-router'
import { useErrors } from '../../../hooks/useErrors'

import { Input } from '../../../components/Forms/Inputs/Input'
import { Select } from '../../../components/Forms/Selects/Select'
import { useWorkingCompany } from '../../../hooks/useWorkingCompany'
import { formatInputDate } from '../../../utils/Date/formatInputDate'
import moneyToBackend from '../../../utils/moneyToBackend'
import { HasPermission, useProfile } from '../../../hooks/useProfile'
import { useEffect, useState } from 'react'
import { isAuthenticated } from '../../../services/auth'
import { redirectMessages } from '../../../utils/redirectMessages'
import { ReactSelect, SelectOption } from '../../../components/Forms/ReactSelect'
import { LeadsFilterData, useLeads } from '../../../hooks/useLeads'
import { City, Customer, Lead, State, User } from '../../../types'
import { useStates } from '../../../hooks/useStates'
import { useCities } from '../../../hooks/useCities'
import { useWorkingBranch } from '../../../hooks/useWorkingBranch'

interface NewSaleModalProps {
  isOpen: boolean
  onRequestClose: () => void
  toAddLeadData?: toAddSaleLeadData;
  toAddCustomerData?: Customer;
  afterCreate?: () => void
}

export interface toAddSaleLeadData {
  id: number
  name: string
}

export interface CreateNewCustomerFormData {
  company_id: number;
  branch_id?: number;
  seller_id?: number;

  name?: string;
  email?: string;
  phone?: string;
  cpf_cnpj?: string;
  type_customer?: "PF" | "PJ";
  birth_date?: string;
  civil_status: string;
  state_id: number;
  city_id: number;
  cep: string;
  address: string;
  neighborhood: string;
  number?: string;
}

const CreateNewCustomerFormSchema = yup.object().shape({
  name: yup.string().required('Nome do cliente obrigatório'),
  email: yup.string().required('E-mail do cliente obrigatório'),
  phone: yup.string().required('Telefone do cliente obrigatório'),
  cpf_cnpj: yup.string().required('Informe um CPF ou CNPJ'),
  birth_date: yup.string().nullable(),
  civil_status: yup.string().required('Informe o estado civil'),
  state_id: yup.number().required('Informe o estado'),
  city_id: yup.string().required('Informe a cidade'),
  cep: yup.string().required('Informe o CEP'),
  address: yup.string().required('Informe o endereço'),
  neighborhood: yup.string().required('Informe o bairro'),
  number: yup.string().required('Informe o número'),
})

export function NewCustomerModal({
  isOpen,
  onRequestClose,
  afterCreate,
  toAddLeadData,
  toAddCustomerData
}: NewSaleModalProps) {
  const workingCompany = useWorkingCompany();
  const workingBranch = useWorkingBranch();
  const { profile, permissions } = useProfile()

  const history = useHistory()
  const toast = useToast()
  const { showErrors } = useErrors()

  const { register, handleSubmit, control, reset, watch, formState } =
    useForm<CreateNewCustomerFormData>({
      resolver: yupResolver(CreateNewCustomerFormSchema)
    })

  const handleCreateNewPayment = async (customerData: CreateNewCustomerFormData) => {
    try {
      if (!workingCompany.company) {
        toast({
          title: 'Ops',
          description: `Seleciona uma empresa para trabalhar`,
          status: 'warning',
          duration: 12000,
          isClosable: true
        })

        return
      }

      if (!profile) {
        return
      }

      //const isManager = HasPermission(permissions, 'Vendas Completo');

      const newCustomerData = {
        company_id: workingCompany.company.id,
        branch_id: workingBranch.branch && workingBranch.branch.id,
        cpf_cnpj: customerData.cpf_cnpj,
        type_customer: isPF ? 'PF' : 'PJ',
        name: customerData.name,
        email: customerData.email,
        phone: customerData.phone,
        birth_date: customerData.birth_date,
        civil_status: customerData.civil_status,
        city_id: customerData.city_id,
        state_id: customerData.state_id,
        cep: customerData.cep,
        address: customerData.address,
        neighborhood: customerData.neighborhood,
        number: customerData.number,
      };

      const response = await api.post('/customers', newCustomerData)

      toast({
        title: 'Sucesso',
        description: `Cliente cadastrado`,
        status: 'success',
        duration: 12000,
        isClosable: true
      })

      await api.post('/logs/store', {
        user: profile.id,
        company: workingCompany.company.id,
        action: `Cadastrou um novo cliente`
      })

      onRequestClose()
      if(afterCreate){
        afterCreate();
      }
      reset()
    } catch (error: any) {
      showErrors(error, toast)

      if (error.response.data.access) {
        history.push('/')
      }
    }
  }

  const states = useStates();

  const selectedState = watch('state_id');
  const cities = useCities({state_id: selectedState ? selectedState : 1});

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push({
        pathname: '/',
        state: redirectMessages.auth
      })
    }
  }, [isOpen]);

  const [otherValue, setOtherValue] = useState(false);
  const [isPF, setIsPF] = useState(true);

  const [users, setUsers] = useState<User[]>([]);

    const loadUsers = async () => {
        const { data } = await api.get('/users', {
            // params: {
            //     role: 5
            // }
        });

        setUsers(data);
    }

    useEffect(() => {
        loadUsers();
    }, []);

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxH="calc(100vh - 120px)"
        overflow="auto"
        as="form"
        borderRadius="24px"
        onSubmit={handleSubmit(handleCreateNewPayment)}
      >
        <ModalHeader p="10" fontWeight="700" fontSize="2xl">
          Cadastrar novo cliente
          {
            toAddCustomerData && (
              <Text fontSize="md" fontWeight="normal" color="gray.700">Adicionar plano ao cliente <b>{toAddCustomerData.name}</b></Text>
            )
          }
        </ModalHeader>

        <ModalCloseButton top="10" right="5" />

        <ModalBody pl="10" pr="10">
          <Stack spacing="6">
            

            {/* <HStack spacing="4" align="baseline">
                <Input register={register} name="contract" type="text" placeholder="Contrato" focusBorderColor="blue.800" variant="outline" error={formState.errors.contract}/>

                <Input register={register} name="group" type="text" placeholder="Grupo" focusBorderColor="blue.800" variant="outline" error={formState.errors.group}/>

                <Input register={register} name="quota" type="text" placeholder="Cota" focusBorderColor="blue.800" variant="outline" mask="" error={formState.errors.quota}/>
            </HStack> */}

            <Select register={register} defaultValue="true" h="45px" name="seller_id" error={formState.errors.seller_id} w="100%"  placeholder="Vendedor(a)" maxW="200px" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="filled" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full">
                {
                    users.map((user: User) => {
                        return (
                            <option value={user.id}>{user.name}</option>
                        )
                    })
                }
            </Select>
            {
              !toAddCustomerData && (
                <>
                  <HStack spacing="4" alignItems="flex-start">
                    <Checkbox isChecked={isPF} onChange={() => setIsPF(true)}>Pessoa física</Checkbox>
                    <Checkbox isChecked={!isPF} onChange={() => setIsPF(false)}>Pessoa jurídica</Checkbox>
                  </HStack>

                  <Input
                      register={register}
                      name="cpf_cnpj"
                      type="text"
                      placeholder={isPF ? "CPF" : "CNPJ"}
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask={isPF ? "cpf" : "cnpj"}
                      error={formState.errors.cpf_cnpj}
                    />

                  <Input
                      register={register}
                      name="name"
                      type="text"
                      placeholder="Nome completo"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.name}
                    />

                  <HStack spacing="4" alignItems="flex-start">
                    <Input
                      register={register}
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.email}
                    />

                    <Input
                      register={register}
                      name="phone"
                      type="text"
                      placeholder="Telefone"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask="phone"
                      error={formState.errors.phone}
                    />
                  </HStack>

                  <HStack spacing="4" alignItems="flex-start">
                    <Select
                      register={register}
                      h="45px"
                      name="state_id"
                      value="0"
                      w="100%"
                      fontSize="sm"
                      focusBorderColor="blue.800"
                      bg="gray.400"
                      variant="outline"
                      _hover={{ bgColor: 'gray.500' }}
                      size="lg"
                      borderRadius="full"
                      placeholder="Estado"
                      error={formState.errors.state_id}
                    >
                      {!states.isLoading &&
                        !states.error &&
                        states.data.map((state: State) => {
                          return (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          )
                        })}
                    </Select>

                    {cities.isLoading ? (
                      <Flex justify="center" mt="4">
                        <Spinner />
                      </Flex>
                    ) : cities.isError ? (
                      <Text fontSize="11px">Erro listar as cidades</Text>
                    ) : (
                      cities.data?.length === 0 && (
                        <Text fontSize="11px">Selecione um estado.</Text>
                      )
                    )}
                    {
                      (!cities.isLoading && !cities.isError && cities.data?.length !== 0) && (
                        <Select
                          register={register}
                          h="45px"
                          name="city_id"
                          value="0"
                          w="100%"
                          fontSize="sm"
                          focusBorderColor="blue.800"
                          bg="gray.400"
                          variant="outline"
                          _hover={{ bgColor: 'gray.500' }}
                          size="lg"
                          borderRadius="full"
                          placeholder="Cidade"
                          error={formState.errors.city_id}
                        >
                          {!cities.isLoading &&
                            !cities.error &&
                            cities.data.map((city: City) => {
                              return (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              )
                            })}
                        </Select>
                      )
                    }
                  </HStack>

                  <HStack spacing="4" alignItems="flex-start">
                    <Input
                      register={register}
                      name="birth_date"
                      type="date"
                      placeholder="Data de nascimento"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.birth_date}
                    />
                    <Input
                      register={register}
                      name="civil_status"
                      type="text"
                      placeholder="Estado Civil"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.civil_status}
                    />
                  </HStack>

                  <HStack spacing="4" alignItems="flex-start">
                    <Input
                      register={register}
                      name="cep"
                      type="text"
                      placeholder="CEP"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask="cep"
                      error={formState.errors.cep}
                    />
                    <Input
                      register={register}
                      name="address"
                      type="text"
                      placeholder="Rua"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.address}
                    />
                  </HStack>

                  <HStack spacing="4" alignItems="flex-start">
                    <Input
                      register={register}
                      name="neighborhood"
                      type="text"
                      placeholder="Bairro"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.neighborhood}
                    />
                    <Input
                      register={register}
                      name="number"
                      type="text"
                      placeholder="Número"
                      focusBorderColor="blue.800"
                      variant="outline"
                      mask=""
                      error={formState.errors.number}
                    />
                  </HStack>
                </>
              )
            }
            
          </Stack>
        </ModalBody>

        <ModalFooter p="10">
          <SolidButton
            mr="6"
            color="white"
            bg="blue.800"
            colorScheme="blue"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Cadastrar
          </SolidButton>

          <Link onClick={onRequestClose} color="gray.700" fontSize="14px">
            Cancelar
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
