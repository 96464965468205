import { Box, Flex, HStack, useToast, Modal, ModalBody, Text, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Select as ChakraSelect, Input as ChakraInput, ModalFooter, Link, FormControl, FormLabel, FormErrorMessage, Heading, Divider } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router";
import { isJSDocNullableType } from "typescript";
import * as yup from 'yup';
import { SolidButton } from "../../../components/Buttons/SolidButton";
import { ControlledInput } from "../../../components/Forms/Inputs/ControlledInput";
import { Input } from "../../../components/Forms/Inputs/Input";
import { ReactSelect, SelectOption } from "../../../components/Forms/ReactSelect";
import { ControlledSelect } from "../../../components/Forms/Selects/ControlledSelect";
import { Select } from "../../../components/Forms/Selects/Select";
import { useCities } from "../../../hooks/useCities";
import { useErrors } from "../../../hooks/useErrors";
import { useProfile } from "../../../hooks/useProfile";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { api } from "../../../services/api";
import { Broker, Company, Customer, Investor, Partner, Quota, User } from "../../../types";
import { formatInputDate } from "../../../utils/Date/formatInputDate";
import moneyToBackend from "../../../utils/moneyToBackend";

interface NewQuotaModalProps{
    isOpen: boolean;
    onRequestClose: () => void;
    afterCreate: () => void;
}

export interface CreateNewQuota{
    id: number;
    sold: boolean;
    company: number;
    segment: string;
    value?: string;
    credit: string;
    parcel: string;
    common_fund?: string;
    deadline: number;
    group: string;
    quota: string;
    cost: string;
    partner_cost?: string;
    partner_id?: number;
    partner_percent?: string;
    investor_id?: number;
    investor_percent?: string;
    investor_cost?: string;
    passed_cost?: string;
    total_cost?: string;
    seller?: string;
    cpf_cnpj: string;
    paid_percent: string;
    is_contemplated?: boolean;
    is_hot?: boolean;
    tax?: string;
    contemplated_type: string;
    month_adjust_number?: number;
    adjust_index?: string;
    description?: string;
    purchase_date: string;
    parcel_deadline: string;

    owner_type?: string;
    owner_id?: number;

    purchased_of_type?: string;
    purchased_of_id?: number;

    purchased_by_id?: number;

    supervisor_commission?: string;
    supervisor_id?: number;

    coordinator_commission?: string;
    coordinator_id?: number;

    created_at?: Date;
    updated_at?: Date;
}

const CreateNewQuotaFormSchema = yup.object().shape({
    segment: yup.string().required('Selecione o tipo da carta de crédito'),
    description: yup.string(),
    seller: yup.string().nullable(),//.required("De quem foi comprada a carta?"),
    contemplated_type: yup.string().nullable(),//.required("Qual o tipo de contemplação?"),
    is_contemplated: yup.boolean().required("É contemplado?"),
    is_hot: yup.boolean().required("Tem destaque?"),
    credit: yup.string().required("Informe o valor do crédito"),
    deadline: yup.string().nullable(),//.required("Informe o prazo"),
    parcel: yup.string().nullable(),//.required("Informe a parcela"),
    value: yup.string().nullable(),
    cost: yup.string().nullable(),//.required("Informe o custo"),
    total_cost: yup.string().nullable(),//.required("Informe o custo total"),
    cpf_cnpj: yup.string().nullable(),//.required("Qual o cpf ou cnpj proprietário?"),
    partner_id: yup.string().nullable(),
    partner_cost: yup.string().nullable(),
    investor_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    investor_percent: yup.string().nullable(),
    investor_cost: yup.string().nullable(),
    passed_cost: yup.string().nullable(),
    paid_percent: yup.string().nullable(),//.required("Diga qual o percentual pago"),
    partner_percent: yup.string().nullable(),
    purchase_date: yup.string().nullable(),//.required("Selecione a data de compra"),
    group: yup.string().nullable(),//.required("Informe o grupo"),
    quota: yup.string().nullable(),//.required("Informe a cota"),
    tax: yup.string().nullable(),
    month_adjust_number: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    adjust_index: yup.string().nullable(),

    owner_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    owner_type: yup.string().nullable(),

    purchased_of_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    purchased_of_type: yup.string().nullable(),

    purchased_by_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),

    supervisor_commission: yup.string().nullable(),
    supervisor_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),

    coordinator_commission: yup.string().nullable(),
    coordinator_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
});

export function NewQuotaModal({ isOpen, onRequestClose, afterCreate } : NewQuotaModalProps){
    const workingCompany = useWorkingCompany();
    const {profile} = useProfile();

    const history = useHistory();
    const toast = useToast();
    const { showErrors } = useErrors();

    const { register, handleSubmit, control, watch, reset, formState, setValue} = useForm<CreateNewQuota>({
        resolver: yupResolver(CreateNewQuotaFormSchema),
    });

    //const watchCost = watch('cost');

    const watchCost = useWatch({
        control,
        name: 'cost',
    });

    const watchCredit = useWatch({
        control,
        name: 'credit',
    });

    const watchPaidPercent = useWatch({
        control,
        name: 'paid_percent',
    });

    const watchPartnerCost = useWatch({
        control,
        name: 'partner_cost',
    });

    const watchTotalCost = useWatch({
        control,
        name: 'total_cost',
    });

    const [paidPercent, setPaidPercent] = useState<number>();
    const [partnerPercent, setPartnerPercent] = useState<number>();
    const [investorPercent, setInvestorPercent] = useState<number>();
    const [totalCost, setTotalCost] = useState<string>();

    useEffect(() => {
        if(watchCost && watchCredit){
            const selectedCost = parseFloat(moneyToBackend(watchCost));
            const selectedCredit = parseFloat(moneyToBackend(watchCredit));

            const paidParcent = (selectedCost * 100) / selectedCredit;

            setPaidPercent(paidParcent);
            //setValue('paid_percent', paidParcent.toString(), { shouldValidate: true });
        }
    }, [watchCost, watchCredit]);

    useEffect(() => {
        const partnerCost = watch('partner_cost') === "" ? "0" : watch('partner_cost');
        const coordinatorCommission = watch('coordinator_commission') === "" ? "0" : watch('coordinator_commission');
        const supervisorCommission = watch('supervisor_commission') === "" ? "0" : watch('supervisor_commission');
        const investorCost = watch('investor_cost') === "" ? "0" : watch('investor_cost');

        if(watch('cost') && moneyToBackend(watch('cost')) || (partnerCost && moneyToBackend(partnerCost)) || (investorCost && moneyToBackend(investorCost)) || (supervisorCommission && moneyToBackend(supervisorCommission)) || (coordinatorCommission && moneyToBackend(coordinatorCommission))){
            const partnerCost = watch('partner_cost') === "" ? "0" : watch('partner_cost');
            const investorCost = watch('investor_cost') === "" ? "0" : watch('investor_cost');
            const cost = watch('cost') === "" ? "0" : watch('cost');
            const credit = watch('credit') === "" ? "0" : watch('credit');
            
            if(partnerCost !== undefined && investorCost !== undefined && supervisorCommission !== undefined && coordinatorCommission !== undefined){
                const selectedCost = parseFloat(moneyToBackend(cost));
                const selectedPartnerCost = parseFloat(moneyToBackend(partnerCost));
                const selectedInvestorCost = parseFloat(moneyToBackend(investorCost));
                const selectedSupervisorCommission = parseFloat(moneyToBackend(supervisorCommission));
                const selectedCoordinatorCommission = parseFloat(moneyToBackend(coordinatorCommission));
                const selectedCredit = parseFloat(moneyToBackend(credit));

                const totalCost = (selectedCost + selectedPartnerCost + selectedInvestorCost + selectedSupervisorCommission + selectedCoordinatorCommission).toFixed(2);
                const newPaidPercent = ((selectedCost + selectedPartnerCost)*100)/selectedCredit;
                const newInvestorPercent = ((selectedCost + selectedInvestorCost)*100)/selectedCredit;
                //setValue('total_cost', totalCost.toString(), { shouldValidate: true });
                console.log(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(parseFloat(totalCost)));
                //setTotalCost(totalCost.toString());
                setTotalCost(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(parseFloat(totalCost)));
                setPaidPercent(newPaidPercent);
                setInvestorPercent(selectedInvestorCost === 0 ? 0 : (parseFloat(totalCost) === selectedInvestorCost ? 100 : (selectedInvestorCost * 100)/parseFloat(totalCost)));
                setPartnerPercent(selectedPartnerCost === 0 ? 0 : (parseFloat(totalCost) === selectedPartnerCost ? 100 : (selectedPartnerCost * 100)/parseFloat(totalCost)));

                return;
            }


            setTotalCost(watch('cost'));
            //setValue('total_cost', watchCost, { shouldValidate: true });
        }
    }, [watch('cost'), watch('partner_cost'), watch('investor_cost'), watch('coordinator_commission'), watch('supervisor_commission')]);
    

    function includeAndFormatData(quotaData: CreateNewQuota): CreateNewQuota{
        quotaData.value = ((quotaData.value != null && quotaData.value != "") ? moneyToBackend(quotaData.value) : '');
        quotaData.credit = moneyToBackend(quotaData.credit);
        quotaData.cost = moneyToBackend(quotaData.cost);

        console.log(((quotaData.total_cost != null && quotaData.total_cost != "") ? moneyToBackend(quotaData.total_cost) : ''));

        quotaData.partner_cost = ((quotaData.partner_cost != null && quotaData.partner_cost != "") ? moneyToBackend(quotaData.partner_cost) : '');
        quotaData.passed_cost = ((quotaData.passed_cost != null && quotaData.passed_cost != "") ? moneyToBackend(quotaData.passed_cost) : '');
        quotaData.investor_cost = ((quotaData.investor_cost != null && quotaData.investor_cost != "") ? moneyToBackend(quotaData.investor_cost) : '');
        quotaData.coordinator_commission = ((quotaData.coordinator_commission != null && quotaData.coordinator_commission != "") ? moneyToBackend(quotaData.coordinator_commission) : '');
        quotaData.supervisor_commission = ((quotaData.supervisor_commission != null && quotaData.supervisor_commission != "") ? moneyToBackend(quotaData.supervisor_commission) : '');

        quotaData.total_cost = ((quotaData.total_cost != null && quotaData.total_cost != "") ? moneyToBackend(quotaData.total_cost) : '').replaceAll(' ', '');
        quotaData.parcel = ((quotaData.parcel != null && quotaData.parcel != "") ? moneyToBackend(quotaData.parcel) : '');
        quotaData.common_fund = ((quotaData.common_fund != null && quotaData.common_fund != "") ? moneyToBackend(quotaData.common_fund) : '');
        quotaData.tax = ((quotaData.tax != null && quotaData.tax != "") ? moneyToBackend(quotaData.tax) : '');

        //quotaData.purchase_date = formatInputDate(quotaData.purchase_date);
        console.log(quotaData);

        if(!workingCompany.company){
            return quotaData;
        }
        
        quotaData.company = workingCompany.company?.id;

        return quotaData;
    }

    const handleCreateNewQuota = async (quotaData : CreateNewQuota) => {
        try{
            if(!workingCompany.company){
                toast({
                    title: "Ué",
                    description: `Seleciona uma empresa para trabalhar`,
                    status: "warning",
                    duration: 12000,
                    isClosable: true,
                });

                return;
            }

            quotaData = includeAndFormatData(quotaData);

            console.log(quotaData);

            const response = await api.post('/ready_quotas/store', quotaData);

            toast({
                title: "Sucesso",
                description: `A Cota ${quotaData.group}-${quotaData.quota} foi cadastrada.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            onRequestClose();
            afterCreate();
            reset();
        }catch(error:any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [nullOptions, setNullOptions] = useState<SelectOption[]>([{value: "", label: ""}]);
    const [partnersOptions, setPartnersOptions] = useState<SelectOption[]>();
    const [investorsOptions, setInvestorsOptions] = useState<SelectOption[]>();
    const [brokersOptions, setBrokersOptions] = useState<SelectOption[]>();
    const [customersOptions, setCustomersOptions] = useState<SelectOption[]>();
    const [coordinatorsOptions, setCoordinatorsOptions] = useState<SelectOption[]>();
    const [supervisorsOptions, setSupervisorsOptions] = useState<SelectOption[]>();
    const [usersOptions, setUsersOptions] = useState<SelectOption[]>();

    const loadUsers = async () => {
        api.get('/users').then(response => {
            setUsersOptions([{value: "", label: "Selecionar um comprador"},...response.data.map((user: User) => {
                return {
                    value: user.id,
                    label: user.name
                }
            })])
        });
    }

    const loadPartners = async () => {
        api.get('/partners').then(response => {
            setPartnersOptions([{value: "", label: "Selecionar um parceiro"},...response.data.data.map((partner: Partner) => {
                return {
                    value: partner.id,
                    label: partner.name
                }
            })])
        });
    }

    const loadInvestors = async () => {
        api.get('/investors').then(response => {
            setInvestorsOptions([{value: "", label: "Selecionar um investidor"},...response.data.data.map((investor: Investor) => {
                return {
                    value: investor.id,
                    label: investor.name
                }
            })])
        });
    }

    const loadBrokers = async () => {
        api.get('/brokers').then(response => {
            setBrokersOptions([{value: "", label: "Selecionar um corretor"},...response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            })])

            console.log([{value: 0, label: "Selecionar um corretor"},...response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            })]);
        });
    }

    const loadCustomers = async () => {
        api.get('/customersList').then(response => {
            setCustomersOptions([{value: "", label: "Selecionar um cliente"}, ...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    const loadCoordinators = async () => {
        api.get('/coordinators').then(response => {
            setCoordinatorsOptions([{value: "", label: "Selecionar um coordenador"}, ...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    const loadSupervisors = async () => {
        api.get('/supervisors').then(response => {
            setSupervisorsOptions([{value: "", label: "Selecionar um supervisor"},...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    useEffect(() => {
        loadInvestors();
        loadPartners();
        loadBrokers();
        loadCustomers();
        loadCoordinators();
        loadSupervisors();
        loadUsers();
    }, [])

    return(
        <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
            <ModalOverlay />
            <ModalContent as="form" borderRadius="24px" onSubmit={handleSubmit(handleCreateNewQuota)} maxWidth="700px">
                <ModalHeader p="10" >
                    <Heading fontWeight="700" fontSize="2xl">Cadastrar Nova Cota</Heading>
                    <Text fontWeight={"normal"} fontSize="md" mt="3">Campos com * são obrigatórios</Text>
                </ModalHeader>

                <ModalCloseButton top="10" right="5"/>
                
                <ModalBody pl="10" pr="10">
                    <Stack spacing="6">
                        <HStack spacing="4" align="baseline">
                            <Select register={register} isRequired h="45px" name="is_contemplated" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.is_contemplated}>
                                <option value={1} selected>Contemplada</option>
                                <option value={0}>Não contemplada</option>
                            </Select>

                            <Select register={register} isRequired h="45px" name="is_hot" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.is_hot}>
                                <option value={0}>Sem destaque</option>
                                <option value={1} selected>Com destaque</option>
                            </Select>
                        </HStack>

                        <HStack spacing="4" align="baseline">

                            <Select register={register} isRequired h="45px" name="segment" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.segment}>
                                <option value="Imóvel" selected>Imóvel</option>
                                <option value="Veículo">Veículo</option>
                            </Select>

                            {/* <Input register={register} name="credit" type="text" placeholder="Crédito" variant="outline" mask="money" error={formState.errors.credit} focusBorderColor="blue.800"/>
                         */}
                            {/* <FormControl pos="relative" isInvalid={!!formState.errors.credit}>
                                <FormLabel pos="absolute" left="25" zIndex="2" top={watchCredit ? "4px" : "13px"} fontSize={watchCredit  ? "9" : "13"} fontWeight="500" color="gray.700" _focus={{top: "4px", fontSize: "9px"}}>Crédito *</FormLabel>

                                <ChakraInput {...register('credit')} type={'text'} 
                                        h="45px" pt="8px" pl="6"  fontSize="sm" borderColor={"gray.500"} bgColor={"gray.100"} _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" _placeholder={{color: "gray.600"}} focusBorderColor="blue.800"
                                />
                            
                                { !!formState.errors.credit && (
                                    <FormErrorMessage>
                                        {formState.errors.credit.message}
                                    </FormErrorMessage>   
                                )}
                            </FormControl> */}
                            <ControlledInput control={control} isRequired name="credit" type="text" placeholder="Crédito" variant="outline" mask="money" error={formState.errors.credit} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="baseline">
                            <Input register={register} name="deadline" type="text" placeholder="Prazo" variant="outline" error={formState.errors.deadline} focusBorderColor="blue.800"/>

                            <Input register={register} name="parcel" type="text" placeholder="Parcela" variant="outline" mask="money" error={formState.errors.parcel} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="baseline">
                            <Input register={register} name="value" type="text" placeholder="Entrada" variant="outline" mask="money" error={formState.errors.value} focusBorderColor="blue.800"/>

                            {/* <Input name="cost" type="text" placeholder="Custo da Empresa" variant="outline" mask="money" error={formState.errors.cost} focusBorderColor="blue.800"/> */}
                        
                            <Input register={register} name="common_fund" type="text" placeholder="Fundo comum" variant="outline" mask="money" error={formState.errors.common_fund} focusBorderColor="blue.800"/>
                            
                        </HStack>

                        {/* <Input register={register} name="total_cost" type="text" placeholder="Custo Total" variant="outline" mask="money" error={formState.errors.total_cost} focusBorderColor="blue.800"/> */}

                        <HStack spacing="4" align="baseline">
                            <Input register={register} name="group" type="text" placeholder="Grupo" variant="outline" error={formState.errors.group} focusBorderColor="blue.800"/>

                            <Input register={register} name="quota" type="text" placeholder="Cota" variant="outline" error={formState.errors.quota} focusBorderColor="blue.800"/>
                        </HStack>

                        <Divider />

                        <HStack spacing="4">
                            {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                            
                            <Select register={register} isRequired h="45px" name="owner_type" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.owner_type}>
                                <option value="">Carta Própria</option>
                                <option value="App\Models\Partners">Carta de Parceiro</option>
                                <option value="App\Models\Brokers">Carta de Corretor</option>
                                <option value="App\Models\Investor">Carta de Investor</option>
                            </Select>

                            {
                                (partnersOptions && watch("owner_type") == "App\\Models\\Partners") && (
                                    <ReactSelect options={partnersOptions} placeholder="Parceiro" control={control} label="Contato" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }

                            {
                                (brokersOptions && watch("owner_type") == "App\\Models\\Brokers") && (
                                    <ReactSelect options={brokersOptions} placeholder="Corretor" control={control} label="Corretor" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }

                            {
                                (investorsOptions && watch("owner_type") == "App\\Models\\Investor") && (
                                    <ReactSelect options={investorsOptions} placeholder="Investidor" control={control} label="Contato" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }
                        </HStack>

                        {
                            watch("owner_type") === "" && (
                                <HStack spacing="4">
                                    {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                    
                                    <Select register={register} isRequired h="45px" name="purchased_of_type" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.purchased_of_type}>
                                        <option value="" selected>Comprado de</option>
                                        <option value="App\Models\Partners">Comprado de Parceiro</option>
                                        <option value="App\Models\Brokers">Comprado de Corretor</option>
                                        <option value="App\Models\Investor">Comprado de Investidor</option>
                                        <option value="App\Models\Customers">Comprado de Cliente</option>
                                    </Select>

                                    {
                                        (watch("purchased_of_type") === "") && (
                                            <ReactSelect options={nullOptions} disabled={true} placeholder="Selecione" control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (partnersOptions && watch("purchased_of_type") == "App\\Models\\Partners") && (
                                            <ReactSelect options={partnersOptions} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Parceiro"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (brokersOptions && watch("purchased_of_type") == "App\\Models\\Brokers") && (
                                            <ReactSelect options={brokersOptions} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Corretor"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (investorsOptions && watch("purchased_of_type") == "App\\Models\\Investor") && (
                                            <ReactSelect options={investorsOptions} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Investidor"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (customersOptions && watch("purchased_of_type") == "App\\Models\\Customers") && (
                                            <ReactSelect options={customersOptions} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Cliente"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }
                                </HStack>
                            )
                        }

                        <Stack border="1px solid" borderColor="gray.300" borderRadius="24px" p="4" spacing="6">
                            <HStack spacing="4" align="baseline">
                                <ControlledInput control={control} name="cost" type="text" placeholder="Custo da empresa" variant="outline" mask="money" error={formState.errors.cost} focusBorderColor="blue.800"/>

                                <ControlledInput control={control} name="total_cost" value={totalCost} type="text" placeholder="Custo total" variant="outline" mask="money" error={formState.errors.cost} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4" align="baseline">
                                <ControlledInput control={control} name="partner_percent" value={partnerPercent ? partnerPercent.toFixed(2) : "0"} mask="number" min="0" max="100" type="text" placeholder="Percentual do parceiro(%)" variant="outline" error={formState.errors.partner_percent} focusBorderColor="blue.800"/>
                                <ControlledInput control={control} name="investor_percent" value={investorPercent ? investorPercent.toFixed(2) : "0"} mask="number" min="0" max="100" type="text" placeholder="Percentual do investidor(%)" variant="outline" error={formState.errors.investor_percent} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    partnersOptions && (
                                        <ReactSelect options={partnersOptions} placeholder="Parceiro" control={control} label="Contato" name="partner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.partner_id}/>
                                    )
                                }

                                <ControlledInput control={control} name="partner_cost" type="text" placeholder="Custo do parceiro" variant="outline" mask="number" error={formState.errors.partner_cost} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    investorsOptions && (
                                        <ReactSelect options={investorsOptions} placeholder="Investidor" control={control} label="Contato" name="investor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.investor_id}/>
                                    )
                                }

                                <ControlledInput control={control} name="investor_cost" type="text" placeholder="Custo do investidor" variant="outline" mask="number" error={formState.errors.investor_cost} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    coordinatorsOptions && (
                                        <ReactSelect options={coordinatorsOptions} placeholder="Coordenador" control={control} label="Contato" name="coordinator_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.coordinator_id}/>
                                    )
                                }

                                <ControlledInput control={control} name="coordinator_commission" type="text" placeholder="Custo do coordenador" variant="outline" mask="number" error={formState.errors.coordinator_commission} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    supervisorsOptions && (
                                        <ReactSelect options={supervisorsOptions} placeholder="Supervisor" control={control} label="Contato" name="supervisor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.supervisor_id}/>
                                    )
                                }

                                <ControlledInput control={control} name="supervisor_commission" type="text" placeholder="Custo do supervisor" variant="outline" mask="number" error={formState.errors.supervisor_commission} focusBorderColor="blue.800"/>
                            </HStack>
                        </Stack>

                        <HStack spacing="4" align="baseline">
                            <Input register={register} name="purchase_date" type="date" placeholder="Data de Compra" variant="outline" error={formState.errors.purchase_date} focusBorderColor="blue.800"/>

                            <Input register={register} name="parcel_deadline" type="date" placeholder="Data da próxima parcela" variant="outline" error={formState.errors.parcel_deadline} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="baseline">
                            {
                                usersOptions && (
                                    <ReactSelect options={usersOptions} placeholder="Comprador" control={control} label="Contato" name="purchased_by_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_by_id}/>
                                )
                            }

                            <ControlledInput control={control} value={paidPercent ? paidPercent.toFixed(2) : ""} name="paid_percent" type="text" placeholder="Percentual pago" variant="outline" mask="number" error={formState.errors.cost} focusBorderColor="blue.800"/>
                        </HStack>

                        {/* <HStack spacing="4" align="baseline">
                            <Input register={register} name="seller" type="text" placeholder="Comprado de" variant="outline" error={formState.errors.seller} focusBorderColor="blue.800"/>
                            <Input register={register} name="cpf_cnpj" type="text" placeholder="CPF/CNPJ" variant="outline" error={formState.errors.cpf_cnpj} focusBorderColor="blue.800"/>
                        </HStack> */}

                        <Input register={register} name="cpf_cnpj" type="text" placeholder="CPF/CNPJ" variant="outline" error={formState.errors.cpf_cnpj} focusBorderColor="blue.800"/>

                        <HStack spacing="4" align="baseline">
                            <Input register={register} name="contemplated_type" type="text" placeholder="Tipo de contemplação" variant="outline" error={formState.errors.contemplated_type} focusBorderColor="blue.800"/>
                            <Input register={register} name="tax" type="text" placeholder="Taxa" mask="money" variant="outline" error={formState.errors.tax} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="baseline">
                            {/* <ControlledInput control={control} value={toEditQuotaData.month_adjust_number} name="month_adjust_number" type="text" placeholder="Tipo de contemplação" variant="outline" error={formState.errors.month_adjust_number} focusBorderColor="blue.800"/> */}
                            <Select register={register}  h="45px" name="month_adjust_number" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.month_adjust_number}>
                                <option value="">Mês de reajuste</option>
                                <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                            </Select>
                            <Input register={register} name="adjust_index" type="text" placeholder="Índice de reajuste" variant="outline" error={formState.errors.adjust_index} focusBorderColor="blue.800"/>
                        </HStack>

                        <Input register={register} name="description" type="text" placeholder="Descrição" variant="outline" error={formState.errors.description} focusBorderColor="blue.800"/>

                    </Stack>
                </ModalBody>

                <ModalFooter p="10">
                    <SolidButton mr="6" color="white" bg="blue.800" colorScheme="blue" type="submit" isLoading={formState.isSubmitting}>
                        Cadastrar
                    </SolidButton>

                    <Link onClick={onRequestClose} color="gray.700" fontSize="14px">Cancelar</Link>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}