import { useQuery } from "react-query";
import { api } from "../services/api";

export const getPartners = async () => {
    const { data } = await api.get('/partners');

    return data.data;
}

export function usePartners(){
    return useQuery(['partners'], () => getPartners(), {
        staleTime: 1000 * 5 * 60, //fresh
    });
}