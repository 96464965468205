import { Checkbox, Divider, Flex, Heading, HStack, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, useToast } from "@chakra-ui/react";
import { SolidButton } from "../../../components/Buttons/SolidButton";

import {  useForm, useWatch } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Broker, Company, Customer, Investor, Partner, PaymentCategory, Provider, User } from "../../../types";
import { api } from "../../../services/api";
import { useHistory } from "react-router";
import { useErrors } from "../../../hooks/useErrors";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { ControlledInput } from "../../../components/Forms/Inputs/ControlledInput";
import { ControlledSelect } from "../../../components/Forms/Selects/ControlledSelect";
import { formatInputDate } from "../../../utils/Date/formatInputDate";
import moneyToBackend from "../../../utils/moneyToBackend";
import { useProfile } from "../../../hooks/useProfile";
import { redirectMessages } from "../../../utils/redirectMessages";
import { isAuthenticated } from "../../../services/auth";
import React, { useEffect, useState } from "react";
import { ReactSelect, SelectOption } from "../../../components/Forms/ReactSelect";

interface EditQuotaModalProps{
    isOpen: boolean;
    onRequestClose: () => void;
    afterEdit: () => void;
    toEditQuotaData: EditQuota;
}

export interface EditQuota{
    id: number;
    sold: boolean;
    company: number;
    segment: string;
    value?: string;
    deadline?: number;
    parcel?: string;
    credit: string;
    common_fund?: string;
    group?: string;
    quota?: string;
    cost?: string;
    partner_id?: number;
    partner_cost?: string;
    passed_cost?: string;
    total_cost?: string;
    seller?: string;
    cpf_cnpj?: string;
    paid_percent?: string;
    partner_percent?: number;
    tax?: string;
    contemplated_type?: string;
    is_contemplated: boolean;
    is_hot: boolean;
    month_adjust_number?: number;
    adjust_index?: string;
    description?: string;
    purchase_date?: string;

    investor_id?: number;
    investor_percent?: number;
    investor_cost?: string;

    parcel_deadline?: string;

    owner_type?: string;
    owner_id?: number;

    purchased_of_type?: string;
    purchased_of_id?: number;

    purchased_by_id?: number;

    supervisor_commission?: string;
    supervisor_id?: number;

    coordinator_commission?: string;
    coordinator_id?: number;

    created_at?: Date;
    updated_at?: Date;
}

const EditQuotaFormSchema = yup.object().shape({
    segment: yup.string().required('Selecione o tipo da carta de crédito'),
    description: yup.string().nullable(),
    seller: yup.string().nullable(),
    contemplated_type: yup.string().nullable(),//.required("Qual o tipo de contemplação?"),
    is_contemplated: yup.boolean().required("É contemplado?"),
    is_hot: yup.boolean().required("Possui destaque?"),
    credit: yup.string().required("Informe o valor do crédito"),
    value: yup.string().nullable(),//.required("Informe o valor do pagamento"),
    cost: yup.string().nullable(),//.required("Informe o custo"),
    total_cost: yup.string().nullable(),//.required("Informe o custo total"),
    cpf_cnpj: yup.string().nullable(),//.required("Qual o cpf ou cnpj proprietário?"),
    partner: yup.string().nullable(),
    partner_cost: yup.string().nullable(),
    passed_cost: yup.string().nullable(),
    month_adjust_number: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    adjust_index: yup.string().nullable(),
    paid_percent: yup.string().nullable(),//.required("Diga qual o percentual pago"),
    partner_commission: yup.string().nullable(),//.nullable(),
    purchase_date: yup.string().nullable(),//.required("Selecione a data de compra"),
    group: yup.string().nullable(),//.required("Informe o grupo"),
    quota: yup.string().nullable(),//.required("Informe a cota"),
    tax: yup.string().nullable(),

    investor_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    investor_percent: yup.string().nullable(),
    investor_cost: yup.string().nullable(),

    owner_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    owner_type: yup.string().nullable(),

    purchased_of_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
    purchased_of_type: yup.string().nullable(),

    purchased_by_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),

    supervisor_commission: yup.string().nullable(),
    supervisor_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),

    coordinator_commission: yup.string().nullable(),
    coordinator_id: yup.number().transform((v, o) => o === '' ? null : v).nullable(),
});


export function EditQuotaModal( { isOpen, onRequestClose, afterEdit, toEditQuotaData } : EditQuotaModalProps){
    const workingCompany = useWorkingCompany();
    const history = useHistory();
    const toast = useToast();
    const {profile} = useProfile();

    const { showErrors } = useErrors();

    const { handleSubmit, register, formState, watch, control} = useForm<EditQuota>({
        resolver: yupResolver(EditQuotaFormSchema),
        defaultValues: {
            id: toEditQuotaData.id,
            sold: toEditQuotaData.sold,
            credit: toEditQuotaData.credit,
            value: toEditQuotaData.value,
            parcel: toEditQuotaData.parcel,
            deadline: toEditQuotaData.deadline,
            common_fund: toEditQuotaData.common_fund,
            segment: toEditQuotaData.segment,
            company: toEditQuotaData.company,
            seller: toEditQuotaData.seller,
            contemplated_type: toEditQuotaData.contemplated_type,
            cost: toEditQuotaData.cost,
            total_cost: toEditQuotaData.total_cost,
            cpf_cnpj: toEditQuotaData.cpf_cnpj,
            partner_id: toEditQuotaData.partner_id,
            partner_percent: toEditQuotaData.partner_percent,
            is_contemplated: toEditQuotaData.is_contemplated,
            is_hot: toEditQuotaData.is_hot,
            month_adjust_number: toEditQuotaData.month_adjust_number,
            adjust_index: toEditQuotaData.adjust_index,
            partner_cost: toEditQuotaData.partner_cost,
            passed_cost: toEditQuotaData.passed_cost,
            purchase_date: toEditQuotaData.purchase_date,
            paid_percent: toEditQuotaData.paid_percent,
            description: toEditQuotaData.description,
            group: toEditQuotaData.group,
            quota: toEditQuotaData.quota,
        }
    });

    function includeAndFormatData(quotaData: EditQuota): EditQuota{
        quotaData.value = ((quotaData.value != null && quotaData.value != "") ? moneyToBackend(quotaData.value) : '');
        quotaData.credit = moneyToBackend(quotaData.credit);
        quotaData.cost = ((quotaData.cost != null && quotaData.cost != "") ? moneyToBackend(quotaData.cost) : '');

        console.log(((quotaData.total_cost != null && quotaData.total_cost != "") ? moneyToBackend(quotaData.total_cost) : ''));

        quotaData.partner_cost = ((quotaData.partner_cost != null && quotaData.partner_cost != "") ? moneyToBackend(quotaData.partner_cost) : '');
        quotaData.passed_cost = ((quotaData.passed_cost != null && quotaData.passed_cost != "") ? moneyToBackend(quotaData.passed_cost) : '');
        quotaData.investor_cost = ((quotaData.investor_cost != null && quotaData.investor_cost != "") ? moneyToBackend(quotaData.investor_cost) : '');
        quotaData.coordinator_commission = ((quotaData.coordinator_commission != null && quotaData.coordinator_commission != "") ? moneyToBackend(quotaData.coordinator_commission) : '');
        quotaData.supervisor_commission = ((quotaData.supervisor_commission != null && quotaData.supervisor_commission != "") ? moneyToBackend(quotaData.supervisor_commission) : '');

        quotaData.total_cost = ((quotaData.total_cost != null && quotaData.total_cost != "") ? moneyToBackend(quotaData.total_cost) : '').replaceAll(' ', '');
        quotaData.parcel = ((quotaData.parcel != null && quotaData.parcel != "") ? moneyToBackend(quotaData.parcel) : '');
        quotaData.common_fund = ((quotaData.common_fund != null && quotaData.common_fund != "") ? moneyToBackend(quotaData.common_fund) : '');
        quotaData.tax = ((quotaData.tax != null && quotaData.tax != "") ? moneyToBackend(quotaData.tax) : '');

        //quotaData.purchase_date = formatInputDate(quotaData.purchase_date);
        console.log(quotaData);

        if(!workingCompany.company){
            return quotaData;
        }
        
        quotaData.company = workingCompany.company?.id;

        return quotaData;
    }

    const handleEditPayment = async (quotaData : EditQuota) => {
        try{
            if(!workingCompany.company && quotaData.company === 0){
                toast({
                    title: "Ué",
                    description: `Seleciona uma empresa para trabalhar`,
                    status: "warning",
                    duration: 12000,
                    isClosable: true,
                });

                return;
            }

            quotaData.sold = toEditQuotaData.sold;
            
            quotaData = includeAndFormatData(quotaData);

            console.log(quotaData);

            await api.post(`/ready_quotas/update/${toEditQuotaData.id}`, quotaData);

            toast({
                title: "Sucesso",
                description: `Dados da cota ${toEditQuotaData.group}-${toEditQuotaData.quota} atualizados.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            setIsDisabledRecurrence(true);
            afterEdit();
            onRequestClose();
        }catch(error: any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    useEffect(() => {
        if(!isAuthenticated()){
            history.push({
                pathname: '/',
                state: redirectMessages.auth
            });
        }
    }, [isOpen])

    const [isDisabledRecurrence, setIsDisabledRecurrence] = useState(true);

    const changeIsDisabledRecurrence = () => {
        setIsDisabledRecurrence(!isDisabledRecurrence);
    }

    const watchCost = useWatch({
        control,
        name: 'cost',
    });

    const watchCredit = useWatch({
        control,
        name: 'credit',
    });

    const [paidPercent, setPaidPercent] = useState<number>();
    const [partnerPercent, setPartnerPercent] = useState<number>(toEditQuotaData.partner_percent ? toEditQuotaData.partner_percent : 0);
    const [investorPercent, setInvestorPercent] = useState<number>(toEditQuotaData.investor_percent ? toEditQuotaData.investor_percent : 0);
    const [totalCost, setTotalCost] = useState<string>();

    useEffect(() => {
        if(watchCost && watchCredit){
            const selectedCost = parseFloat(moneyToBackend(watchCost));
            const selectedCredit = parseFloat(moneyToBackend(watchCredit));

            const paidParcent = (selectedCost * 100) / selectedCredit;

            console.log(paidParcent.toString());
            setPaidPercent(paidParcent);
            //setValue('paid_percent', paidParcent.toString(), { shouldValidate: true });
        }
    }, [watchCost, watchCredit]);

    useEffect(() => {
        const partnerCost = watch('partner_cost') === "" ? "0" : watch('partner_cost');
        const coordinatorCommission = watch('coordinator_commission') === "" ? "0" : watch('coordinator_commission');
        const supervisorCommission = watch('supervisor_commission') === "" ? "0" : watch('supervisor_commission');
        const investorCost = watch('investor_cost') === "" ? "0" : watch('investor_cost');
        const cost = watch('cost') === "" ? "0" : watch('cost');

        if((cost && moneyToBackend(cost)) || (partnerCost && moneyToBackend(partnerCost)) || (investorCost && moneyToBackend(investorCost)) || (supervisorCommission && moneyToBackend(supervisorCommission)) || (coordinatorCommission && moneyToBackend(coordinatorCommission))){
            const partnerCost = watch('partner_cost') === "" ? "0" : watch('partner_cost');
            const investorCost = watch('investor_cost') === "" ? "0" : watch('investor_cost');
            const credit = watch('credit') === "" ? "0" : watch('credit');
            
            if(cost !== undefined && partnerCost !== undefined && investorCost !== undefined && supervisorCommission !== undefined && coordinatorCommission !== undefined){
                const selectedCost = parseFloat(moneyToBackend(cost));
                const selectedPartnerCost = parseFloat(moneyToBackend(partnerCost));
                const selectedInvestorCost = parseFloat(moneyToBackend(investorCost));
                const selectedSupervisorCommission = parseFloat(moneyToBackend(supervisorCommission));
                const selectedCoordinatorCommission = parseFloat(moneyToBackend(coordinatorCommission));
                const selectedCredit = parseFloat(moneyToBackend(credit));

                const totalCost = (selectedCost + selectedPartnerCost + selectedInvestorCost + selectedSupervisorCommission + selectedCoordinatorCommission).toFixed(2);
                const newPaidPercent = ((selectedCost + selectedPartnerCost)*100)/selectedCredit;
                const newInvestorPercent = ((selectedCost + selectedInvestorCost)*100)/selectedCredit;
                //setValue('total_cost', totalCost.toString(), { shouldValidate: true });
                console.log(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(parseFloat(totalCost)));
                //setTotalCost(totalCost.toString());
                setTotalCost(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(parseFloat(totalCost)));
                setPaidPercent(newPaidPercent);
                setInvestorPercent(selectedInvestorCost === 0 ? 0 : (parseFloat(totalCost) === selectedInvestorCost ? 100 : (selectedInvestorCost * 100)/parseFloat(totalCost)));
                setPartnerPercent(selectedPartnerCost === 0 ? 0 : (parseFloat(totalCost) === selectedPartnerCost ? 100 : (selectedPartnerCost * 100)/parseFloat(totalCost)));

                return;
            }


            setTotalCost(watch('cost'));
            //setValue('total_cost', watchCost, { shouldValidate: true });
        }
    }, [watch('cost'), watch('partner_cost'), watch('investor_cost'), watch('coordinator_commission'), watch('supervisor_commission')]);

    const [nullOptions, setNullOptions] = useState<SelectOption[]>([{value: "", label: ""}]);
    const [partnersOptions, setPartnersOptions] = useState<SelectOption[]>();
    const [investorsOptions, setInvestorsOptions] = useState<SelectOption[]>();
    const [brokersOptions, setBrokersOptions] = useState<SelectOption[]>();
    const [customersOptions, setCustomersOptions] = useState<SelectOption[]>();
    const [coordinatorsOptions, setCoordinatorsOptions] = useState<SelectOption[]>();
    const [supervisorsOptions, setSupervisorsOptions] = useState<SelectOption[]>();
    const [usersOptions, setUsersOptions] = useState<SelectOption[]>();

    const loadPartners = async () => {
        api.get('/partners').then(response => {
            setPartnersOptions([{value: "", label: "Selecionar um parceiro"},...response.data.data.map((partner: Partner) => {
                return {
                    value: partner.id,
                    label: partner.name
                }
            })])
        });
    }

    const loadInvestors = async () => {
        api.get('/investors').then(response => {
            setInvestorsOptions([{value: "", label: "Selecionar um investidor"},...response.data.data.map((investor: Investor) => {
                return {
                    value: investor.id,
                    label: investor.name
                }
            })])
        });
    }

    const loadBrokers = async () => {
        api.get('/brokers').then(response => {
            setBrokersOptions([{value: "", label: "Selecionar um corretor"},...response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            })])

            console.log([{value: 0, label: "Selecionar um corretor"},...response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            })]);
        });
    }

    const loadCustomers = async () => {
        api.get('/customersList').then(response => {
            setCustomersOptions([{value: "", label: "Selecionar um cliente"}, ...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    const loadCoordinators = async () => {
        api.get('/coordinators').then(response => {
            setCoordinatorsOptions([{value: "", label: "Selecionar um coordenador"}, ...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    const loadSupervisors = async () => {
        api.get('/supervisors').then(response => {
            setSupervisorsOptions([{value: "", label: "Selecionar um supervisor"}, ...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    const loadUsers = async () => {
        api.get('/users').then(response => {
            setUsersOptions([{value: "", label: "Selecionar um comprador"},...response.data.map((user: User) => {
                return {
                    value: user.id,
                    label: user.name
                }
            })])
        });
    }

    useEffect(() => {
        loadInvestors();
        loadPartners();
        loadBrokers();
        loadCustomers();
        loadCoordinators();
        loadSupervisors();
        loadUsers();
    }, [])

    console.log(toEditQuotaData);

    return (
        <Modal isOpen={isOpen} onClose={onRequestClose} size="xl">
            <ModalOverlay />
            <ModalContent as="form" borderRadius="24px" onSubmit={handleSubmit(handleEditPayment)} maxWidth="700px">
                <ModalHeader p="10" fontWeight="700" fontSize="2xl">
                    <Heading fontWeight="700" fontSize="2xl">Alterar {toEditQuotaData.group}-{toEditQuotaData.quota}</Heading>
                    <Text fontWeight={"normal"} fontSize="md" mt="3">Campos com * são obrigatórios</Text>
                </ModalHeader>

                <ModalCloseButton top="10" right="5"/>
                
                <ModalBody pl="10" pr="10">
                    <Stack spacing="6">

                        <HStack spacing="4" align="end">
                            <ControlledSelect control={control} value={toEditQuotaData.is_contemplated ? 1 : 0}  h="45px" name="is_contemplated" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.is_contemplated}>
                                <option value={1}>Contemplada</option>
                                <option value={0}>Não contemplada</option>
                            </ControlledSelect>

                            <ControlledSelect control={control} value={toEditQuotaData.is_hot ? 1 : 0}  h="45px" name="is_hot" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.is_hot}>
                                <option value={1}>Com destaque</option>
                                <option value={0}>Sem destaque</option>
                            </ControlledSelect>
                        </HStack>

                        <HStack spacing="4" align="end">
                            <ControlledSelect control={control} value={toEditQuotaData.segment.toString()}  h="45px" name="segment" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.segment}>
                                <option value="Imóvel">Imóvel</option>
                                <option value="Veículo">Veículo</option>
                            </ControlledSelect>

                            <ControlledInput control={control} mask="money" value={toEditQuotaData.credit} name="credit" type="text" placeholder="Crédito" variant="outline" error={formState.errors.credit} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="end">
                            <ControlledInput control={control} value={toEditQuotaData.deadline ? toEditQuotaData.deadline.toString() : ''} name="deadline" type="text" placeholder="Prazo" variant="outline" error={formState.errors.deadline} focusBorderColor="blue.800"/>
                            <ControlledInput control={control} mask="money" value={toEditQuotaData.parcel ? toEditQuotaData.parcel : ""} name="parcel" type="text" placeholder="Valor da parcela" variant="outline" error={formState.errors.parcel} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="end">
                            <ControlledInput control={control} mask="money" value={toEditQuotaData.value ? toEditQuotaData.value : ""} name="value" type="text" placeholder="Entrada" variant="outline" error={formState.errors.value} focusBorderColor="blue.800"/>
                            <ControlledInput control={control} mask="money" value={toEditQuotaData.common_fund ? toEditQuotaData.common_fund : ""} name="common_fund" type="text" placeholder="Fundo comum" variant="outline" error={formState.errors.common_fund} focusBorderColor="blue.800"/>
                        </HStack>

                        

                        <HStack spacing="4" align="end">
                            <ControlledInput control={control} value={toEditQuotaData.group} name="group" type="text" placeholder="Grupo" variant="outline" error={formState.errors.group} focusBorderColor="blue.800"/>
                            <ControlledInput control={control} value={toEditQuotaData.quota} name="quota" type="text" placeholder="Cota" variant="outline" error={formState.errors.quota} focusBorderColor="blue.800"/>
                        </HStack>

                        <Divider />

                        <HStack spacing="4">
                            {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                            
                            <ControlledSelect control={control} value={toEditQuotaData.owner_type} isRequired h="45px" name="owner_type" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.owner_type}>
                                <option value="">Carta Própria</option>
                                <option value="App\Models\Partners">Carta de Parceiro</option>
                                <option value="App\Models\Brokers">Carta de Corretor</option>
                                <option value="App\Models\Investor">Carta de Investor</option>
                            </ControlledSelect>

                            {
                                (watch("owner_type") === "") && (
                                    <ReactSelect options={nullOptions} disabled={true} placeholder="Investidor" control={control} label="Contato" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }

                            {
                                (partnersOptions && watch("owner_type") === "App\\Models\\Partners") && (
                                    <ReactSelect options={partnersOptions} placeholder="Parceiro" control={control} label="Contato" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }

                            {
                                (brokersOptions && watch("owner_type") === "App\\Models\\Brokers") && (
                                    <ReactSelect options={brokersOptions} placeholder="Corretor" control={control} label="Corretor" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }

                            {
                                (investorsOptions && watch("owner_type") === "App\\Models\\Investor") && (
                                    <ReactSelect options={investorsOptions} placeholder="Investidor" control={control} label="Contato" name="owner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.owner_id}/>
                                )
                            }
                        </HStack>

                        {
                            watch("owner_type") === "" || watch("owner_type") === null && (
                                <HStack spacing="4">
                                    {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                    
                                    <ControlledSelect control={control} value={toEditQuotaData.purchased_of_type} isRequired h="45px" name="purchased_of_type" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.purchased_of_type}>
                                        <option value="" selected>Comprado de</option>
                                        <option value="App\Models\Partners">Comprado de Parceiro</option>
                                        <option value="App\Models\Brokers">Comprado de Corretor</option>
                                        <option value="App\Models\Investor">Comprado de Investidor</option>
                                        <option value="App\Models\Customers">Comprado de Cliente</option>
                                    </ControlledSelect>

                                    {
                                        (watch("purchased_of_type") === "") && (
                                            <ReactSelect options={nullOptions} disabled={true} placeholder="Selecione" control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (partnersOptions && watch("purchased_of_type") === "App\\Models\\Partners") && (
                                            <ReactSelect options={partnersOptions} value={toEditQuotaData.purchased_of_id} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Parceiro"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (brokersOptions && watch("purchased_of_type") === "App\\Models\\Brokers") && (
                                            <ReactSelect options={brokersOptions} value={toEditQuotaData.purchased_of_id} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Corretor"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (investorsOptions && watch("purchased_of_type") === "App\\Models\\Investor") && (
                                            <ReactSelect options={investorsOptions} value={toEditQuotaData.purchased_of_id} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Investidor"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }

                                    {
                                        (customersOptions && watch("purchased_of_type") === "App\\Models\\Customers") && (
                                            <ReactSelect options={customersOptions} value={toEditQuotaData.purchased_of_id} placeholder={watch('purchased_of_type') === "" ? "Selecione o Tipo" : "Selecione o Cliente"} disabled={watch('purchased_of_type') === "" ? true : false} control={control} label="Contato" name="purchased_of_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_of_id}/>
                                        )
                                    }
                                </HStack>
                            )
                        }

                        <Stack border="1px solid" borderColor="gray.300" borderRadius="24px" p="4" spacing="6">
                            <HStack spacing="4" align="end">
                                <ControlledInput control={control} name="cost" value={toEditQuotaData.cost} type="text" placeholder="Custo da empresa" variant="outline" mask="money" error={formState.errors.cost} focusBorderColor="blue.800"/>

                                <ControlledInput control={control} name="total_cost" value={totalCost} type="text" placeholder="Custo total" variant="outline" mask="money" error={formState.errors.cost} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4" align="end">
                                <ControlledInput control={control} name="partner_percent" value={partnerPercent ? partnerPercent.toFixed(2) : "0"} mask="number" min="0" max="100" type="text" placeholder="Percentual do parceiro(%)" variant="outline" error={formState.errors.partner_percent} focusBorderColor="blue.800"/>
                                <ControlledInput control={control} name="investor_percent" value={investorPercent ? investorPercent.toFixed(2) : "0"} mask="number" min="0" max="100" type="text" placeholder="Percentual do parceiro(%)" variant="outline" error={formState.errors.investor_percent} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    partnersOptions && (
                                        <ReactSelect value={toEditQuotaData.partner_id} options={partnersOptions} placeholder="Parceiro" control={control} label="Contato" name="partner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.partner_id}/>
                                    )
                                }

                                <ControlledInput value={toEditQuotaData.partner_cost} control={control} name="partner_cost" type="text" placeholder="Custo do parceiro" variant="outline" mask="number" error={formState.errors.partner_cost} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    investorsOptions && (
                                        <ReactSelect options={investorsOptions} value={toEditQuotaData.investor_id} placeholder="Investidor" control={control} label="Contato" name="investor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.investor_id}/>
                                    )
                                }

                                <ControlledInput control={control} value={toEditQuotaData.investor_cost} name="investor_cost" type="text" placeholder="Custo do investidor" variant="outline" mask="number" error={formState.errors.investor_cost} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    coordinatorsOptions && (
                                        <ReactSelect options={coordinatorsOptions} value={toEditQuotaData.coordinator_id} placeholder="Coordenador" control={control} label="Contato" name="coordinator_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.coordinator_id}/>
                                    )
                                }

                                <ControlledInput control={control} value={toEditQuotaData.coordinator_commission} name="coordinator_commission" type="text" placeholder="Custo do coordenador" variant="outline" mask="number" error={formState.errors.coordinator_commission} focusBorderColor="blue.800"/>
                            </HStack>

                            <HStack spacing="4">
                                {/* <Input register={register} name="partner" type="text" placeholder="Parceiro" variant="outline" error={formState.errors.partner} focusBorderColor="blue.800"/> */}
                                
                                {
                                    supervisorsOptions && (
                                        <ReactSelect options={supervisorsOptions} value={toEditQuotaData.supervisor_id} placeholder="Supervisor" control={control} label="Contato" name="supervisor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.supervisor_id}/>
                                    )
                                }

                                <ControlledInput value={toEditQuotaData.supervisor_commission} control={control} name="supervisor_commission" type="text" placeholder="Custo do supervisor" variant="outline" mask="number" error={formState.errors.supervisor_commission} focusBorderColor="blue.800"/>
                            </HStack>
                        </Stack>

                        <HStack spacing="4" align="end">
                            <ControlledInput control={control} value={toEditQuotaData.purchase_date} name="purchase_date" type="date" placeholder="Data da compra" variant="outline" error={formState.errors.purchase_date} focusBorderColor="blue.800"/>
                            <ControlledInput control={control} value={toEditQuotaData.parcel_deadline} name="parcel_deadline" type="date" placeholder="Data da próxima parcela" variant="outline" error={formState.errors.parcel_deadline} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="end">
                            {
                                usersOptions && (
                                    <ReactSelect options={usersOptions} value={toEditQuotaData.purchased_by_id} placeholder="Comprador" control={control} label="Contato" name="purchased_by_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.purchased_by_id}/>
                                )
                            }
                            <ControlledInput control={control} value={toEditQuotaData.paid_percent} name="paid_percent" type="text" placeholder="Percentual Pago" variant="outline" error={formState.errors.paid_percent} focusBorderColor="blue.800"/>
                        </HStack>

                        <ControlledInput control={control} value={toEditQuotaData.cpf_cnpj} name="cpf_cnpj" type="text" placeholder="CPF/CNPJ" variant="outline" error={formState.errors.cpf_cnpj} focusBorderColor="blue.800"/>

                        <HStack spacing="4" align="end">
                            <ControlledInput control={control} value={toEditQuotaData.contemplated_type} name="contemplated_type" type="text" placeholder="Tipo de contemplação" variant="outline" error={formState.errors.contemplated_type} focusBorderColor="blue.800"/>
                            <ControlledInput control={control} value={toEditQuotaData.tax ? toEditQuotaData.tax : ""} name="tax" mask="money" type="text" placeholder="Taxa" variant="outline" error={formState.errors.tax} focusBorderColor="blue.800"/>
                        </HStack>

                        <HStack spacing="4" align="end">
                            {/* <ControlledInput control={control} value={toEditQuotaData.month_adjust_number} name="month_adjust_number" type="text" placeholder="Tipo de contemplação" variant="outline" error={formState.errors.month_adjust_number} focusBorderColor="blue.800"/> */}
                            <ControlledSelect control={control} value={toEditQuotaData.month_adjust_number ? toEditQuotaData.month_adjust_number.toString() : ""}  h="45px" name="month_adjust_number" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.month_adjust_number}>
                                <option value="">Mês de reajuste</option>
                                <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                            </ControlledSelect>
                            <ControlledInput control={control} value={toEditQuotaData.adjust_index ? toEditQuotaData.adjust_index.toString() : ""} name="adjust_index" type="text" placeholder="Índice de reajuste" variant="outline" error={formState.errors.adjust_index} focusBorderColor="blue.800"/>
                        </HStack>

                        <ControlledInput control={control} value={toEditQuotaData.description} name="description" type="text" placeholder="Descrição" variant="outline" error={formState.errors.description} focusBorderColor="blue.800"/>

                    </Stack>
                </ModalBody>

                <ModalFooter p="10">
                    <SolidButton mr="6" color="white" bg="blue.800" colorScheme="blue" type="submit" isLoading={formState.isSubmitting}>
                        Atualizar
                    </SolidButton>

                    <Link onClick={onRequestClose} color="gray.700" fontSize="14px">Cancelar</Link>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}