import { Flex, HStack, Link, Text, Stack } from "@chakra-ui/react"
import { SolidButton } from "../../../components/Buttons/SolidButton"

import { ReactComponent as PlusIcon } from '../../../assets/icons/Plus.svg';

interface StockNavBarProps{
    OpenNewQuotaModal: () => void;
}

export function StockNavBar({OpenNewQuotaModal}: StockNavBarProps){
    return (
        <Flex justify="space-between" alignItems="center" mb="10">
            <SolidButton onClick={OpenNewQuotaModal} color="white" bg="blue.800" icon={PlusIcon} colorScheme="blue">
                Adicionar Carta
            </SolidButton>

            <Stack>
                <HStack>
                    <Link href="/parceiros" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                        <Text textAlign="center">Parceiros</Text>
                    </Link>

                    <Link href="/coordenadores" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                        <Text textAlign="center">Coordenadores</Text>
                    </Link>

                    <Link href="/supervisores" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                        <Text textAlign="center">Supervisores</Text>
                    </Link>
                </HStack>

                <HStack>
                    <Link href="/investidores" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                        <Text textAlign="center">Investidores</Text>
                    </Link>

                    <Link href="/corretores" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                        <Text textAlign="center">Corretores</Text>
                    </Link>

                    <Link href="/lista-clientes" border="2px" borderRadius="full" borderColor="gray.500" px="6" h="8" alignItems="center">
                        <Text textAlign="center">Clientes</Text>
                    </Link>
                </HStack>
            </Stack>

            {/* <OutlineButton onClick={OpenExportDocumentsModal} variant="outline" colorScheme="blue" color="blue.400" borderColor="blue.400">
                Baixar Documentos
            </OutlineButton> */}
        </Flex>
    )
}