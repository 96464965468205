import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Divider, Flex, HStack, IconButton, Spinner, Stack, Tbody, Td, Text, Th, Thead, Table, Tr, Link, useToast } from "@chakra-ui/react";
import { BillCategory, Payment, PaymentCategory, Provider, Quota, QuotaSale, ResumedBill, User } from "../../../types";

import { ReactComponent as PlusIcon } from '../../../assets/icons/Plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/Minus.svg';
import { ReactComponent as StrongPlusIcon } from '../../../assets/icons/StrongPlus.svg';
import { ReactComponent as EllipseIcon } from '../../../assets/icons/Ellipse.svg';
import { ReactComponent as TagIcon } from '../../../assets/icons/Tag.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/Check.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/File.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/Refresh.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/Home.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/Back Arrow.svg';

import { OutlineButton } from "../../../components/Buttons/OutlineButton";
import { EditButton } from "../../../components/Buttons/EditButton";
import { RemoveButton } from "../../../components/Buttons/RemoveButton";
import { UseQueryResult } from "react-query";
import { formatBRDate } from "../../../utils/Date/formatBRDate";
import { useEffect, useState } from "react";
// import { EditQuota, EditQuotaSaleModal } from "./EditSaleModal";
// import { ConfirmPaymentRemoveModal, cancelQuotaSaleData } from "./ConfirmSaleRemoveModal";
import { Router, useHistory } from "react-router";
import { CancelQuotaSaleData, ConfirmQuotaSaleCancelModal } from "./ConfirmQuotaSaleCancelModal";
import { SolidButton } from "../../../components/Buttons/SolidButton";
import { ConfirmQuotaSaleRemoveModal, RemoveQuotaSaleData } from "./ConfirmQuotaSaleRemoveModal";
import { PayPaymentFormData, PayPaymentModal } from "../../Financial/Payments/PayPaymentModal";
import { ReceiveBillFormData, ReceiveBillModal } from "../../Financial/Bills/ReceiveBillModal";
import { api } from "../../../services/api";
import { showErrors } from "../../../hooks/useErrors";
import { EditPaymentFormData, EditPaymentModal } from "../../Financial/Payments/EditPaymentModal";
import { UserFilterData, useUsers } from "../../../hooks/useUsers";
import { useProviders } from "../../../hooks/useProviders";
import { ConfirmPaymentRemoveModal } from "../../Financial/Payments/ConfirmPaymentRemoveModal";
import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg';
import { EditBillFormData, EditBillModal } from "../../Financial/Bills/EditBillModal";
import { ConfirmBillRemoveModal } from "../../Financial/Bills/ConfirmBillRemoveModal";
import { useSources } from "../../../hooks/useSources";

interface QuotaSalesByMonth{
    [key:string]: QuotaSale[];
}

interface QuotasListProps{
    quotaSales: QuotaSalesByMonth;
    refetchQuotaSales: () => void;
}

interface RemovePaymentData{
    id: number;
    title: string;
}

interface RemoveBillData{
    id: number;
    title: string;
}

export function SalesList({quotaSales, refetchQuotaSales}: QuotasListProps){
    const history = useHistory();

    let totalQuotasCount = 0;
    let totalValue = 0;
    let totalCredit = 0;
    Object.keys(quotaSales).map((month) => {
        totalQuotasCount =  totalQuotasCount + quotaSales[month].reduce((sumAmount:number, quotaSale:QuotaSale) => {
            return sumAmount + 1;
        }, 0);

        totalValue =  totalValue + quotaSales[month].reduce((sumAmount:number, quotaSale:QuotaSale) => {
            return sumAmount + quotaSale.value;
        }, 0);

        totalCredit =  totalCredit + quotaSales[month].reduce((sumAmount:number, quotaSale:QuotaSale) => {
            return sumAmount + (quotaSale.ready_quota ? quotaSale.ready_quota.credit : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + (saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}, 0) : 0);
        }, 0);
    });

    // let totalValue = 0;
    // const totalValue = quotaSales.reduce((sumAmount:number, quotaSale:QuotaSale) => {
    //     return sumAmount + quotaSale.value;
    // }, 0);

    // const totalCredit = quotaSales.reduce((sumAmount:number, quotaSale:QuotaSale) => {
    //     return sumAmount + quotaSale.credit;
    // }, 0);

    // const [isEditQuotaSaleModalOpen, setIsEditQuotaSaleModalOpen] = useState(false);
    // const [editQuotaData, setEditQuotaData] = useState<EditQuota>(() => {

    //     const data: EditQuota = {
    //         id: 0,
    //         credit: '',
    //         sold: false,
    //         value: '',
    //         segment: '',
    //         company: 0,
    //         seller: '',
    //         contemplated_type: '',
    //         cost: '',
    //         total_cost: '',
    //         cpf_cnpj: '',
    //         partner: '',
    //         partner_commission: '',
    //         partner_cost: '',
    //         passed_cost: '',
    //         paid_percent: '',
    //         purchase_date: '',
    //         description: '',
    //         group: '',
    //         quota: '',
    //     };
        
    //     return data;
    // });

    // function OpenEditQuotaSaleModal(quotaData: EditQuota){
    //     setEditQuotaData(quotaData);
    //     setIsEditQuotaSaleModalOpen(true);
    // }
    // function CloseEditQuotaSaleModal(){
    //     setIsEditQuotaSaleModalOpen(false);
    // }

    const [isConfirmQuotaSaleCancelModalOpen, setisConfirmQuotaSaleCancelModalOpen] = useState(false);
    const [cancelQuotaSaleData, setCancelQuotaSaleData] = useState<CancelQuotaSaleData>(() => {

        const data: CancelQuotaSaleData = {
            //group: '',
            //quota: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmQuotaSaleCancelModal(QuotaData: CancelQuotaSaleData){
        setCancelQuotaSaleData(QuotaData);
        setisConfirmQuotaSaleCancelModalOpen(true);
    }
    function CloseConfirmQuotaSaleCancelModal(){
        setisConfirmQuotaSaleCancelModalOpen(false);
    }

    const [isConfirmQuotaSaleRemoveModalOpen, setisConfirmQuotaSaleRemoveModalOpen] = useState(false);
    const [removeQuotaSaleData, setRemoveQuotaSaleData] = useState<RemoveQuotaSaleData>(() => {

        const data: RemoveQuotaSaleData = {
            //group: '',
            //quota: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmQuotaSaleRemoveModal(QuotaData: RemoveQuotaSaleData){
        setRemoveQuotaSaleData(QuotaData);
        setisConfirmQuotaSaleRemoveModalOpen(true);
    }
    function CloseConfirmQuotaSaleRemoveModal(){
        setisConfirmQuotaSaleRemoveModalOpen(false);
    }

    const [isReceiveBillModalOpen, setIsReceiveBillModalOpen] = useState(false);
    const [toReceiveBillData, setToReceiveBillData] = useState<ReceiveBillFormData>(() => {

        const data: ReceiveBillFormData = {
            id: 0,
            value: 0,
            paid: 0,
            new_value: '',
            title: '',
        };
        
        return data;
    });

    function OpenReceiveBillModal(billIdAndName: ReceiveBillFormData){
        setToReceiveBillData(billIdAndName);
        setIsReceiveBillModalOpen(true);
    }
    function CloseReceiveBillModal(){
        setIsReceiveBillModalOpen(false);
    }

    const [isPayPaymentModalOpen, setIsPayPaymentModalOpen] = useState(false);
    const [toPayPaymentData, setToPayPaymentData] = useState<ReceiveBillFormData>(() => {

        const data: ReceiveBillFormData = {
            id: 0,
            value: 0,
            paid: 0,
            new_value: '',
            title: '',
        };
        
        return data;
    });

    function OpenPayPaymentModal(billIdAndName: PayPaymentFormData){
        setToPayPaymentData(billIdAndName);
        setIsPayPaymentModalOpen(true);
    }
    function ClosePayPaymentModal(){
        setIsPayPaymentModalOpen(false);
    }

    const toast = useToast();

    const handleReverseBill = async (billId : number) => {
        try{
            await api.post(`/bills/unreceive/${billId}`);

            toast({
                title: "Sucesso",
                description: `Conta a receber redefinda como não recebida.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            refetchQuotaSales();
        }catch(error: any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState(false);

    function OpenEditPaymentModal(paymentData: EditPaymentFormData){
        setToEditPaymentData(paymentData);
        setIsEditPaymentModalOpen(true);
    }

    function CloseEditPaymentModal(){
        setIsEditPaymentModalOpen(false);
    }

    const [toEditPaymentData, setToEditPaymentData] = useState<EditPaymentFormData>(() => {

        const data: EditPaymentFormData = {
            id: 0,
            title: '',
            value: '',
            paid: '',
            company: 0,
            category: 0,
            provider: 0,
            pay_to_user: 0,
            //status: false,
            expire: '',
            observation: '',
            contract: '',
            group: '',
            quote: '',
            recurrence: 0,
            file: [],
        };
        
        return data;
    });

    const [isConfirmPaymentRemoveModalOpen, setisConfirmPaymentRemoveModalOpen] = useState(false);
    const [removePaymentData, setRemovePaymentData] = useState<RemovePaymentData>(() => {

        const data: RemovePaymentData = {
            title: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmPaymentRemoveModal(paymentData: RemovePaymentData){
        setRemovePaymentData(paymentData);
        setisConfirmPaymentRemoveModalOpen(true);
    }
    function CloseConfirmPaymentRemoveModal(){
        setisConfirmPaymentRemoveModalOpen(false);
    }

    const [toEditBillData, setToEditBillData] = useState<EditBillFormData>(() => {

        const data: EditBillFormData = {
            id: 0,
            title: '',
            value: '',
            company: 0,
            category: 0,
            source: 0,
            expire: '',
            observation: '',
        };
        
        return data;
    });

    const [isEditBillModalOpen, setIsEditBillModalOpen] = useState(false);

    function OpenEditBillModal(BillData : EditBillFormData){
        setToEditBillData(BillData);
        setIsEditBillModalOpen(true);
    }
    function CloseEditBillModal(){
        setIsEditBillModalOpen(false);
    }

    const [isConfirmBillRemoveModalOpen, setisConfirmBillRemoveModalOpen] = useState(false);
    const [removeBillData, setRemoveBillData] = useState<RemoveBillData>(() => {

        const data: RemoveBillData = {
            title: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmBillRemoveModal(BillData: RemoveBillData){
        setRemoveBillData(BillData);
        setisConfirmBillRemoveModalOpen(true);
    }
    function CloseConfirmBillRemoveModal(){
        setisConfirmBillRemoveModalOpen(false);
    }

    const [paymentCategories, setPaymentCategories] = useState<PaymentCategory[]>([]);
    const [billCategories, setBillCategories] = useState<BillCategory[]>([]);

    const usersFilter: UserFilterData = {
        search: ''
    };

    const users = useUsers(usersFilter);
    const providers = useProviders();
    const sources = useSources();

    const loadPaymentCategories = async () => {
        const { data } = await api.get('/payment_categories');
        setPaymentCategories(data);
    }

    const loadBillCategories = async () => {
        const { data } = await api.get('/bill_categories');
        setBillCategories(data);
    }

    useEffect(() => {
        loadPaymentCategories();
        loadBillCategories();
    }, [])

    return (
        <Stack fontSize="13px" spacing="12">
            <ConfirmQuotaSaleCancelModal afterCancel={refetchQuotaSales} toCancelQuotaSaleData={cancelQuotaSaleData} isOpen={isConfirmQuotaSaleCancelModalOpen} onRequestClose={CloseConfirmQuotaSaleCancelModal}/>
            <ConfirmQuotaSaleRemoveModal afterRemove={refetchQuotaSales} toRemoveQuotaSaleData={removeQuotaSaleData} isOpen={isConfirmQuotaSaleRemoveModalOpen} onRequestClose={CloseConfirmQuotaSaleRemoveModal}/>

            <ReceiveBillModal afterReceive={refetchQuotaSales} toReceiveBillData={toReceiveBillData} isOpen={isReceiveBillModalOpen} onRequestClose={CloseReceiveBillModal}/>
            <EditBillModal categories={billCategories} toEditBillData={toEditBillData} users={users.data} sources={sources.data} afterEdit={refetchQuotaSales} isOpen={isEditBillModalOpen} onRequestClose={CloseEditBillModal}/>
            <ConfirmBillRemoveModal afterRemove={refetchQuotaSales} toRemoveBillData={removeBillData} isOpen={isConfirmBillRemoveModalOpen} onRequestClose={CloseConfirmBillRemoveModal}/>

            <PayPaymentModal afterPay={refetchQuotaSales} toPayPaymentData={toPayPaymentData} isOpen={isPayPaymentModalOpen} onRequestClose={ClosePayPaymentModal}/>
            <EditPaymentModal categories={paymentCategories} toEditPaymentData={toEditPaymentData} users={users.data} providers={providers.data} afterEdit={refetchQuotaSales} isOpen={isEditPaymentModalOpen} onRequestClose={CloseEditPaymentModal}/>
            <ConfirmPaymentRemoveModal afterRemove={refetchQuotaSales} toRemovePaymentData={removePaymentData} isOpen={isConfirmPaymentRemoveModalOpen} onRequestClose={CloseConfirmPaymentRemoveModal}/>


            {
                 Object.keys(quotaSales).map((month:string) => {
                    let monthTotalQuotasCount = 0;
                    monthTotalQuotasCount = monthTotalQuotasCount + quotaSales[month].reduce((sumAmount:number, quotaSale:QuotaSale) => {
                        return sumAmount + (quotaSale.ready_quota ? 1 : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.length : 0);
                    }, 0);
            
                    let monthTotalValue = 0;
                    monthTotalValue =  monthTotalValue + quotaSales[month].reduce((sumAmount:number, quotaSale:QuotaSale) => {
                        return sumAmount + quotaSale.value;
                    }, 0);
            
                    let monthTotalCredit = 0;
                    monthTotalCredit =  monthTotalCredit + quotaSales[month].reduce((sumAmount:number, quotaSale:QuotaSale) => {
                        return sumAmount + (quotaSale.ready_quota ? quotaSale.ready_quota.credit : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + (saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}, 0) : 0);
                    }, 0);

                    return(
                        <Accordion w="100%" border="2px" borderColor="gray.500" borderRadius="26" overflow="hidden" spacing="0" allowMultiple>
                            <HStack spacing="8" justify="space-between" paddingX="8" paddingY="3" bg="gray.200">
                                <Text fontWeight="extrabold">{month}</Text>
                                <Text fontWeight="extrabold">{monthTotalQuotasCount} COTAS</Text>
                                
                                <Text float="right">TOTAL em entradas: <strong>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(monthTotalValue)}</strong></Text>
                                <Text float="right">TOTAL em crédito: <strong>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(monthTotalCredit)}</strong></Text>
                            </HStack>

                            {
                                    quotaSales[month].map((quotaSale:QuotaSale) => {
                                        const creditAmount = (quotaSale.ready_quota ? quotaSale.ready_quota.credit : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + saleReadyQuota.ready_quota.credit}, 0) : 0)
                                        const totalCost = (quotaSale.ready_quota && quotaSale.ready_quota.total_cost ? quotaSale.ready_quota.total_cost : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + (saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}, 0) : 0)
                                        
                                        console.log(quotaSale.sale_ready_quotas[0].id);
                                        return(
                                            <AccordionItem key={quotaSale.id} display="flex" flexDir="column" paddingX="8" paddingTop="3" bg="white" borderTop="2px" borderTopColor="gray.500" borderBottom="0">
                                                {({ isExpanded }) => (
                                                    <>
                                                        <HStack justify="space-between" mb="3" opacity={quotaSale.cancelled ? 0.5 : 1}>
                                                            <AccordionButton p="0" height="fit-content" w="auto">
                                                                <Flex alignItems="center" justifyContent="center" h="24px" w="30px" p="0" borderRadius="full" border="2px" borderColor="blue.800" variant="outline">
                                                                { 
                                                                        !isExpanded ? <StrongPlusIcon stroke="#2a4365" fill="none" width="12px"/> :
                                                                        <MinusIcon stroke="#2a4365" fill="none" width="12px"/>
                                                                } 
                                                                </Flex>
                                                            </AccordionButton>

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800">{quotaSale.sale_date ? formatBRDate(quotaSale.sale_date) : "--"}</Text>
                                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{quotaSale.id}</Text>
                                                            </Stack>

                                                            <HStack spacing="4">
                                                                <HomeIcon stroke="#4e4b66" fill="none" width="22px"/>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Valor da venda</Text>
                                                                    <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(creditAmount)}</Text>
                                                                </Stack>
                                                            </HStack>

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Valor recebido</Text>
                                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.value)} ({((quotaSale.value*100)/creditAmount).toFixed(0)}%)</Text>
                                                            </Stack>

                                                            {
                                                                quotaSale.ready_quota && (
                                                                    <Stack spacing="0">
                                                                        <Text fontSize="12px" color="gray.800">Grupo: <strong>{quotaSale.ready_quota.group}</strong></Text>
                                                                        <Text fontSize="12px" color="gray.800">Cota: <strong>{quotaSale.ready_quota.quota}</strong></Text>
                                                                    </Stack>
                                                                )
                                                            }

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Cotas</Text>
                                                                <HStack>
                                                                {
                                                                    (quotaSale.sale_ready_quotas.length > 0) && quotaSale.sale_ready_quotas.map((saleReadyQuota) => {
                                                                        return(
                                                                            <Text fontSize="sm" fontWeight="bold" color="gray.800">{saleReadyQuota.ready_quota.group}-{saleReadyQuota.ready_quota.quota},</Text>
                                                                        )
                                                                    })
                                                                }
                                                                </HStack>
                                                            </Stack>
                        
                                                            <HStack spacing="5">
                                                                {
                                                                    quotaSale.cancelled && (
                                                                        <HStack>
                                                                            <Flex fontWeight="bold" alignItems="center" color="red.400">
                                                                                <CloseIcon stroke="#c30052" fill="none" width="16px"/>
                                                                                <Text ml="2">Cancelada</Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                    )
                                                                }

                                                                {/* <EditButton disabled={!!quotaSale.cancelled} onClick={() => history.push(`editar-venda/${quotaSale.id}`)}/> */}
                                                                <EditButton disabled={true} onClick={() => history.push(`editar-venda/${quotaSale.id}`)}/>
                                                            </HStack>
                                                        </HStack>
                        
                                                        <AccordionPanel flexDir="column" borderTop="2px" borderColor="gray.500" px="0" py="5" opacity={quotaSale.cancelled ? 0.5 : 1}>
                                                            <HStack justifyContent="space-between" marginBottom="4">
                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Lucro</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.profit - (totalCost ? totalCost : 0))}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Taxa</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.tax ? quotaSale.tax : 0)}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Vendido por</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.salesman?.name}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Comprado por</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.buyer?.name}</Text>
                                                                </Stack>
                                                            </HStack>

                                                            <HStack justifyContent="space-between" mb="4">
                                                                {/* <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Coordenador</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.coordinator ? quotaSale.coordinator.name : '--'}</Text>
                                                                </Stack> */}

                                                                {/* <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Comissão do coordenador</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.coordinator_value ? quotaSale.coordinator_value : 0)}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Supervisor</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.supervisor  ? quotaSale.supervisor.name : '--'}</Text>
                                                                </Stack> */}

                                                                {/* <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Comissão supervisor</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.supervisor_value ? quotaSale.supervisor_value : 0)}</Text>
                                                                </Stack> */}

                                                                {/* <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Corretor</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.broker  ? quotaSale.broker.name : '--'}</Text>
                                                                </Stack> */}

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Comissão do vendedor</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.supervisor_value ? quotaSale.supervisor_value : 0)}</Text>
                                                                </Stack>
                                                            </HStack>

                                                            <HStack mt="4" mb="3" justifyContent="space-between">
                                                                <Flex alignItems="center">
                                                                    <Text fontWeight="500" mr="2">Descrição: </Text>
                                                                    <Text> {quotaSale.description && quotaSale.description}</Text>
                                                                </Flex>

                                                                <HStack spacing="5" alignItems="center">
                                                                    <SolidButton disabled={!!quotaSale.cancelled} colorScheme="red" h="29px" pl="5" pr="5" bg="red.400" minWidth="none" onClick={() => OpenConfirmQuotaSaleCancelModal({ id: quotaSale.id}) }>
                                                                        Cancelar Venda
                                                                    </SolidButton>
                                                                    <RemoveButton onClick={() => OpenConfirmQuotaSaleRemoveModal({ id: quotaSale.id }) }/>
                                                                </HStack>
                                                            </HStack>

                                                            <Stack pb="6" pt="3">
                                                                <HStack>
                                                                    <Text fontWeight="700" mr="2">Cotas vendidas: </Text>
                                                                </HStack>
                                                                {/* <Divider /> */}
                                                                {/* {
                                                                    quotaSale.ready_quota && (
                                                                        <HStack justifyContent={"space-between"}>
                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Cota</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.ready_quota.group} - {quotaSale.ready_quota.quota}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Valor</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.value ? quotaSale.ready_quota.value : 0)}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Crédito</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.credit ? quotaSale.ready_quota.credit : 0)}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="12px" color="gray.800">{quotaSale.ready_quota.deadline ? `${quotaSale.ready_quota.deadline}x` : "--"}</Text>
                                                                                <Text fontSize="sm" fontWeight="normal" color="gray.800">{quotaSale.ready_quota.parcel ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.parcel) : ""}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Custo</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.cost ? quotaSale.ready_quota.cost : 0)}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Custo Parceiro</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.partner_cost ? quotaSale.ready_quota.partner_cost : 0)}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Lucro Parceiro</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (quotaSale.partner_profit) - (quotaSale.ready_quota.partner_cost ? quotaSale.ready_quota.partner_cost : 0))}</Text>
                                                                            </Stack>

                                                                            <Stack spacing="0">
                                                                                <Text fontSize="10px" color="gray.800">Ganho do Investidor</Text>
                                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (saleReadyQuota.investor_value))}</Text>
                                                                            </Stack>
                                                                        </HStack>
                                                                    )
                                                                } */}
                                                                {
                                                                    quotaSale.sale_ready_quotas.map((saleReadyQuota) => {
                                                                        return(
                                                                            <Stack spacing="2" padding="3" bg="gray.100" borderRadius="16px">
                                                                                <HStack justifyContent={"space-between"} key={saleReadyQuota.id}>
                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Cota</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{saleReadyQuota.ready_quota.group} - {saleReadyQuota.ready_quota.quota}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Valor</Text>
                                                                                        <Text fontSize="12px" fontWeight="semibold" color="gray.600">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.value ? saleReadyQuota.ready_quota.value : 0)}</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.value)}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Crédito</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.credit ? saleReadyQuota.ready_quota.credit : 0)}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="12px" color="gray.800">{saleReadyQuota.ready_quota.deadline ? `${saleReadyQuota.ready_quota.deadline}x` : "--"}</Text>
                                                                                        <Text fontSize="sm" fontWeight="normal" color="gray.800">{saleReadyQuota.ready_quota.parcel ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.parcel) : ""}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Custo</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Lucro</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.profit ? saleReadyQuota.profit : 0)}</Text>
                                                                                    </Stack>
                                                                                </HStack>

                                                                                <HStack justifyContent={"space-between"} key={saleReadyQuota.id}>
                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Comissão do coordenador</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.coordinator_commission ? saleReadyQuota.ready_quota.coordinator_commission : 0)}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Comissão do supervisor</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format((saleReadyQuota.ready_quota.supervisor_commission ? saleReadyQuota.ready_quota.supervisor_commission : 0))}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Custo Parceiro</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.partner_cost ? saleReadyQuota.ready_quota.partner_cost : 0)}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Lucro Parceiro</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (saleReadyQuota.partner_profit) - (saleReadyQuota.ready_quota.partner_cost ? saleReadyQuota.ready_quota.partner_cost : 0))}</Text>
                                                                                    </Stack>

                                                                                    <Stack spacing="0">
                                                                                        <Text fontSize="10px" color="gray.800">Ganho do Investidor</Text>
                                                                                        <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (saleReadyQuota.investor_value))}</Text>
                                                                                    </Stack>
                                                                                </HStack>
                                                                            </Stack>
                                                                        )
                                                                    })
                                                                }
                                                            </Stack>

                                                            <Divider mb="3"/>

                                                            <HStack justifyContent="space-between" alignItems="center">
                                                                <Table size="sm" variant="simple">
                                                                    <Thead>
                                                                        <Tr>
                                                                            <Th color="gray.900">Valores a receber: </Th>
                                                                            <Th></Th>
                                                                            <Th></Th>
                                                                            <Th></Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                        {
                                                                            quotaSale.bills && quotaSale.bills.map((bill) => {
                                                                                const billToEditData:EditBillFormData = {
                                                                                    id: bill.id,
                                                                                    title: bill.title,
                                                                                    value: bill.value.toString().replace('.', ','),
                                                                                    paid: bill.paid.toString().replace('.', ','),
                                                                                    company: bill.company,
                                                                                    category: bill.category,
                                                                                    source: bill.source,
                                                                                    status: bill.status,
                                                                                    expire: bill.expire,
                                                                                    observation: bill.observation,
                                                                                }

                                                                                return (
                                                                                    <Tr>
                                                                                        <Td fontSize="12px">{bill.expire && formatBRDate(bill.expire)}</Td>
                                                                                        <Td fontSize="12px">{bill.title}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(bill.status ? (bill.paid > 0 ? bill.paid : bill.value) : bill.value - bill.paid)}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                                            {
                                                                                                bill.status ? (
                                                                                                    <HStack>
                                                                                                        <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                                            <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                                            <Text ml="2">Recebido</Text>
                                                                                                        </Flex>

                                                                                                        <IconButton onClick={() => handleReverseBill(bill.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                                    </HStack>
                                                                                                ) : (
                                                                                                    <OutlineButton disabled={!!quotaSale.cancelled}
                                                                                                        h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                                        onClick={() => OpenReceiveBillModal({ id: bill.id, title: bill.title , value: bill.value, paid: bill.paid, status: bill.status, new_value: ''})}>
                                                                                                        Receber
                                                                                                    </OutlineButton>
                                                                                                )
                                                                                            }
                                                                                        </Td>
                                                                                        <Td>
                                                                                            <IconButton onClick={() => OpenConfirmBillRemoveModal({ id: bill.id, title: bill.title }) } h="24px" w="23px" p="0" float="right" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                                            <IconButton onClick={() => OpenEditBillModal(billToEditData)} h="24px" w="23px" p="0" float="right" aria-label="Alterar parcial" border="none" icon={ <EditIcon width="20px" stroke="#d69e2e" fill="none"/>} variant="outline"/>
                                                                                        </Td>
                                                                                    </Tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tbody>
                                                                </Table>
                                                            </HStack>

                                                            <Divider />

                                                            <HStack justifyContent="space-between" alignItems="center" mt="4">
                                                                <Table size="sm" variant="simple">
                                                                    <Thead>
                                                                        <Tr>
                                                                            <Th color="gray.900">Valores a apagar: </Th>
                                                                            <Th></Th>
                                                                            <Th></Th>
                                                                            <Th></Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                        
                                                                        {
                                                                            quotaSale.payments && quotaSale.payments.map((payment) => {
                                                                                const paymentToEditData:EditPaymentFormData = {
                                                                                    id: payment.id,
                                                                                    title: payment.title,
                                                                                    value: payment.value.toString().replace('.', ','),
                                                                                    paid: payment.paid.toString().replace('.', ','),
                                                                                    company: payment.company,
                                                                                    category: payment.category,
                                                                                    provider: payment.provider,
                                                                                    pay_to_user: payment.pay_to_user?.id,
                                                                                    //status: payment.status,
                                                                                    expire: payment.expire,
                                                                                    observation: payment.observation,
                                                                                    contract: payment.contract,
                                                                                    group: payment.group,
                                                                                    quote: payment.quote,
                                                                                    recurrence: payment.recurrence,
                                                                                    file: payment.file,
                                                                                }

                                                                                console.log(paymentToEditData);

                                                                                return (
                                                                                    <Tr>
                                                                                        <Td fontSize="12px">{payment.expire && formatBRDate(payment.expire)}</Td>
                                                                                        <Td fontSize="12px">{payment.title}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(payment.status ? (payment.paid > 0 ? payment.paid : payment.value) : payment.value - payment.paid)}</Td>
                                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                                            {
                                                                                                payment.status ? (
                                                                                                    <HStack>
                                                                                                        <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                                            <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                                            <Text ml="2">Pago</Text>
                                                                                                        </Flex>

                                                                                                        <IconButton onClick={() => handleReverseBill(payment.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                                    </HStack>
                                                                                                ) : (
                                                                                                    <OutlineButton disabled={!!quotaSale.cancelled}
                                                                                                        h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                                        onClick={() => OpenPayPaymentModal({ id: payment.id, title: payment.title , value: payment.value, paid: payment.paid, status: payment.status, new_value: ''})}>
                                                                                                        Pagar
                                                                                                    </OutlineButton>
                                                                                                )
                                                                                            }
                                                                                        </Td>
                                                                                        <Td>
                                                                                            <IconButton onClick={() => OpenConfirmPaymentRemoveModal({ id: payment.id, title: payment.title }) } h="24px" w="23px" p="0" float="right" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                                            <IconButton onClick={() => OpenEditPaymentModal(paymentToEditData)} h="24px" w="23px" p="0" float="right" aria-label="Alterar parcial" border="none" icon={ <EditIcon width="20px" stroke="#d69e2e" fill="none"/>} variant="outline"/>
                                                                                        </Td>
                                                                                    </Tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tbody>
                                                                </Table>
                                                            </HStack>
                        
                                                        </AccordionPanel>
                                                    </>
                                                )}
                                            </AccordionItem>
                                        )
                                    })
                            }
                            
                        </Accordion>
                    )
                 })
            }

            {/* <Accordion w="100%" border="2px" borderColor="gray.500" borderRadius="26" overflow="hidden" spacing="0" allowMultiple>
                <HStack spacing="8" justify="space-between" paddingX="8" paddingY="3" bg="gray.200">
                    <Text fontWeight="extrabold">{totalQuotasCount} COTAS</Text>
                    
                    <Text float="right">TOTAL em entradas: <strong>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalValue)}</strong></Text>
                    <Text float="right">TOTAL em crédito: <strong>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalCredit)}</strong></Text>
                </HStack>

                { quotaSales.map((quotaSale:QuotaSale) => {
                        if(quotaSale.ready_quota){
                            console.log(`id: ${quotaSale.id}`,quotaSale.ready_quota.total_cost);
                        }
                        const creditAmount = (quotaSale.ready_quota ? quotaSale.ready_quota.credit : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + saleReadyQuota.ready_quota.credit}, 0) : 0)
                        const totalCost = (quotaSale.ready_quota && quotaSale.ready_quota.total_cost ? quotaSale.ready_quota.total_cost : 0) + (quotaSale.sale_ready_quotas.length > 0 ? quotaSale.sale_ready_quotas.reduce((sumAmount, saleReadyQuota) => {return sumAmount + (saleReadyQuota.ready_quota.total_cost ? saleReadyQuota.ready_quota.total_cost : 0)}, 0) : 0)

                        return (
                            <AccordionItem key={quotaSale.id} display="flex" flexDir="column" paddingX="8" paddingTop="3" bg="white" borderTop="2px" borderTopColor="gray.500" borderBottom="0">
                                {({ isExpanded }) => (
                                    <>
                                        <HStack justify="space-between" mb="3" opacity={quotaSale.cancelled ? 0.5 : 1}>
                                            <AccordionButton p="0" height="fit-content" w="auto">
                                                <Flex alignItems="center" justifyContent="center" h="24px" w="30px" p="0" borderRadius="full" border="2px" borderColor="blue.800" variant="outline">
                                                { 
                                                        !isExpanded ? <StrongPlusIcon stroke="#2a4365" fill="none" width="12px"/> :
                                                        <MinusIcon stroke="#2a4365" fill="none" width="12px"/>
                                                } 
                                                </Flex>
                                            </AccordionButton>

                                            <Stack spacing="0">
                                                <Text fontSize="10px" color="gray.800">{quotaSale.sale_date ? formatBRDate(quotaSale.sale_date) : "--"}</Text>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{quotaSale.id}</Text>
                                            </Stack>

                                            <HStack spacing="4">
                                                <HomeIcon stroke="#4e4b66" fill="none" width="22px"/>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Valor da venda</Text>
                                                    <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(creditAmount)}</Text>
                                                </Stack>
                                            </HStack>

                                            <Stack spacing="0">
                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Valor recebido</Text>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.value)}</Text>
                                            </Stack>

                                            {
                                                quotaSale.ready_quota && (
                                                    <Stack spacing="0">
                                                        <Text fontSize="12px" color="gray.800">Grupo: <strong>{quotaSale.ready_quota.group}</strong></Text>
                                                        <Text fontSize="12px" color="gray.800">Cota: <strong>{quotaSale.ready_quota.quota}</strong></Text>
                                                    </Stack>
                                                )
                                            }

                                            <Stack spacing="0">
                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Cotas</Text>
                                                <HStack>
                                                {
                                                    (quotaSale.sale_ready_quotas.length > 0) && quotaSale.sale_ready_quotas.map((saleReadyQuota) => {
                                                        return(
                                                            <Text fontSize="sm" fontWeight="bold" color="gray.800">{saleReadyQuota.ready_quota.group}-{saleReadyQuota.ready_quota.quota},</Text>
                                                        )
                                                    })
                                                }
                                                </HStack>
                                            </Stack>
        
                                            <HStack spacing="5">
                                                {
                                                    quotaSale.cancelled && (
                                                        <HStack>
                                                            <Flex fontWeight="bold" alignItems="center" color="red.400">
                                                                <CloseIcon stroke="#c30052" fill="none" width="16px"/>
                                                                <Text ml="2">Cancelada</Text>
                                                            </Flex>
                                                        </HStack>
                                                    )
                                                }

                                                <EditButton disabled={!!quotaSale.cancelled} onClick={() => history.push(`editar-venda/${quotaSale.ready_quota.id}/${quotaSale.id}`)}/>
                                            </HStack>
        
                                        </HStack>
        
                                        <AccordionPanel flexDir="column" borderTop="2px" borderColor="gray.500" px="0" py="5" opacity={quotaSale.cancelled ? 0.5 : 1}>
                                            <HStack justifyContent="space-between" marginBottom="4">
                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Lucro</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.profit - (totalCost ? totalCost : 0))}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Taxa</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.tax ? quotaSale.tax : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">CPF/CNPJ</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.cpf_cnpj}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Vendido por</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.seller}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Comprado por</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.buyer}</Text>
                                                </Stack>
                                            </HStack>

                                            <HStack justifyContent="space-between" mb="4">
                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Coordenador</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.coordinator ? quotaSale.coordinator.name : '--'}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Comissão do coordenador</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.coordinator_value ? quotaSale.coordinator_value : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Supervisor</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.supervisor  ? quotaSale.supervisor.name : '--'}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Comissão supervisor</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.supervisor_value ? quotaSale.supervisor_value : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Corretor</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.broker  ? quotaSale.broker.name : '--'}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Comissão corretor</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.supervisor_value ? quotaSale.supervisor_value : 0)}</Text>
                                                </Stack>
                                            </HStack>

                                            <HStack mt="4" mb="3" justifyContent="space-between">
                                                <Flex alignItems="center">
                                                    <Text fontWeight="500" mr="2">Descrição: </Text>
                                                    <Text> {quotaSale.description && quotaSale.description}</Text>
                                                </Flex>

                                                <HStack spacing="5" alignItems="center">
                                                    <SolidButton disabled={!!quotaSale.cancelled} colorScheme="red" h="29px" pl="5" pr="5" bg="red.400" minWidth="none" onClick={() => OpenConfirmQuotaSaleCancelModal({ id: quotaSale.id}) }>
                                                        Cancelar Venda
                                                    </SolidButton>
                                                    <RemoveButton onClick={() => OpenConfirmQuotaSaleRemoveModal({ id: quotaSale.id }) }/>
                                                </HStack>
                                            </HStack>

                                            <Stack pb="6" pt="3">
                                                <HStack>
                                                    <Text fontWeight="700" mr="2">Cotas vendidas: </Text>
                                                </HStack>
                                                <Divider />
                                                {
                                                    quotaSale.ready_quota && (
                                                        <HStack justifyContent={"space-between"}>
                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800">Cota</Text>
                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quotaSale.ready_quota.group} - {quotaSale.ready_quota.quota}</Text>
                                                            </Stack>

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800">Crédito</Text>
                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.credit ? quotaSale.ready_quota.credit : 0)}</Text>
                                                            </Stack>

                                                            <Stack spacing="0">
                                                                <Text fontSize="12px" color="gray.800">{quotaSale.ready_quota.deadline ? `${quotaSale.ready_quota.deadline}x` : "--"}</Text>
                                                                <Text fontSize="sm" fontWeight="normal" color="gray.800">{quotaSale.ready_quota.parcel ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.parcel) : ""}</Text>
                                                            </Stack>

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800">Custo</Text>
                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.cost ? quotaSale.ready_quota.cost : 0)}</Text>
                                                            </Stack>

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800">Custo Parceiro</Text>
                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSale.ready_quota.partner_cost ? quotaSale.ready_quota.partner_cost : 0)}</Text>
                                                            </Stack>

                                                            <Stack spacing="0">
                                                                <Text fontSize="10px" color="gray.800">Lucro Parceiro</Text>
                                                                <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (quotaSale.partner_profit) - (quotaSale.ready_quota.partner_cost ? quotaSale.ready_quota.partner_cost : 0))}</Text>
                                                            </Stack>
                                                        </HStack>
                                                    )
                                                }
                                                {
                                                    quotaSale.sale_ready_quotas.map((saleReadyQuota) => {
                                                        return(
                                                            <HStack justifyContent={"space-between"} key={saleReadyQuota.id}>
                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Cota</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{saleReadyQuota.ready_quota.group} - {saleReadyQuota.ready_quota.quota}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Crédito</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.credit ? saleReadyQuota.ready_quota.credit : 0)}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="12px" color="gray.800">{saleReadyQuota.ready_quota.deadline ? `${saleReadyQuota.ready_quota.deadline}x` : "--"}</Text>
                                                                    <Text fontSize="sm" fontWeight="normal" color="gray.800">{saleReadyQuota.ready_quota.parcel ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.parcel) : ""}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Custo</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.cost ? saleReadyQuota.ready_quota.cost : 0)}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Custo Parceiro</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(saleReadyQuota.ready_quota.partner_cost ? saleReadyQuota.ready_quota.partner_cost : 0)}</Text>
                                                                </Stack>

                                                                <Stack spacing="0">
                                                                    <Text fontSize="10px" color="gray.800">Lucro Parceiro</Text>
                                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format( (saleReadyQuota.partner_profit) - (saleReadyQuota.ready_quota.partner_cost ? saleReadyQuota.ready_quota.partner_cost : 0))}</Text>
                                                                </Stack>
                                                            </HStack>
                                                        )
                                                    })
                                                }
                                            </Stack>

                                            <Divider mb="3"/>

                                            <HStack justifyContent="space-between" alignItems="center">
                                                <Table size="sm" variant="simple">
                                                    <Thead>
                                                        <Tr>
                                                            <Th color="gray.900">Valores a receber: </Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        
                                                        {
                                                            quotaSale.bills && quotaSale.bills.map((bill: ResumedBill) => {
                                                                return (
                                                                    <Tr>
                                                                        <Td fontSize="12px">{bill.expire && formatBRDate(bill.expire)}</Td>
                                                                        <Td fontSize="12px">{bill.title}</Td>
                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(bill.status ? (bill.paid > 0 ? bill.paid : bill.value) : bill.value - bill.paid)}</Td>
                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                            {
                                                                                bill.status ? (
                                                                                    <HStack>
                                                                                        <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                            <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                            <Text ml="2">Recebido</Text>
                                                                                        </Flex>

                                                                                        <IconButton onClick={() => handleReverseBill(bill.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                    </HStack>
                                                                                ) : (
                                                                                    <OutlineButton disabled={!!quotaSale.cancelled}
                                                                                        h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                        onClick={() => OpenReceiveBillModal({ id: bill.id, title: bill.title , value: bill.value, paid: bill.paid, status: bill.status, new_value: ''})}>
                                                                                        Receber
                                                                                    </OutlineButton>
                                                                                )
                                                                            }
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </HStack>

                                            <Divider />

                                            <HStack justifyContent="space-between" alignItems="center" mt="4">
                                                <Table size="sm" variant="simple">
                                                    <Thead>
                                                        <Tr>
                                                            <Th color="gray.900">Valores a apagar: </Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        
                                                        {
                                                            quotaSale.payments && quotaSale.payments.map((payment: Payment) => {
                                                                return (
                                                                    <Tr>
                                                                        <Td fontSize="12px">{payment.expire && formatBRDate(payment.expire)}</Td>
                                                                        <Td fontSize="12px">{payment.title}</Td>
                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(payment.status ? (payment.paid > 0 ? payment.paid : payment.value) : payment.value - payment.paid)}</Td>
                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                            {
                                                                                payment.status ? (
                                                                                    <HStack>
                                                                                        <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                            <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                            <Text ml="2">Pago</Text>
                                                                                        </Flex>

                                                                                        <IconButton onClick={() => handleReverseBill(payment.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                    </HStack>
                                                                                ) : (
                                                                                    <OutlineButton disabled={!!quotaSale.cancelled}
                                                                                        h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                        onClick={() => OpenPayPaymentModal({ id: payment.id, title: payment.title , value: payment.value, paid: payment.paid, status: payment.status, new_value: ''})}>
                                                                                        Pagar
                                                                                    </OutlineButton>
                                                                                )
                                                                            }
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </HStack>
        
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        )
                    })
                }
                
            </Accordion> */}
        </Stack>
    )
}