import { useQuery } from "react-query";
import { api } from "../services/api";

export const getBrokers = async () => {
    const { data } = await api.get('/brokers');

    return data.data;
}

export function useBrokers(){
    return useQuery(['brokers'], () => getBrokers(), {
        staleTime: 1000 * 5 * 60, //fresh
    });
}