import { useQuery } from "react-query";
import { api } from "../services/api";

export const getCoordinators = async () => {
    const { data } = await api.get('/coordinators');

    return data.data;
}

export function useCoordinators(){
    return useQuery(['coordinators'], () => getCoordinators(), {
        staleTime: 1000 * 5 * 60, //fresh
    });
}