import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Checkbox, Divider, Flex, HStack, IconButton, Spinner, Stack, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react";
import { PaymentCategory, Quota, ReadyQuota } from "../../../types";

import { ReactComponent as PlusIcon } from '../../../assets/icons/Plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/Minus.svg';
import { ReactComponent as StrongPlusIcon } from '../../../assets/icons/StrongPlus.svg';
import { ReactComponent as EllipseIcon } from '../../../assets/icons/Ellipse.svg';
import { ReactComponent as TagIcon } from '../../../assets/icons/Tag.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/Check.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/File.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/Refresh.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/Home.svg';
import { ReactComponent as CarIcon } from '../../../assets/icons/Car.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/Star.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/Profile.svg';
import { ReactComponent as AlertInfoIcon } from '../../../assets/icons/Alert-info.svg';
import { OutlineButton } from "../../../components/Buttons/OutlineButton";
import { EditButton } from "../../../components/Buttons/EditButton";
import { RemoveButton } from "../../../components/Buttons/RemoveButton";
import { UseQueryResult } from "react-query";
import { formatBRDate } from "../../../utils/Date/formatBRDate";
import React, { ChangeEvent, useState } from "react";
import { EditQuota, EditQuotaModal } from "./EditQuotaModal";
import { ConfirmQuotaRemoveModal, RemoveQuotaData } from "./ConfirmQuotaRemoveModal";
import { Router, useHistory } from "react-router";
import { SolidButton } from "../../../components/Buttons/SolidButton";
import Sum from "./Sum";
import { monthNames } from "../../../utils/monthNames";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { api } from "../../../services/api";
import { showErrors } from "../../../hooks/useErrors";
import { EditPaymentFormData, EditPaymentModal } from "../../Financial/Payments/EditPaymentModal";
import { ConfirmPaymentRemoveModal } from "../../Financial/Payments/ConfirmPaymentRemoveModal";
import { PayPaymentFormData, PayPaymentModal } from "../../Financial/Payments/PayPaymentModal";
import { ReceiveBillFormData } from "../../Financial/Bills/ReceiveBillModal";
import { UserFilterData, useUsers } from "../../../hooks/useUsers";
import { useProviders } from "../../../hooks/useProviders";
import { useSources } from "../../../hooks/useSources";
import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg';


interface QuotasListProps{
    quotas: ReadyQuota[];
    refetchQuotas: () => void;
}

interface RemovePaymentData{
    id: number;
    title: string;
}

export function QuotasList({quotas, refetchQuotas}: QuotasListProps){
    const history = useHistory();
    const toast = useToast();
    const usersFilter: UserFilterData = {
        search: ''
    };
    const users = useUsers(usersFilter);
    const providers = useProviders();
    const sources = useSources();

    const [selectedQuotas, setSelectedQuotas] = useState<ReadyQuota[]>([]);

    const totalQuotasCount = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        return sumAmount + 1;
    }, 0);

    const totalRealtyCost = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        if(quota.segment === "Imóvel"){
            return sumAmount + (quota.cost ? quota.cost : 0);
        }

        return sumAmount;
    }, 0);

    const totalVehicleCost = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        if(quota.segment === "Veículo"){
            return sumAmount + (quota.cost ? quota.cost : 0);
        }

        return sumAmount;
    }, 0);

    const totalRealtyCredit = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        if(quota.segment === "Imóvel"){
            return sumAmount + quota.credit;
        }

        return sumAmount;
    }, 0);

    const totalVehicleCredit = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        if(quota.segment === "Veículo"){
            return sumAmount + quota.credit;
        }

        return sumAmount;
    }, 0);

    const totalRealtyCount = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        if(quota.segment === "Imóvel"){
            return sumAmount + 1;
        }

        return sumAmount;
    }, 0);

    const totalVehicleCount = quotas.reduce((sumAmount:number, quota:ReadyQuota) => {
        if(quota.segment === "Veículo"){
            return sumAmount + 1;
        }

        return sumAmount;
    }, 0);

    const [isEditQuotaModalOpen, setIsEditQuotaModalOpen] = useState(false);
    const [editQuotaData, setEditQuotaData] = useState<EditQuota>(() => {

        const data: EditQuota = {
            id: 0,
            credit: '',
            sold: false,
            value: '',
            deadline: 0,
            parcel: '',
            segment: '',
            company: 0,
            seller: '',
            contemplated_type: '',
            is_contemplated: false,
            is_hot: false,
            cost: '',
            total_cost: '',
            cpf_cnpj: '',
            partner_id: 0,
            partner_percent: 0,
            partner_cost: '',
            passed_cost: '',
            paid_percent: '',
            purchase_date: '',
            description: '',
            group: '',
            quota: '',
        };
        
        return data;
    });

    function OpenEditQuotaModal(quotaData: EditQuota){
        setEditQuotaData(quotaData);
        setIsEditQuotaModalOpen(true);
    }
    function CloseEditQuotaModal(){
        setIsEditQuotaModalOpen(false);
    }

    const [isConfirmQuotaRemoveModalOpen, setisConfirmQuotaRemoveModalOpen] = useState(false);
    const [removeQuotaData, setRemoveQuotaData] = useState<RemoveQuotaData>(() => {

        const data: RemoveQuotaData = {
            group: '',
            quota: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmQuotaRemoveModal(QuotaData: RemoveQuotaData){
        setRemoveQuotaData(QuotaData);
        setisConfirmQuotaRemoveModalOpen(true);
    }
    function CloseConfirmQuotaRemoveModal(){
        setisConfirmQuotaRemoveModalOpen(false);
    }

    const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target?.checked){
            setSelectedQuotas([...selectedQuotas, quotas.filter(quota => quota.id === parseInt(event.target?.value))[0]]);
        }else{
            setSelectedQuotas(selectedQuotas.filter((quota) => quota.id !== parseInt(event.target?.value)));
        }
    }

    const [isSumModalOpen, setIsSumModalOpen] = useState(false);

    const handleOpenSumModal = () => {
        if(selectedQuotas.length > 0){
            setIsSumModalOpen(true);
        }else{
            toast({
                title: "Ops",
                description: `Nenhuma cota selecionada`,
                status: "warning",
                duration: 12000,
                isClosable: true,
            });

        }
    }

    const handleCloseSumModal = () => {
        setIsSumModalOpen(false);
    }

    const handleChangeReserved = async (id : number, event: ChangeEvent<HTMLInputElement>) => {
        try{
            await api.post(`/ready_quotas/update/${id}`, {reserved: event.target.checked});

            // toast({
            //     title: "Sucesso",
            //     description: `Dados da cota ${toEditQuotaData.group}-${toEditQuotaData.quota} atualizados.`,
            //     status: "success",
            //     duration: 12000,
            //     isClosable: true,
            // });

            refetchQuotas()
        }catch(error: any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [isEditPaymentModalOpen, setIsEditPaymentModalOpen] = useState(false);

    function OpenEditPaymentModal(paymentData: EditPaymentFormData){
        setToEditPaymentData(paymentData);
        setIsEditPaymentModalOpen(true);
    }

    function CloseEditPaymentModal(){
        setIsEditPaymentModalOpen(false);
    }

    const [toEditPaymentData, setToEditPaymentData] = useState<EditPaymentFormData>(() => {

        const data: EditPaymentFormData = {
            id: 0,
            title: '',
            value: '',
            paid: '',
            company: 0,
            category: 0,
            provider: 0,
            pay_to_user: 0,
            //status: false,
            expire: '',
            observation: '',
            contract: '',
            group: '',
            quote: '',
            recurrence: 0,
            file: [],
        };
        
        return data;
    });

    const [isConfirmPaymentRemoveModalOpen, setisConfirmPaymentRemoveModalOpen] = useState(false);
    const [removePaymentData, setRemovePaymentData] = useState<RemovePaymentData>(() => {

        const data: RemovePaymentData = {
            title: '',
            id: 0,
        };
        
        return data;
    });

    function OpenConfirmPaymentRemoveModal(paymentData: RemovePaymentData){
        setRemovePaymentData(paymentData);
        setisConfirmPaymentRemoveModalOpen(true);
    }
    function CloseConfirmPaymentRemoveModal(){
        setisConfirmPaymentRemoveModalOpen(false);
    }

    const [isPayPaymentModalOpen, setIsPayPaymentModalOpen] = useState(false);
    const [toPayPaymentData, setToPayPaymentData] = useState<ReceiveBillFormData>(() => {

        const data: ReceiveBillFormData = {
            id: 0,
            value: 0,
            paid: 0,
            new_value: '',
            title: '',
        };
        
        return data;
    });

    function OpenPayPaymentModal(billIdAndName: PayPaymentFormData){
        setToPayPaymentData(billIdAndName);
        setIsPayPaymentModalOpen(true);
    }

    function ClosePayPaymentModal(){
        setIsPayPaymentModalOpen(false);
    }

    const handleReverseBill = async (billId : number) => {
        try{
            await api.post(`/bills/unreceive/${billId}`);

            toast({
                title: "Sucesso",
                description: `Conta a receber redefinda como não recebida.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            refetchQuotas();
        }catch(error: any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [paymentCategories, setPaymentCategories] = useState<PaymentCategory[]>([]);

    return (
        <Stack fontSize="13px" spacing="12">
            <EditQuotaModal toEditQuotaData={editQuotaData} afterEdit={refetchQuotas} isOpen={isEditQuotaModalOpen} onRequestClose={CloseEditQuotaModal}/>
            <ConfirmQuotaRemoveModal afterRemove={refetchQuotas} toRemoveQuotaData={removeQuotaData} isOpen={isConfirmQuotaRemoveModalOpen} onRequestClose={CloseConfirmQuotaRemoveModal}/>

            <Sum selectedQuotas={selectedQuotas} isOpen={isSumModalOpen} onRequestClose={handleCloseSumModal}/>

            <PayPaymentModal afterPay={refetchQuotas} toPayPaymentData={toPayPaymentData} isOpen={isPayPaymentModalOpen} onRequestClose={ClosePayPaymentModal}/>
            <EditPaymentModal categories={paymentCategories} toEditPaymentData={toEditPaymentData} users={users.data} providers={providers.data} afterEdit={refetchQuotas} isOpen={isEditPaymentModalOpen} onRequestClose={CloseEditPaymentModal}/>
            <ConfirmPaymentRemoveModal afterRemove={refetchQuotas} toRemovePaymentData={removePaymentData} isOpen={isConfirmPaymentRemoveModalOpen} onRequestClose={CloseConfirmPaymentRemoveModal}/>

            <Accordion w="100%" border="2px" borderColor="gray.500" borderRadius="26" overflow="hidden" spacing="0" allowMultiple>
                <HStack spacing="8" justify="space-between" paddingX="8" paddingY="3" bg="gray.200">
                    <Text fontWeight="extrabold">{totalQuotasCount} COTAS</Text>

                    <HStack spacing="6">
                        <Text fontWeight="semibold" color="gray.800">{totalRealtyCount} Imóveis</Text>
                        <Text fontWeight="semibold" color="gray.800">{totalVehicleCount} Veículos</Text>
                    </HStack>

                    <HStack spacing="7">
                        <Stack spacing="1">
                            <Text fontSize="10px" fontWeight="semibold">CUSTO total de Imóveis:</Text>
                            <Text float="right" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalRealtyCost)}</Text>
                        </Stack>

                        <Stack spacing="1">
                            <Text fontSize="10px" fontWeight="semibold">CUSTO total de Veículos:</Text>
                            <Text float="right" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalVehicleCost)}</Text>
                        </Stack>
                    </HStack>

                    <HStack spacing="7">
                        <Stack spacing="1">
                            <Text fontSize="10px" fontWeight="semibold">Total em CRÉDITO de Imóveis:</Text>
                            <Text float="right" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalRealtyCredit)}</Text>
                        </Stack>

                        <Stack spacing="1">
                            <Text fontSize="10px" fontWeight="semibold">Total em CRÉDITO de  Veículos:</Text>
                            <Text float="right" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalVehicleCredit)}</Text>
                        </Stack>
                    </HStack>
                </HStack>

                { quotas.map((quota:ReadyQuota) => {
                        const toEditQuotaData:EditQuota = {
                            id: quota.id,
                            sold: quota.sold,
                            credit: quota.credit.toFixed(2).replace('.',','),
                            common_fund: quota.common_fund ? quota.common_fund.toFixed(2).replace('.',',') : '',
                            value: quota.value ? quota.value.toFixed(2).replace('.',',') : '',
                            deadline: quota.deadline ? quota.deadline : 0,
                            parcel: quota.parcel ? quota.parcel.toFixed(2).replace('.',',') : '',
                            segment: quota.segment,
                            company: quota.company.id,
                            seller: quota.seller,
                            contemplated_type: quota.contemplated_type ? quota.contemplated_type : '',
                            cost: quota.cost ?  quota.cost.toFixed(2).replace('.',',') : '',
                            total_cost: quota.total_cost ? quota.total_cost.toFixed(2).replace('.',',') : "",
                            cpf_cnpj: quota.cpf_cnpj,
                            partner_id: quota.partner?.id,
                            partner_percent: quota.partner_percent,
                            partner_cost: quota.partner_cost ? quota.partner_cost.toFixed(2).replace('.',',') : '',
                            passed_cost: quota.passed_cost ? quota.passed_cost.toFixed(2).replace('.',',') : '',
                            purchase_date: quota.purchase_date,
                            paid_percent: quota.paid_percent,
                            description: quota.description,
                            group: quota.group,
                            quota: quota.quota,
                            tax: quota.tax ? quota.tax.toFixed(2).replace('.',',') : '',
                            is_contemplated: quota.is_contemplated,
                            is_hot: quota.is_hot,
                            month_adjust_number: quota.month_adjust_number,
                            adjust_index: quota.adjust_index,

                            investor_id: quota.investor_id,
                            investor_cost: quota.investor_cost ? quota.investor_cost.toFixed(2).replace('.',',') : '',
                            investor_percent: quota.investor_percent,

                            parcel_deadline: quota.parcel_deadline,

                            purchased_of_id: quota.purchased_of_id,
                            purchased_of_type: quota.purchased_of_type,

                            owner_type: quota.owner_type,
                            owner_id: quota.owner_id,

                            coordinator_id: quota.coordinator_id,
                            coordinator_commission: quota.coordinator_commission ? quota.coordinator_commission.toFixed(2).replace('.',',') : '',

                            supervisor_id: quota.supervisor_id,
                            supervisor_commission: quota.supervisor_commission ? quota.supervisor_commission.toFixed(2).replace('.',',') : ''
                        };

                        const isDraft = !quota.group || !quota.quota || !quota.purchase_date || !quota.cost || !quota.parcel || !quota.deadline;

                        return (
                            <AccordionItem key={quota.id} borderLeft={"3px solid"} borderLeftColor={quota.reserved ? "orange.400" : "gray.200"} display="flex" flexDir="column" paddingX="8" paddingTop="3" bg="white" borderTop="2px" borderTopColor="gray.500" borderBottom="0">
                                {({ isExpanded }) => (
                                    <>
                                        <HStack justify="space-between" mb="3">
                                            <HStack spacing={["3", "4"]}>
                                                <AccordionButton p="0" height="fit-content" w="auto">
                                                    <Flex alignItems="center" justifyContent="center" h="24px" w="30px" p="0" borderRadius="full" border="2px" borderColor="blue.800" variant="outline">
                                                    { 
                                                            !isExpanded ? <StrongPlusIcon stroke="#2a4365" fill="none" width="12px"/> :
                                                            <MinusIcon stroke="#2a4365" fill="none" width="12px"/>
                                                    } 
                                                    </Flex>
                                                </AccordionButton>

                                                <Checkbox label="" name="remove" checked={typeof selectedQuotas.find(selectedQuota => quota.id === selectedQuota.id) !== undefined} value={quota.id} onChange={handleSelect}
                                                    isDisabled={isDraft}
                                                />
                                            </HStack>

                                            <Stack spacing="0">
                                                <Text fontSize="10px" color="gray.800">{quota.purchase_date ? formatBRDate(quota.purchase_date) : "--"}</Text>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{quota.id}</Text>
                                            </Stack>

                                            <HStack spacing="4" opacity={quota.sold ? 0.5 : 1}>
                                                {
                                                    quota.segment === "Imóvel" ? (
                                                        <HomeIcon stroke="#4e4b66" fill="none" width="22px"/>
                                                    ) :(
                                                        <CarIcon fill="#4e4b66" width="22px"/>
                                                    )
                                                }

                                                <Stack spacing="0">
                                                    <Text fontSize="10px" color="gray.800">Crédito</Text>
                                                    <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.credit)}</Text>
                                                </Stack>
                                            </HStack>

                                            <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Entrada ({(((quota.value ? quota.value : 0) / quota.credit) * 100).toFixed(0)}%)</Text>
                                                <Text fontSize="sm" fontWeight="bold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.value ? quota.value : 0)}</Text>
                                            </Stack>

                                            <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                <Text fontSize="12px" color="gray.800">Grupo: <strong>{quota.group}</strong></Text>
                                                <Text fontSize="12px" color="gray.800">Cota: <strong>{quota.quota}</strong></Text>
                                            </Stack>

                                            <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                <Text fontSize="12px" color="gray.800">{quota.deadline ? `${quota.deadline}x` : "--"}</Text>
                                                <Text fontSize="sm" fontWeight="normal" color="gray.800">{quota.parcel ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.parcel) : ""}</Text>
                                            </Stack>

                                            <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Proprietário</Text>
                                                <Text fontSize="sm" fontWeight="normal" color="gray.800">{quota.owner ? quota.owner.name : "Própria"}</Text>
                                            </Stack>

                                            {/* <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                <Text fontSize="10px" color="gray.800" fontWeight="bold">Saldo Devedor</Text>
                                                <Text fontSize="sm" fontWeight="normal" color="gray.800">{(quota.parcel && quota.deadline) ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.parcel*quota.deadline) : "--"}</Text>
                                            </Stack> */}
        
                                            {
                                                quota.sold ? (
                                                    <Stack>
                                                        <HStack>
                                                            {
                                                                quota.is_hot && (
                                                                    <StarIcon stroke="#c9aa13" fill="none" width="22px"/>
                                                                )
                                                            }
                                                            {
                                                                quota.partner && (
                                                                    <UserIcon stroke="#4e4b66" fill="none" width="22px"/>
                                                                )
                                                            }
                                                            <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                <Text ml="2">Vendida</Text>
                                                            </Flex>
            
                                                            {/* <IconButton onClick={() => handleReversePayment(payment.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/> */}
                                                        </HStack>
                                                    </Stack>
                                                ) : (
                                                    <Stack>
                                                        <HStack>
                                                            <Checkbox label="Reservado" isChecked={quota.reserved} name="reserved" value={quota.id} onChange={(event:ChangeEvent<HTMLInputElement>) => handleChangeReserved(quota.id, event)}>
                                                                <Text fontSize={"12px"}>Cota reservada</Text>
                                                            </Checkbox>
                                                        </HStack>

                                                        <HStack>
                                                            {
                                                                quota.is_hot && (
                                                                    <StarIcon stroke="#c9aa13" fill="none" width="22px"/>
                                                                )
                                                            }
                                                            {/* {
                                                                quota.partner && (
                                                                    <UserIcon stroke="#4e4b66" fill="none" width="22px"/>
                                                                )
                                                            } */}
                                                            {
                                                                isDraft && (
                                                                    <Tooltip label={`Campos pendentes: ${!quota.group ? 'Grupo,' : ''} ${!quota.quota ? 'Cota,' : ''} ${!quota.purchase_date ? 'Data de compra,' : ''} ${!quota.cost ? 'Custo,' : ''} ${!quota.parcel ? "Valor da parcela," : ''} ${!quota.deadline ? "Prazo," : ''}`}>
                                                                        <AlertInfoIcon stroke="#df7326" fill="none" width="22px"/>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                            <OutlineButton isDisabled={quota.sold || isDraft} onClick={() => history.push(`/cadastrar-venda/${quota.id}`)}
                                                                h="30px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11">
                                                                Vender
                                                            </OutlineButton>
                                                        </HStack>
                                                    </Stack>
                                                )
                                            }
        
                                        </HStack>
        
                                        <AccordionPanel flexDir="column" borderTop="2px" borderColor="gray.500" px="0" py="5">
                                            <HStack justifyContent="space-between" marginBottom="4">
                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Custo</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.cost ? quota.cost : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Parceiro: {quota.partner ? quota.partner.name : "--"}</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">Custo: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_cost ? quota.partner_cost : 0)} ({quota.partner_percent}%)</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Investidor: {quota.investor ? quota.investor.name : "--"}</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">Custo: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.investor_cost ? quota.investor_cost : 0)} ({quota.investor_percent}%)</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Coordenador: {quota.coordinator ? quota.coordinator.name : "--"}</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">Custo: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.coordinator_commission ? quota.coordinator_commission : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Supervisor: {quota.supervisor ? quota.supervisor.name : "--"}</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">Custo: {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.supervisor_commission ? quota.supervisor_commission : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Custo Total</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.total_cost ? quota.total_cost : 0)} ({quota.paid_percent}%)</Text>
                                                </Stack>
                                            </HStack>

                                            <HStack justifyContent="space-between" mb="4">
                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" fontWeight="semibold" color="gray.800">Saldo Devedor</Text>
                                                    <Text fontSize="sm" color="gray.800">{(quota.parcel && quota.deadline) ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.parcel*quota.deadline) : "--"}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Taxa</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.tax ? parseFloat(quota.tax.toString().replace('.', ',')) : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Fundo comum</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.common_fund ? quota.common_fund : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Comprado de</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.purchased_of ? quota.purchased_of.name : ''}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Comprado por</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.buyer ? quota.buyer.name : ''}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">% paga pelo crédito</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.paid_percent ? parseFloat(quota.paid_percent).toFixed(2) : "--"}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Mês de reajuste</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.month_adjust_number ? monthNames[quota.month_adjust_number] : "--"}</Text>
                                                </Stack>
                                            </HStack>

                                            <HStack justifyContent="space-between" mb="4">

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Contemplado por</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.contemplated_type}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">CPF/CNPJ</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.cpf_cnpj}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Data da próxima parcela</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.parcel_deadline}</Text>
                                                </Stack>

                                                <Stack spacing="0" opacity={quota.sold ? 0.5 : 1}>
                                                    <Text fontSize="10px" color="gray.800">Índice de reajuste</Text>
                                                    <Text fontSize="sm" fontWeight="semibold" color="gray.800">{quota.adjust_index}</Text>
                                                </Stack>
                                            </HStack>
        
                                            <HStack mt="4" mb="3" justifyContent="space-between">
                                                <Flex alignItems="center">
                                                    <Text fontWeight="500" mr="2">Descrição: </Text>
                                                    <Text> {quota.description && quota.description}</Text>
                                                </Flex>

                                                <HStack spacing="5" alignItems="center">
                                                    <EditButton onClick={() => OpenEditQuotaModal(toEditQuotaData)}/>
                                                    <RemoveButton onClick={() => OpenConfirmQuotaRemoveModal({ id: quota.id, group: quota.group ? quota.group : "", quota: quota.quota ? quota.quota : "" }) }/>
                                                </HStack>
                                            </HStack>

                                            <Divider />

                                            <HStack justifyContent="space-between" alignItems="center" mt="4">
                                                <Table size="sm" variant="simple">
                                                    <Thead>
                                                        <Tr>
                                                            <Th color="gray.900">Valores a apagar: </Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                            <Th></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        
                                                        {
                                                            quota.payments && quota.payments.map((payment) => {
                                                                const paymentToEditData:EditPaymentFormData = {
                                                                    id: payment.id,
                                                                    title: payment.title,
                                                                    value: payment.value.toString().replace('.', ','),
                                                                    paid: payment.paid.toString().replace('.', ','),
                                                                    company: payment.company,
                                                                    category: payment.category,
                                                                    provider: payment.provider,
                                                                    pay_to_user: payment.pay_to_user?.id,
                                                                    //status: payment.status,
                                                                    expire: payment.expire,
                                                                    observation: payment.observation,
                                                                    contract: payment.contract,
                                                                    group: payment.group,
                                                                    quote: payment.quote,
                                                                    recurrence: payment.recurrence,
                                                                    file: payment.file,
                                                                }

                                                                console.log(paymentToEditData);

                                                                return (
                                                                    <Tr>
                                                                        <Td fontSize="12px">{payment.expire && formatBRDate(payment.expire)}</Td>
                                                                        <Td fontSize="12px">{payment.title}</Td>
                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(payment.status ? (payment.paid > 0 ? payment.paid : payment.value) : payment.value - payment.paid)}</Td>
                                                                        <Td color="gray.800" fontWeight="semibold" fontSize="12px">
                                                                            {
                                                                                payment.status ? (
                                                                                    <HStack>
                                                                                        <Flex fontWeight="bold" alignItems="center" color="green.400">
                                                                                            <CheckIcon stroke="#48bb78" fill="none" width="16px"/>
                                                                                            <Text ml="2">Pago</Text>
                                                                                        </Flex>

                                                                                        <IconButton onClick={() => handleReverseBill(payment.id)} h="24px" w="20px" minW="25px" p="0" float="right" aria-label="Excluir categoria" border="none" icon={ <RefreshIcon width="20px" stroke="#14142b" fill="none"/>} variant="outline"/>
                                                                                    </HStack>
                                                                                ) : (
                                                                                    <OutlineButton
                                                                                        h="29px" size="sm" color="green.400" borderColor="green.400" colorScheme="green" fontSize="11" 
                                                                                        onClick={() => OpenPayPaymentModal({ id: payment.id, title: payment.title , value: payment.value, paid: payment.paid, status: payment.status, new_value: ''})}>
                                                                                        Pagar
                                                                                    </OutlineButton>
                                                                                )
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            <IconButton onClick={() => OpenConfirmPaymentRemoveModal({ id: payment.id, title: payment.title }) } h="24px" w="23px" p="0" float="right" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                            <IconButton onClick={() => OpenEditPaymentModal(paymentToEditData)} h="24px" w="23px" p="0" float="right" aria-label="Alterar parcial" border="none" icon={ <EditIcon width="20px" stroke="#d69e2e" fill="none"/>} variant="outline"/>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </HStack>
        
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        )
                    })
                }
                
            </Accordion>

            <SolidButton pos="fixed" bottom="30px" right="30px" color="white" bg="blue.800" onClick={handleOpenSumModal} icon={PlusIcon} colorScheme="blue">
                Somar
            </SolidButton>
        </Stack>
    )
}