import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Badge as ChakraBadge,
    Checkbox,
    Divider,
    Flex,
    HStack,
    IconButton,
    Link,
    Spinner,
    Stack,
    Text,
    useBreakpointValue,
    useToast
  } from '@chakra-ui/react'
  import Badge from '../../../components/Badge'
  import { OutlineButton } from '../../../components/Buttons/OutlineButton'
  import { CompanySelectMaster } from '../../../components/CompanySelect/companySelectMaster'
  import { MainBoard } from '../../../components/MainBoard'
  
  import { ReactComponent as PlusIcon } from '../../../assets/icons/Plus.svg'
  import { ReactComponent as MinusIcon } from '../../../assets/icons/Minus.svg'
  import { ReactComponent as StrongPlusIcon } from '../../../assets/icons/StrongPlus.svg'
  import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg'
  import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg'
  
  import { HasPermission, useProfile } from '../../../hooks/useProfile'
  import { EditButton } from '../../../components/Buttons/EditButton'
  import { RemoveButton } from '../../../components/Buttons/RemoveButton'
  import { SolidButton } from '../../../components/Buttons/SolidButton'
  import { ChangeEvent, useState } from 'react'
  import { LeadsFilterData, useLeads } from '../../../hooks/useLeads'
  import { Customer, DataOrigin, Lead, LeadStatus } from '../../../types'
  import { api } from '../../../services/api'
  import { useEffect } from 'react'
  import { formatBRDate } from '../../../utils/Date/formatBRDate'
  import { getHour } from '../../../utils/Date/getHour'
  import { useUsers } from '../../../hooks/useUsers'
  import { useWorkingCompany } from '../../../hooks/useWorkingCompany'
  import { useWorkingBranch } from '../../../hooks/useWorkingBranch'
import { useCustomers } from '../../../hooks/useCustomers'
import { SearchCustomers } from './SearchCustomers'
import { EditCustomerModal } from './EditCustomerModal'
import { NewCustomerModal } from './NewCustomerModal'
import { useCustomersList } from '../../../hooks/useCustomersList'
  
  export default function MinCustomers() {
    const workingCompany = useWorkingCompany()
    const workingBranch = useWorkingBranch()
  
    const toast = useToast()
    const { permissions, profile } = useProfile()
  
    const isManager = HasPermission(permissions, 'Vendas Completo');
  
    const checkPendingLeads = async () => {
      await api.post('/leads/check')
    }
  
    useEffect(() => {
      checkPendingLeads()
    }, [])
  
    const [origins, setOrigins] = useState<DataOrigin[]>([])
  
    const loadOrigins = async () => {
      const { data } = await api.get('/data_origins')
  
      setOrigins(data)
    }
  
    useEffect(() => {
      loadOrigins()
    }, [])
  
    const [filter, setFilter] = useState<LeadsFilterData>(() => {
      const data: LeadsFilterData = {
        search: '',
        //start_date: formatYmdDate(new Date().toString()),
        //end_date: formatYmdDate(new Date().toString()),
        company: workingCompany.company?.id,
        branch: workingBranch.branch?.id,
        status: 0,
        user: isManager ? undefined : profile?.id,
        group_by: ''
      }
  
      return data
    })
  
    function handleChangeFilter(newFilter: LeadsFilterData) {
      newFilter.user = isManager
        ? newFilter.user
          ? newFilter.user
          : undefined
        : profile?.id
      setFilter(newFilter)
    }

    const [isNewSaleModalOpen, setIsNewSaleModalOpen] = useState(false)
    const [addSaleToCustomerData, setAddSaleToCustomerData] = useState<Customer>()
  
    function OpenNewCustomerModal(customer?: Customer) {
      setAddSaleToCustomerData(customer)
      setIsNewSaleModalOpen(true)
    }
    function CloseNewSaleModal() {
      setIsNewSaleModalOpen(false)
    }

    const customers = useCustomersList(filter, 1);
  
    const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false)
    const [editCustomerFormData, setEditCustomerFormData] = useState<Customer>()
  
    function OpenEditCustomerModal(customerData: Customer) {
      setEditCustomerFormData(customerData)
      setIsEditCustomerModalOpen(true)
    }
    function CloseEditCustomerModal() {
      setIsEditCustomerModalOpen(false)
    }
  
    const pendingCustomersCount = 0;
  
    const isWideVersion = useBreakpointValue({
      base: false,
      lg: true
    })
  
    const [showingFilterMobile, setShowingFilterMobile] = useState(false)
  
    const handleOpenFilter = () => {
      setShowingFilterMobile(true)
    }
  
    const handleCloseFilter = () => {
      setShowingFilterMobile(false)
    }
  
    useEffect(() => {
      setFilter({
        ...filter,
        company: workingCompany.company?.id,
        branch: workingBranch.branch?.id
      })
    }, [workingCompany, workingBranch])

    console.log(customers.data?.data.data);
  
    return (
      <MainBoard sidebar="quotas" header={<CompanySelectMaster />}>
        <NewCustomerModal
          isOpen={isNewSaleModalOpen}
          onRequestClose={CloseNewSaleModal}
          toAddCustomerData={addSaleToCustomerData}
        />

        <EditCustomerModal
          toEditCustomerData={editCustomerFormData}
          afterEdit={customers.refetch}
          isOpen={isEditCustomerModalOpen}
          onRequestClose={CloseEditCustomerModal}
        />
  
        <SolidButton
          color="white"
          bg="blue.800"
          icon={PlusIcon}
          colorScheme="blue"
          mb="10"
          onClick={() => OpenNewCustomerModal()}
        >
          Cadastrar cliente
        </SolidButton>
  
        {isWideVersion ? (
            <SearchCustomers
              filter={filter}
              handleSearchCustomers={handleChangeFilter}
            />
        ) : (
          <>
            <Link
              onClick={showingFilterMobile ? handleCloseFilter : handleOpenFilter}
              mb="12"
            >
              {showingFilterMobile ? 'Fechar Filtro' : 'Filtrar lista'}
            </Link>
  
            {showingFilterMobile && (
              <SearchCustomers
                filter={filter}
                handleSearchCustomers={handleChangeFilter}
              />
            )}
          </>
        )}
  
        {customers.isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : customers.isError ? (
          <Flex justify="center" mt="4" mb="4">
            <Text>Erro listar os clientes</Text>
          </Flex>
        ) : (
            customers.data?.data.data.length === 0 && (
            <Flex justify="center">
              <Text>Nenhuma cliente encontrado.</Text>
            </Flex>
          )
        )}
  
        {!customers.isLoading && !customers.error && (
          <Accordion
            w="100%"
            border="2px"
            borderColor="gray.500"
            borderRadius="26"
            overflow="hidden"
            spacing="0"
            allowMultiple
          >
            <HStack
              spacing="8"
              justify="space-between"
              paddingX="8"
              paddingY="3"
              bg="gray.200"
            >
              <HStack fontSize="sm" spacing="4">
                <Text>{customers.data?.data.data.length} clientes</Text>
              </HStack>
            </HStack>
  
            {customers.data?.data.data.map((customer: Customer) => {
                console.log(customer);
  
              return (
                <AccordionItem
                  key={customer.id}
                  display="flex"
                  flexDir="column"
                  paddingX={[4, 4, 8]}
                  paddingTop="3"
                  bg="white"
                  borderTop="2px"
                  borderTopColor="gray.500"
                  borderBottom="0"
                >
                  {({ isExpanded }) => (
                    <>
                      <HStack justify="space-between" mb="3" pos="relative">
                        <AccordionButton p="0" height="fit-content" w="auto">
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            h="24px"
                            w="30px"
                            p="0"
                            borderRadius="full"
                            border="2px"
                            borderColor="blue.800"
                            variant="outline"
                          >
                            {!isExpanded ? (
                              <StrongPlusIcon
                                stroke="#2a4365"
                                fill="none"
                                width="12px"
                              />
                            ) : (
                              <MinusIcon
                                stroke="#2a4365"
                                fill="none"
                                width="12px"
                              />
                            )}
                          </Flex>
                        </AccordionButton>
  
                        {/* <ControlledCheckbox label="Pendência" control={control} defaultIsChecked={toEditPaymentData.pendency} name="pendency" error={formState.errors.pendency}/> */}
                        
                        {/* <Checkbox
                            label=""
                            name="delegate"
                            checked={delegateList.includes(customer.id)}
                            value={customer.id}
                            onChange={handleSelect}
                        /> */}
  
                        {/* {isWideVersion && (
                          <Stack spacing="0">
                            <Text fontSize="10px" color="gray.800">
                              {getHour(customer.created_at)}
                            </Text>
                            <Text
                              fontSize="sm"
                              fontWeight="normal"
                              color="gray.800"
                            >
                              {formatBRDate(customer.created_at)}
                            </Text>
                          </Stack>
                        )} */}
  
                        <Stack spacing="0">
                          <Text fontSize="sm" fontWeight="bold" color="gray.800">
                            {customer.name}
                          </Text>
                          <Text
                            fontSize="11px"
                            fontWeight="normal"
                            color="gray.800"
                          >
                            {customer.phone}
                          </Text>
                        </Stack>
  
                        <Stack spacing="0">
                          <Text
                            fontSize="sm"
                            fontWeight="normal"
                            color="gray.800"
                          >
                            {customer.city.name}
                          </Text>
                          <Text
                            fontSize="sm"
                            fontWeight="normal"
                            color="gray.800"
                          >
                            {customer.state.name}
                          </Text>
                        </Stack>
  
                        {/* <Text fontSize="sm" fontWeight="normal" color="gray.800">Veículo - R$50.000,00</Text> */}
  
                        <Text
                            fontSize="11px"
                            fontWeight="normal"
                            color="gray.800"
                          >
                            {customer.email}
                        </Text>

                        <EditButton onClick={() => OpenEditCustomerModal(customer)}/>
                      </HStack>
  
                      <AccordionPanel
                        flexDir="column"
                        borderTop="2px"
                        borderColor="gray.500"
                        px="1"
                        py="5"
                      >
                        <Stack
                          spacing="8"
                          direction={['column', 'column', 'column']}
                          justifyContent="space-between"
                          mb="4"
                        >
                            <Stack
                                spacing="8"
                                direction={['column', 'column', 'row']}
                                justifyContent="space-between"
                                mb="4"
                            >
                                <Stack spacing="0">
                                    <Text
                                        fontSize="11px"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        Tipo de cliente
                                    </Text>
                                        <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.type_customer === "PF" ? "Pessoa física" : "Pessoa jurídica" }
                                    </Text>
                                </Stack>

                                <Stack spacing="0">
                                    <Text
                                        fontSize="11px"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        Rua
                                    </Text>
                                        <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.address}
                                    </Text>
                                </Stack>

                                <Stack spacing="0">
                                    <Text
                                        fontSize="11px"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        Bairro
                                    </Text>
                                        <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.neighborhood}
                                    </Text>
                                </Stack>

                                <Stack spacing="0">
                                    <Text
                                        fontSize="11px"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        Número
                                    </Text>
                                        <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.number}
                                    </Text>
                                </Stack>

                                <Stack spacing="0">
                                    <Text
                                        fontSize="11px"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.type_customer === "PF" ? "CPF" : "CNPJ" }
                                    </Text>
                                        <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.cpf_cnpj}
                                    </Text>
                                </Stack>

                                <Stack spacing="0">
                                    <Text
                                        fontSize="11px"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        Estado civil
                                    </Text>
                                        <Text
                                        fontSize="sm"
                                        fontWeight="normal"
                                        color="gray.800"
                                    >
                                        {customer.civil_status}
                                    </Text>
                                </Stack>

                            </Stack>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              )
            })}
          </Accordion>
        )}
      </MainBoard>
    )
  }
  