import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CompanySelectMaster } from "../../../components/CompanySelect/companySelectMaster";
import { MainBoard } from "../../../components/MainBoard";
import { QuotaFilterData, useQuotas } from "../../../hooks/useQuotas";
import { useReadyQuota } from "../../../hooks/useReadyQuota";
import { ReadyQuotaFilterData, useReadyQuotas } from "../../../hooks/useReadyQuotas";
import { useWorkingBranch } from "../../../hooks/useWorkingBranch";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { QuotasCalculatorList } from "./QuotasCalculatorList";
import { SearchQuotas } from "./SearchQuotas";

export default function QuotasCalculator(){
    const workingCompany = useWorkingCompany();
    const workingBranch = useWorkingBranch();

    const [filter, setFilter] = useState<ReadyQuotaFilterData>(() => {
        const data: ReadyQuotaFilterData = {
            search: '',
            company: workingCompany.company?.id,
            branch: workingBranch.branch?.id,
            status: 0,
            sold: 'false',
            is_contemplated: 'true',
        };
        
        return data;
    });

    function handleChangeFilter(newFilter: ReadyQuotaFilterData){
        setFilter(newFilter);
    }

    const [page, setPage] = useState(1);

    const quotas = useReadyQuotas(filter, page);

    const [isNewQuotaModalOpen, setIsNewQuotaModalOpen] = useState(false);

    function OpenNewQuotaModal(){
        setIsNewQuotaModalOpen(true);
    }
    function CloseNewQuotaModal(){
        setIsNewQuotaModalOpen(false);
    }

    useEffect(() => {
        setFilter({...filter, company: workingCompany.company?.id, branch: workingBranch.branch?.id});
    }, [workingCompany, workingBranch]);

    return (
        <MainBoard sidebar="quotas" header={ <CompanySelectMaster/>}>

            <SearchQuotas filter={filter} handleSearchQuotas={handleChangeFilter} />

            {
                    quotas.isLoading ? (
                        <Flex justify="center">
                            <Spinner/>
                        </Flex>
                    ) : ( quotas.isError ? (
                        <Flex justify="center" mt="4" mb="4">
                            <Text>Erro listar as cotas em estoque</Text>
                        </Flex>
                    ) : (quotas.data?.data.length === 0) ? (
                        <Flex justify="center">
                            <Text>Nenhuma cota encontrada.</Text>
                        </Flex>
                    ) : 
                        <QuotasCalculatorList quotas={quotas.data?.data} refetchQuotas={quotas.refetch} />
                    )
            }
            
        </MainBoard>
    )
}