import { Divider, Flex, HStack, IconButton, Link, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { Board } from "../../../components/Board";
import { CompanySelectMaster } from "../../../components/CompanySelect/companySelectMaster";
import { MainBoard } from "../../../components/MainBoard";
import { getQuotas } from "../../../hooks/useQuotas";
import { api } from "../../../services/api";
import { BillCategory, Broker, Coordinator, Customer, Investor, Partner, Quota, ReadyQuota, Supervisor, User } from "../../../types";
import { useReadyQuota } from "../../../hooks/useReadyQuota";
import { ControlledInput } from "../../../components/Forms/Inputs/ControlledInput";

import { useForm, useWatch } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from "../../../components/Forms/Inputs/Input";
import { SolidButton } from "../../../components/Buttons/SolidButton";
import { formatBRDate } from "../../../utils/Date/formatBRDate";
import { RemoveButton } from "../../../components/Buttons/RemoveButton";
import moneyToBackend from "../../../utils/moneyToBackend";
import { useWorkingCompany } from "../../../hooks/useWorkingCompany";
import { useErrors } from "../../../hooks/useErrors";
import { formatInputDate } from "../../../utils/Date/formatInputDate";
import { Select } from "../../../components/Forms/Selects/Select";

import { ReactComponent as TagIcon } from '../../../assets/icons/Tag.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/Back Arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { ReactSelect, SelectOption } from "../../../components/Forms/ReactSelect";

interface QuotaSaleParams{
    quota: string;
}

interface QuotaSaleFormData{
    ready_quota: number;
    sale_date:string;
    company:number;
    value: string;
    seller: string;
    buyer: string;
    cpf_cnpj: string;
    profit?: number;
    tax?: string;
    coordinator_id?: number;
    coordinator_value?: string;
    supervisor_id?: number;
    supervisor_value?: string;
    broker_id?: number;
    broker_value?: string;
    description?: string;

    buyer_type?: string;
    buyer_id?: number;
    salesman_id?: number;

    //profit?: string;
    partner_profit?: number;
}

interface QuotaForSale extends ReadyQuota{
    partner_profit?: number;
    tax?: number;
}

interface SaleReadyQuota{
    id?: number;
    partner_profit?: number;
    tax?: string;
    value?: string;
    profit: string;

    commission_percent: string;
    commission_value: string;

    investor_value: string;
    investor_percent: string;

    coordinator_id?: number;
    coordinator_value?: string;
    supervisor_id?: number;
    supervisor_value?: string;
    broker_id?: number;
    broker_value?: number;
}

interface SaleReadyQuotaList{
    quotas: SaleReadyQuota[]
}

interface QuotaSaleCosts{
    tax?: string;
    coordinator_value?: string;
    supervisor_value?: string;
    commission_value?: string;
    commission_percent?: string;
    profit?: number;
    salesman_commission?: number;
}

interface PaymentOfQuota{
    id?: number;
    title: string;
    value: string;
    observation?:string;
    expire:string;
    company?:number;
    category:number;
    quota_sales_id?: number;
}

const NewQuotaSaleFormSchema = yup.object().shape({
    description: yup.string().nullable(),
    value: yup.string().required("Informe o valor da venda"),
    tax: yup.string().nullable(),

    partner_value: yup.string().nullable(),
    passed_value: yup.string().nullable(),

    coordinator_id: yup.string().nullable(),

    sale_date: yup.string().required("Selecione a data da venda"),

    buyer_id: yup.number().required("Selecione um comprador"),//.transform((v, o) => o === '' ? null : v).nullable(),
    buyer_type: yup.string().required("Selecione o tipo de comprador"),

    salesman_id: yup.number().required("Selecione um vendedor interno"),//.transform((v, o) => o === '' ? null : v).nullable(),
    broker_id: yup.number().required("Selecione um corretor")//.transform((v, o) => o === '' ? null : v).nullable(),
});

const PaymentsOfQuotaSchema = yup.object().shape({
    title: yup.string().required("Qual o título desse recebimento"),
    value: yup.string().required("Informe o valor da venda"),
    expire: yup.string().required("Selecione a data da venda"),
    category: yup.number().required("Selecione uma categoria"),
    observation: yup.string().nullable(),
});

const QuotasForSaleSchema = yup.object().shape({
    id: yup.number().required("Qual a cota selecionada?"),
});

const SaleReadyQuotaSchema = yup.object().shape({
    value: yup.string().required("Informe o valor de venda da carta"),
    commission_percent: yup.string().required("Qual o percentual de comissão?"),
    commission_value: yup.string().required("Informe o valor de venda de comissão da carta"),
    coordinator_value: yup.string().required("Qual a comissão do coordenador"),
    supervisor_value: yup.string().required("Qual a comissão do supervisor"),
    investor_percent: yup.string().required("Qual o percentual do investidor?"),
    investor_value: yup.string().required("Informe o ganho do investidor"),
});

export default function NewQuotaSale(){
    const workingCompany = useWorkingCompany();

    const history = useHistory();
    const toast = useToast();
    const { showErrors } = useErrors();

    const params = useParams<QuotaSaleParams>();

    //const quotaQuery = useReadyQuota(params.quota);

    const [quota, setQuota] = useState<QuotaForSale>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        api.get(`ready_quotas/${params.quota}`).then(response => {
            //console.log(response);
            setQuota(response.data);
            setIsLoading(false);
        });
    }, []);

    //const quota:QuotaForSale = (quotaQuery?.data ? quotaQuery?.data : undefined);

    const [quotasForSale, setQuotasForSale] = useState<QuotaForSale[]>(() => {
        if(quota){
            return [
                quota
            ]
        }

        return [];
    });

    const quotasForSaleForm = useForm<QuotaForSale>({
        resolver: yupResolver(QuotasForSaleSchema)
    });

    const { handleSubmit, register, formState, watch, setValue, control} = useForm<QuotaSaleFormData>({
        resolver: yupResolver(NewQuotaSaleFormSchema),
        defaultValues: {
            value: (quota && quota.value) ? quota.value.toString().replace('.', ',') : '',
        }
    });

    const quotaForm = useForm<SaleReadyQuotaList>({
        resolver: yupResolver(SaleReadyQuotaSchema),
    });

    function handleAddQuota(selectedQuota : ReadyQuota){
        const toAddQuota:QuotaForSale|undefined = quotas?.find(quota => quota.id === selectedQuota.id);

        //payment.value = moneyToBackend(payment.value);
        //payment.title = `[${quota.group}-${quota.quota}] ${payment.title}`;
        //console.log(toAddQuota);

        if(toAddQuota){
            toAddQuota.tax = toAddQuota.credit*0.01;

            const newQuotasForSale = [
                ...quotasForSale,
                toAddQuota
            ]

            const quotaTotalCredit = (newQuotasForSale.length > 0 ? newQuotasForSale.reduce((amount:number, quota) => {return amount + quota.credit}, 0) : 0);

            const percentOfQuotasCredit = (toAddQuota.credit*100)/quotaTotalCredit;

            const value = watch('value') === "" ? "0" : watch('value');
            const brokerValue = watch('broker_value') === "" ? "0" : watch('broker_value');
            const supervisorValue = watch('supervisor_value') === "" ? "0" : watch('supervisor_value');
            const coordinatorValue = watch('coordinator_value') === "" ? "0" : watch('coordinator_value');
            const tax = watch('tax') === "" ? "0" : watch('tax');

            const parsedValue = parseFloat(moneyToBackend(value ? value : '0'));
            const parsedBrokerValue = parseFloat(moneyToBackend(brokerValue ? brokerValue : '0'));
            const parsedSupervisorValue = parseFloat(moneyToBackend(supervisorValue ? supervisorValue : '0'));
            const parsedCoordinatorValue = parseFloat(moneyToBackend(coordinatorValue ? coordinatorValue : '0'));
            const parsedTax = parseFloat(moneyToBackend(tax ? tax : '0'));
            toAddQuota.partner_profit = ((parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax)*((toAddQuota && toAddQuota.partner_percent) ? toAddQuota.partner_percent/100 : 0))*(percentOfQuotasCredit/100);

            setQuotasForSale([
                ...quotasForSale,
                toAddQuota
            ]);
        }
    }

    function handleRemoveQuota(index: number, quotaId: number){
        const updatedQuotas = [...quotasForSale];

        quotaForm.unregister(`quotas.${quotaId}.value` as const);

        updatedQuotas.splice(index, 1);

        setQuotasForSale(updatedQuotas);
    }

    const paymentsForm = useForm<PaymentOfQuota>({
        resolver: yupResolver(PaymentsOfQuotaSchema),
        defaultValues: {
            value: (quota && quota.value) ? quota.value.toString().replace('.', ',') : '',
        }
    });

    const [paymentsOfQuota, setPaymentOfQuota] = useState<PaymentOfQuota[]>([]);

    useEffect(() => {
        setPaymentOfQuota([
            {
                title: "Pagamento à vista", 
                value: (quota && quota.value) ? quota.value.toFixed(2) : '0',
                expire: new Date().toISOString().split("T")[0],
                category: 1
            }
        ])

        if(quota){
            setQuotasForSale([quota])
        }
    }, [quota])

    function handleAddPayment(payment : PaymentOfQuota){
        payment.value = moneyToBackend(payment.value);
        payment.title = quota ? `${payment.title}` : `${payment.title}`;

        setPaymentOfQuota([
            ...paymentsOfQuota,
            payment
        ])
    }

    function handleRemovePayment(index: number){
        const updatedPaymentsOfQuota = [...paymentsOfQuota];

        updatedPaymentsOfQuota.splice(index, 1);

        setPaymentOfQuota(updatedPaymentsOfQuota);
    }

    function includeAndFormatData(quotaSaleData: QuotaSaleFormData){
        quotaSaleData.value = moneyToBackend(quotaSaleData.value);

        quotaSaleData.coordinator_value = moneyToBackend(quotaSaleData.coordinator_value ? quotaSaleData.coordinator_value : '');
        quotaSaleData.supervisor_value = moneyToBackend(quotaSaleData.supervisor_value ? quotaSaleData.supervisor_value : '');
        quotaSaleData.broker_value = moneyToBackend(quotaSaleData.broker_value ? quotaSaleData.broker_value : '');

        //quotaSaleData.partner_profit = ((quotaSaleData.partner_profit != null && quotaSaleData.partner_profit != "") ? moneyToBackend(quotaSaleData.partner_profit) : '');

        quotaSaleData.tax = ((quotaSaleData.tax != null && quotaSaleData.tax != "") ? moneyToBackend(quotaSaleData.tax) : '');

        //const partnerValue = (quotaSaleData.partner_value ? parseFloat(quotaSaleData.partner_value) : 0);

        quotaSaleData.profit = profit; //- partnerValue;
        quotaSaleData.partner_profit = partnerProfit; //- partnerValue;

        quotaSaleData.sale_date = formatInputDate(quotaSaleData.sale_date);

        if(!workingCompany.company){
            return quotaSaleData;
        }
        
        quotaSaleData.company = workingCompany.company?.id;

        return quotaSaleData;
    }

    const handleCreateNewQuotaSale = async (quotaSaleData : QuotaSaleFormData) => {
        try{
            if(!workingCompany.company){
                toast({
                    title: "Ué",
                    description: `Seleciona uma empresa para trabalhar`,
                    status: "warning",
                    duration: 12000,
                    isClosable: true,
                });

                return;
            }

            quotaSaleData = includeAndFormatData(quotaSaleData);
            //quotaSaleData.ready_quota = quota.id

            let billsOfQuota = paymentsOfQuota.map((payment: PaymentOfQuota) => {
                payment.expire = formatInputDate(payment.expire);
                payment.company = workingCompany.company?.id;
                //payment.quota_sales_id = response.data.id;

                //await api.post('/bills/store', payment);
                return payment;
            })

            let registerQuotasForSale = quotasForSale.map((quota: QuotaForSale) => {
                let formedData:SaleReadyQuota = {
                    profit: '',
                    commission_percent: quotaForm.getValues(`quotas.${quota.id}.commission_percent` as const),
                    commission_value: moneyToBackend(quotaForm.getValues(`quotas.${quota.id}.commission_value` as const) ? quotaForm.getValues(`quotas.${quota.id}.commission_value` as const): ''),
                    investor_percent: quotaForm.getValues(`quotas.${quota.id}.investor_percent` as const),
                    investor_value: moneyToBackend(quotaForm.getValues(`quotas.${quota.id}.investor_value` as const) ? quotaForm.getValues(`quotas.${quota.id}.investor_value` as const): ''),
                };

                formedData.id = quota.id;
                formedData.tax = moneyToBackend(quotaForm.getValues(`quotas.${quota.id}.tax` as const) ? quotaForm.getValues(`quotas.${quota.id}.tax` as const): '');
                formedData.partner_profit = quota.partner_profit;

                formedData.value = moneyToBackend(quotaForm.getValues(`quotas.${quota.id}.value` as const) ? quotaForm.getValues(`quotas.${quota.id}.value` as const): '');
                formedData.coordinator_value = moneyToBackend(quotaForm.getValues(`quotas.${quota.id}.coordinator_value` as const) ? quotaForm.getValues(`quotas.${quota.id}.coordinator_value` as const): '');
                formedData.supervisor_value = moneyToBackend(quotaForm.getValues(`quotas.${quota.id}.supervisor_value` as const) ? quotaForm.getValues(`quotas.${quota.id}.supervisor_value` as const): '');

                //console.log('valor da venda', formedData.value);
                const parseOrZero = (value: string) => parseFloat(value) || 0;
                const profitAmount = parseOrZero(formedData.value) - parseOrZero(formedData.coordinator_value) - parseOrZero(formedData.supervisor_value) - parseOrZero(formedData.commission_value) - parseOrZero(formedData.investor_value) - parseOrZero(formedData.tax) - (quota.total_cost ? quota.total_cost : 0);
                formedData.profit = moneyToBackend(profitAmount.toLocaleString('pt-BR', {minimumFractionDigits: 2,maximumFractionDigits: 2}));
                //await api.post('/bills/store', payment);
                return {...quota, ...formedData};
            })

            //console.log({quotaSale:quotaSaleData, bills: billsOfQuota, ready_quotas: registerQuotasForSale});

            const response = await api.post('/quota_sales/store', {quotaSale:quotaSaleData, bills: billsOfQuota, ready_quotas: registerQuotasForSale});

            toast({
                title: "Sucesso",
                description: `As Cotas foi cadastradas como vendidas.`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            history.replace('/venda-contempladas');

        }catch(error:any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    const [categories, setCategories] = useState<BillCategory[]>([]);

    const loadCategories = async () => {
        const { data } = await api.get('/bill_categories');

        setCategories(data);
    }

    const [coordinatorsOptions, setCoordinatorOptions] = useState<SelectOption[]>();

    const loadCoordinators = async () => {
        api.get('/coordinators').then(response => {
            setCoordinatorOptions(response.data.data.map((coordinator: Coordinator) => {
                return {
                    value: coordinator.id,
                    label: coordinator.name
                }
            }))
        });
    }

    const [supervisorsOptions, setSupervisorsOptions] = useState<SelectOption[]>();

    const loadSupervisors = async () => {
        api.get('/supervisors').then(response => {
            setSupervisorsOptions(response.data.data.map((supervisor: Supervisor) => {
                return {
                    value: supervisor.id,
                    label: supervisor.name
                }
            }))
        });
    }

    const [brokersOptions, setBrokersOptions] = useState<SelectOption[]>();

    const loadBrokers = async () => {
        api.get('/brokers').then(response => {
            setBrokersOptions(response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            }))
        });
    }

    const [usersOptions, setUsersOptions] = useState<SelectOption[]>();
    const [partnersOptions, setPartnersOptions] = useState<SelectOption[]>();
    const [investorsOptions, setInvestorsOptions] = useState<SelectOption[]>();
    const [customersOptions, setCustomersOptions] = useState<SelectOption[]>();

    const loadUsers = async () => {
        api.get('/users').then(response => {
            setUsersOptions([{value: "", label: "Selecionar um vendedor"},...response.data.map((user: User) => {
                return {
                    value: user.id,
                    label: user.name
                }
            })])
        });
    }

    const loadPartners = async () => {
        api.get('/partners').then(response => {
            setPartnersOptions([{value: "", label: "Selecionar um parceiro"},...response.data.data.map((partner: Partner) => {
                return {
                    value: partner.id,
                    label: partner.name
                }
            })])
        });
    }

    const loadInvestors = async () => {
        api.get('/investors').then(response => {
            setInvestorsOptions([{value: "", label: "Selecionar um investidor"},...response.data.data.map((investor: Investor) => {
                return {
                    value: investor.id,
                    label: investor.name
                }
            })])
        });
    }

    const loadCustomers = async () => {
        api.get('/customersList').then(response => {
            setCustomersOptions([{value: "", label: "Selecionar um cliente"}, ...response.data.data.map((customer: Customer) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })])
        });
    }

    const [quotas, setQuotas] = useState<ReadyQuota[]>();
    const [quotasOptions, setQuotasOptions] = useState<SelectOption[]>();

    const loadQuotas = async () => {
        api.get('/ready_quotas').then(response => {
            const newQuotas = response.data.filter((readyQuota: ReadyQuota) => {
                if(quotasForSale.find((quotaForSale: ReadyQuota) => {return readyQuota.id === quotaForSale.id})){
                    return false;
                }

                if(readyQuota.group && readyQuota.quota && readyQuota.deadline && readyQuota.purchase_date && readyQuota.parcel && !readyQuota.sold){
                    return true
                }

                return false;
            });

            setQuotas(newQuotas);
        });
    }

    const loadQuotasOptions = async () => {
        if(quotas){
            const newQuotas = quotas.filter((readyQuota: ReadyQuota) => {
                if(quotasForSale.find((quotaForSale: ReadyQuota) => {return readyQuota.id === quotaForSale.id})){
                    return false;
                }
    
                if(readyQuota.group && readyQuota.quota && readyQuota.deadline && readyQuota.purchase_date && readyQuota.parcel && !readyQuota.sold){
                    return true
                }
    
                return false;
            });
    
            setQuotasOptions(newQuotas.map((readyQuota: ReadyQuota) => {
                return {
                    value: readyQuota.id,
                    label: `(${readyQuota.id}) Grupo ${readyQuota.group} - Cota ${readyQuota.quota}`
                }
            }))
        }
    }

    useEffect(() => {
        loadQuotas();
        loadCategories();
        loadCoordinators();
        loadSupervisors();
        loadBrokers();
        loadUsers();
        loadInvestors();
        loadPartners();
        loadCustomers();
    }, []);

    useEffect(() => {
        loadQuotasOptions();
    }, [quotas, quotasForSale]);

    const [profit, setProfit] = useState<number>();
    const [realProfit, setRealProfit] = useState<number>();
    const [partnerProfit, setPartnerProfit] = useState<number>();
    const [coordinatorValue, setCoordinatorValue] = useState<number>();
    const [supervisorValue, setSupervisorValue] = useState<number>();
    const [nullOptions, setNullOptions] = useState<SelectOption[]>([{value: "", label: ""}]);

    const calculateCoordinatorCommission = (value:number) => {
        if(quota){
            const quotaTotalCredit = quota.credit ? quota.credit : 0 + (quotasForSale.length > 0 ? quotasForSale.reduce((amount:number, quota) => {return amount + quota.credit}, 0) : 0);

            const percentOfQuota = (value*100)/quotaTotalCredit;

            const commissionPercent = quota.segment === "Imóvel" ? (percentOfQuota > 34 ? 1 : percentOfQuota > 30 ? 0.75 : percentOfQuota > 28 ? 0.50 : 0.30) : (percentOfQuota > 40 ? 1 : percentOfQuota > 38 ? 0.75 : percentOfQuota > 36 ? 0.50 : 0.30)

            setCoordinatorValue((commissionPercent/100)*quota.credit);
        }
    }

    const getCoordinatorCommission = (quotaForSale: QuotaForSale) => {
        const percentOfQuota = ((quotaForSale.value ? quotaForSale.value : 0)*100)/quotaForSale.credit;

        const commissionPercent = quotaForSale.segment === "Imóvel" ? (percentOfQuota > 34 ? 1 : percentOfQuota > 30 ? 0.75 : percentOfQuota > 28 ? 0.50 : 0.30) : (percentOfQuota > 40 ? 1 : percentOfQuota > 38 ? 0.75 : percentOfQuota > 36 ? 0.50 : 0.30)

        const coordinatorCommission = (commissionPercent/100)*quotaForSale.credit;

        return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(coordinatorCommission);
    }

    const getSupervisorCommission = (quotaForSale: QuotaForSale) => {
        const percentOfQuota = ((quotaForSale.value ? quotaForSale.value : 0)*100)/quotaForSale.credit;

        const commissionPercent = quotaForSale.segment === "Imóvel" ? (percentOfQuota > 34 ? 0.50 : percentOfQuota > 30 ? 0.37 : percentOfQuota > 28 ? 0.25 : 0.15) : (percentOfQuota > 40 ? 0.50 : percentOfQuota > 38 ? 0.37 : percentOfQuota > 36 ? 0.25 : 0.15)

        const supervisorCommission = (commissionPercent/100)*quotaForSale.credit;

        return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(supervisorCommission);
    }

    const calculateSupervisorCommission = (value:number) => {
        if(quota){
            const percentOfQuota = (value*100)/quota.credit;

            const commissionPercent = quota.segment === "Imóvel" ? (percentOfQuota > 34 ? 0.50 : percentOfQuota > 30 ? 0.37 : percentOfQuota > 28 ? 0.25 : 0.15) : (percentOfQuota > 40 ? 0.50 : percentOfQuota > 38 ? 0.37 : percentOfQuota > 36 ? 0.25 : 0.15)
        
            setSupervisorValue((commissionPercent/100)*quota.credit);
        }
    }

    useEffect(() => {
        const value = watch('value') === "" ? "0" : watch('value');
        const brokerValue = watch('broker_value') === "" ? "0" : watch('broker_value');
        const supervisorValue = watch('supervisor_value') === "" ? "0" : watch('supervisor_value');
        const coordinatorValue = watch('coordinator_value') === "" ? "0" : watch('coordinator_value');
        const tax = watch('tax') === "" ? "0" : watch('tax');

        const parsedValue = parseFloat(moneyToBackend(value ? value : '0'));
        const parsedBrokerValue = parseFloat(moneyToBackend(brokerValue ? brokerValue : '0'));
        const parsedSupervisorValue = parseFloat(moneyToBackend(supervisorValue ? supervisorValue : '0'));
        const parsedCoordinatorValue = parseFloat(moneyToBackend(coordinatorValue ? coordinatorValue : '0'));
        const parsedTax = parseFloat(moneyToBackend(tax ? tax : '0'));

        calculateCoordinatorCommission(parsedValue);
        calculateSupervisorCommission(parsedValue);

        let quotaTotalCost = 0

        if(quota){
            const quotaTotalCredit = quota.credit ? quota.credit : 0 + (quotasForSale.length > 0 ? quotasForSale.reduce((amount:number, quota) => {return amount + quota.credit}, 0) : 0);
            quotaTotalCost = quota.total_cost ? quota.total_cost : 0 + (quotasForSale.length > 0 ? quotasForSale.reduce((amount:number, quota) => {return amount + (quota.total_cost ? quota.total_cost : 0)}, 0) : 0);

            quotasForSale.map((quota) => {
                const percentOfQuotasCredit = (quota.credit*100)/quotaTotalCredit;
                quota.partner_profit = ((parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax)*((quota && quota.partner_percent) ? quota.partner_percent/100 : 0))*(percentOfQuotasCredit/100);
            
                return quota
            });
        }

        //setProfit(parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax);
        setProfit(parsedValue);
        setRealProfit(parsedValue - quotaTotalCost - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax);
        setPartnerProfit((parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax)*((quota && quota.partner_percent) ? quota.partner_percent/100 : 0));
    }, [watch('value'), watch('broker_value'), watch('supervisor_value'), watch('coordinator_value'), watch('tax')]);

    const [saleAmount, setSaleAmount] = useState('');

    // useEffect(() => {
    //     const values = quotaForm.watch('value') as unknown;

    //     if(values){
    //         const quotaValues:string[] = values as string[]

    //         console.log(quotaValues.reduce((amount:number, value) => {return amount + parseFloat(moneyToBackend(value))}, 0).toString(), quotaValues);
    //         const totalSales = quotaValues.reduce((amount:number, value) => {return amount + parseFloat(moneyToBackend(value))}, 0);

    //         setSaleAmount(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalSales));
    //     }
        
    // }, [quotaForm.watch('quotas'), quotasForSale])

    const updateAmounts = () => {
        const value = watch('value') === "" ? "0" : watch('value');
        const brokerValue = watch('broker_value') === "" ? "0" : watch('broker_value');
        const supervisorValue = watch('supervisor_value') === "" ? "0" : watch('supervisor_value');
        const coordinatorValue = watch('coordinator_value') === "" ? "0" : watch('coordinator_value');
        const tax = watch('tax') === "" ? "0" : watch('tax');

        const parsedValue = parseFloat(moneyToBackend(value ? value : '0'));
        const parsedBrokerValue = parseFloat(moneyToBackend(brokerValue ? brokerValue : '0'));
        const parsedSupervisorValue = parseFloat(moneyToBackend(supervisorValue ? supervisorValue : '0'));
        const parsedCoordinatorValue = parseFloat(moneyToBackend(coordinatorValue ? coordinatorValue : '0'));
        const parsedTax = parseFloat(moneyToBackend(tax ? tax : '0'));

        calculateCoordinatorCommission(parsedValue);
        calculateSupervisorCommission(parsedValue);

        let quotaTotalCost = 0

        if(quota){
            const quotaTotalCredit = quota.credit ? quota.credit : 0 + (quotasForSale.length > 0 ? quotasForSale.reduce((amount:number, quota) => {return amount + quota.credit}, 0) : 0);
            quotaTotalCost = quota.total_cost ? quota.total_cost : 0 + (quotasForSale.length > 0 ? quotasForSale.reduce((amount:number, quota) => {return amount + (quota.total_cost ? quota.total_cost : 0)}, 0) : 0);

            quotasForSale.map((quota) => {
                const percentOfQuotasCredit = (quota.credit*100)/quotaTotalCredit;
                quota.partner_profit = ((parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax)*((quota && quota.partner_percent) ? quota.partner_percent/100 : 0))*(percentOfQuotasCredit/100);
            
                return quota
            });
        }

        const quotasForSaleSpent = quotasForSale.reduce((amount:number, quota) => {return amount + (quota.total_cost ? quota.total_cost : 0)}, 0);

        //const profit = parsedValue - 

        setProfit(parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax);
        setRealProfit(parsedValue - quotaTotalCost - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax);
        setPartnerProfit((parsedValue - parsedBrokerValue - parsedSupervisorValue - parsedCoordinatorValue - parsedTax)*((quota && quota.partner_percent) ? quota.partner_percent/100 : 0));
    }

    // Use o useWatch para observar os valores das cartas
    const quotasFields = useWatch({
        control: quotaForm.control,
        name: 'quotas', // Observa o objeto 'cartas'
    });

    const [quotaSaleCosts, setQuotaSaleCosts] = useState<QuotaSaleCosts[]>([]);
    //const prevValoresCartasRef = useRef<{ [key: number]: number }>({});

    useEffect(() => {
        const totalSales = Object.values(quotasFields || {}).reduce((acc, curr) => 
            acc + (parseFloat(moneyToBackend(curr.value ? curr.value : '')) || 0
        ),0);

        const newCommissions: QuotaSaleCosts[] = [];

        // Object.keys(quotasFields || {}).forEach((quotaId) => {
        //     const quota = quotasForSale.find(c => c.id === Number(quotaId));
        //     const entryValue = quotasFields?.[Number(quotaId)]?.value;
      
        //     if (quota && entryValue) {
        //       // Implementa o cálculo da comissão do coordenador
        //         const quotaTotalCredit = quota.credit + (quotasForSale.length > 0 ? quotasForSale.reduce((amount: number, quota) => {
        //             return amount + quota.credit;
        //         }, 0) : 0);
        
        //         const percentOfQuota = (parseFloat(entryValue) * 100) / quotaTotalCredit;
        
        //         const coordinatorCommissionPercent = quota.segment === "Imóvel"
        //             ? (percentOfQuota > 34 ? 1 : percentOfQuota > 30 ? 0.75 : percentOfQuota > 28 ? 0.50 : 0.30)
        //             : (percentOfQuota > 40 ? 1 : percentOfQuota > 38 ? 0.75 : percentOfQuota > 36 ? 0.50 : 0.30);
        
        //         const coordinatorCommission = (coordinatorCommissionPercent / 100) * quota.credit;

        //         const supervisorCommissionPercent = quota.segment === "Imóvel" ? (percentOfQuota > 34 ? 0.50 : percentOfQuota > 30 ? 0.37 : percentOfQuota > 28 ? 0.25 : 0.15) : (percentOfQuota > 40 ? 0.50 : percentOfQuota > 38 ? 0.37 : percentOfQuota > 36 ? 0.25 : 0.15)
        
        //         const supervisorCommission = (supervisorCommissionPercent/100)*quota.credit;
      
        //         newCommissions[Number(quotaId)] = {
        //             coordinator_value: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(coordinatorCommission),
        //             supervisor_value: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(supervisorCommission),
        //             //supervisor_value: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(supervisorCommission),
        //         }
        //     }
        // });

        Object.keys(quotasFields || {}).forEach((quotaId) => {
            const quotaField = quotasFields[Number(quotaId)];
            const soldValue = quotaField.value ? parseFloat(moneyToBackend(quotaField.value)) : 0
            const quota = quotasForSale.find(c => c.id === Number(quotaId));

            const coordinatorCommission = quotaField.coordinator_value ? parseFloat(moneyToBackend(quotaField.coordinator_value)) : 0
            const supervisorCommission = quotaField.supervisor_value ? parseFloat(moneyToBackend(quotaField.supervisor_value)) : 0
            const salesmanCommission = quotaField.commission_value ? parseFloat(moneyToBackend(quotaField.commission_value)) : 0
            const investorCommission = quotaField.investor_value ? parseFloat(moneyToBackend(quotaField.investor_value)) : 0
            const tax = quotaField.tax ? parseFloat(moneyToBackend(quotaField.tax)) : 0

            if(quota){
                newCommissions[Number(quotaId)] = {
                    profit: soldValue - coordinatorCommission - supervisorCommission - salesmanCommission - investorCommission - tax - (quota.total_cost ? quota.total_cost : 0),
                    salesman_commission: salesmanCommission
                }
            }
        })

        setQuotaSaleCosts(newCommissions);

        setSaleAmount(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(totalSales));
        updateAmounts();
    }, [quotasFields, quotasForSale]); //quotasFields, quotasForSale

    return(
        <MainBoard sidebar="quotas" header={ <CompanySelectMaster/>}>
            <Link href="/contempladas" display="flex" flexDirection="row" mb="10">
                <BackArrow width="20px" stroke="#4e4b66" fill="none"/>
                <Text ml="4">Voltar para as cotas</Text>
            </Link>

                {
                        isLoading ? (
                            <Flex justify="center">
                                <Spinner/>
                            </Flex>
                        ) : (
                                quota ? (
                                    <>
                                        <Stack mb="20" spacing="4" px="5" py="6" border="2px solid" borderColor="gray.200" borderRadius="24" as="form" onSubmit={quotasForSaleForm.handleSubmit(handleAddQuota)}>
                                            <Text fontWeight="bold" fontSize="lg">Adicionar cotas a venda</Text>
                                            <HStack>
                                                {
                                                    quotasOptions && (
                                                        <ReactSelect options={quotasOptions} placeholder="Cota" control={quotasForSaleForm.control} label="Cota" name="id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={quotasForSaleForm.formState.errors.id}/>
                                                    )
                                                }

                                                <SolidButton mr="6" color="white" bg="blue.800" colorScheme="blue" type="submit" isLoading={quotasForSaleForm.formState.isSubmitting}>
                                                    Adicionar
                                                </SolidButton>
                                            </HStack>
                                        </Stack>

                                        <HStack justifyContent="space-between" align="baseline">
                                            <Text mb="6" fontWeight="bold" fontSize="lg">Cotas a venda</Text>

                                            <HStack align="baseline">
                                                {
                                                    quotasForSale && quotasForSale.map((quota) => {
                                                        return(
                                                            <Text key={quota.id} mb="6" fontWeight="bold" fontSize="lg">{quota.group}-{quota.quota}, </Text>
                                                        )
                                                    })
                                                }
                                            </HStack>
                                        </HStack>

                                        <Board mb="12">
                                            <Stack spacing="4">
                                                {/* <Stack>
                                                    <HStack justifyContent="space-between">
                                                        <Stack spacing="0">
                                                            <Text fontSize="sm" color="gray.800">Grupo e cota:</Text>
                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{quota.group}-{quota.quota}</Text>
                                                        </Stack>

                                                        <Stack spacing="0">
                                                            <Text fontSize="sm" color="gray.800">Crédito:</Text>
                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.credit)}</Text>
                                                        </Stack> 

                                                        <Stack spacing="0">
                                                            <Text fontSize="sm" color="gray.800">Custo da empresa:</Text>
                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.cost ? quota.cost : 0)}</Text>
                                                        </Stack> 

                                                        <Stack spacing="0">
                                                            <Text fontSize="sm" color="gray.800">Custo do parceiro:</Text>
                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_cost ? quota.partner_cost : 0)}</Text>
                                                        </Stack>

                                                        <Stack spacing="0">
                                                            <Text fontSize="sm" color="gray.800">Custo total:</Text>
                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.total_cost ? quota.total_cost : 0)}</Text>
                                                        </Stack>

                                                        <Stack spacing="0">
                                                            <Text fontSize="sm" color="gray.800">Faturamento do parceiro:</Text>
                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partnerProfit ? partnerProfit : 0)}</Text>
                                                        </Stack>
                                                    </HStack>

                                                    <HStack justifyContent="space-between" color="gray.600">
                                                        <Text fontSize={"sm"}>% pago pelo crédito: {quota.paid_percent}% </Text>
                                                        <Text fontSize={"sm"}>Percentual do parceiro: {quota.partner_percent}% </Text>
                                                    </HStack>
                                                </Stack> */}

                                                    {
                                                        quotasForSale && quotasForSale.map((quota, index) => {
                                                            const coordinatorCommission = getCoordinatorCommission(quota)
                                                            const supervisorCommission = getSupervisorCommission(quota)

                                                            const thisQuotaField = quotasFields ? quotasFields.find((quotaField, id) => id === quota.id) : (quota?.value ? {value: quota?.value.toLocaleString()} : {value: '0'});
                                                            const quotaValue = parseFloat(thisQuotaField?.value ? moneyToBackend(thisQuotaField.value) : '0');

                                                            //const profit = quotaSaleCosts[quota.id];
                                                            //const quotaValue = quota.value ? quota.value : 0;
                                                            const investorPercent = quota.investor_percent ? quota.investor_percent : 0;
                                                            const investorValue = quotaValue * (investorPercent / 100);

                                                            const gainPercent = (((quotaSaleCosts[quota.id] ? (quotaSaleCosts[quota.id].profit ?? 0) : 0) / quota.credit) * 100)
                                                            const soldPercent = (quotaValue/quota.credit)*100

                                                            return(
                                                                <>
                                                                    <HStack key={quota.id} spacing="3" w="100%">
                                                                        <Stack spacing="3" w="100%">
                                                                            <HStack spacing="2">
                                                                                <Text fontSize="sm" color="gray.800">Grupo e cota: </Text>
                                                                                <Text fontSize="sm" color="gray.800" fontWeight="bold">{quota.group}-{quota.quota}</Text>
                                                                            </HStack>
                                                                            <HStack spacing="4">
                                                                                <HStack spacing="2">
                                                                                    <ControlledInput control={quotaForm.control} value={quota.value ? quota.value.toFixed(2).replace('.', ',') : ''} mask="money" name={`quotas.${quota.id}.value`} type="text" placeholder="Valor (Entrada)" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.value} focusBorderColor="blue.800"/>
                                                                                    <ControlledInput control={quotaForm.control} value={coordinatorCommission} mask="money" name={`quotas.${quota.id}.coordinator_value`} type="text" placeholder="Comissão do coordenador" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.coordinator_value} focusBorderColor="blue.800"/>
                                                                                    <ControlledInput control={quotaForm.control} value={supervisorCommission} mask="money" name={`quotas.${quota.id}.supervisor_value`} type="text" placeholder="Comissão do supervisor" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.supervisor_value} focusBorderColor="blue.800"/>
                                                                                    <ControlledInput control={quotaForm.control} value={''} mask="money" name={`quotas.${quota.id}.tax`} type="text" placeholder="Taxa de Transferência" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.tax} focusBorderColor="blue.800"/>
                                                                                </HStack>
                                                                            </HStack>
                                                                            <HStack spacing="4" justifyContent="space-between">
                                                                                <HStack spacing="2">
                                                                                    <ControlledInput control={quotaForm.control} value={''} mask="number" name={`quotas.${quota.id}.commission_percent`} type="text" placeholder="Percentual de comissão" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.commission_percent} focusBorderColor="blue.800"/>
                                                                                    <ControlledInput control={quotaForm.control} value={''} mask="money" name={`quotas.${quota.id}.commission_value`} type="text" placeholder="Valor de comissão" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.commission_value} focusBorderColor="blue.800"/>

                                                                                    <ControlledInput control={quotaForm.control} value={''} mask="number" name={`quotas.${quota.id}.investor_percent`} type="text" placeholder="Percentual do investidor" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.commission_value} focusBorderColor="blue.800"/>
                                                                                    <ControlledInput control={quotaForm.control} value={''} mask="money" name={`quotas.${quota.id}.investor_value`} type="text" placeholder="Ganho do Investidor" variant="outline" error={quotaForm.formState.errors.quotas?.[quota.id]?.commission_value} focusBorderColor="blue.800"/>
                                                                                </HStack>
                                                                            </HStack>
                                                                            <HStack spacing="4" justifyContent="space-between">
                                                                                <HStack spacing="0" border="1px solid" borderColor="gray.300" p="2" px="4" borderRadius="full">
                                                                                    <Text fontSize="sm" color="gray.800">Lucro: </Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSaleCosts[quota.id] ? (quotaSaleCosts[quota.id].profit ?? 0) : 0)}</Text>
                                                                                </HStack>

                                                                                <HStack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Percentual de ganho: </Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold"> {gainPercent.toLocaleString('pt-BR', {minimumFractionDigits: 2,maximumFractionDigits: 2,})}%</Text>
                                                                                </HStack>
                                                                            </HStack>
                                                                            <HStack justifyContent="space-between">
                                                                                <Stack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Crédito:</Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.credit)}</Text>
                                                                                </Stack>

                                                                                <Stack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Custo da empresa:</Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.cost ? quota.cost : 0)}</Text>
                                                                                </Stack>

                                                                                <Stack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Custo do parceiro:</Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_cost ? quota.partner_cost : 0)}</Text>
                                                                                </Stack>

                                                                                <Stack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Custo do investidor:</Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.investor_cost ? quota.investor_cost : 0)}</Text>
                                                                                </Stack>

                                                                                <Stack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Custo total:</Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.total_cost ? quota.total_cost : 0)}</Text>
                                                                                </Stack>

                                                                                <Stack spacing="0">
                                                                                    <Text fontSize="sm" color="gray.800">Faturamento do parceiro:</Text>
                                                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_profit ? quota.partner_profit : 0)}</Text>
                                                                                </Stack>
                                                                            </HStack>

                                                                            <HStack justifyContent="space-between" color="gray.600">
                                                                                <Text fontSize={"sm"}>% pago pelo crédito: {quota.paid_percent}% </Text>
                                                                                <Text fontSize={"sm"}>% da venda: {soldPercent.toLocaleString('pt-BR', {minimumFractionDigits: 2,maximumFractionDigits: 2,})}% </Text>
                                                                                <Text fontSize={"sm"}>Percentual do parceiro: {quota.partner_percent}% </Text>
                                                                            </HStack>
                                                                        </Stack>

                                                                        <Stack spacing="0">
                                                                            {/* <Text fontSize="sm" color="gray.800">Remover:</Text> */}
                                                                            <Text fontSize="sm" color="gray.800" fontWeight="bold">
                                                                                <IconButton onClick={() => handleRemoveQuota(index, quota.id)} h="24px" w="23px" p="0" float="right" aria-label="Excluir pagamento parcial" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                                                                            </Text>
                                                                        </Stack>
                                                                    </HStack>

                                                                    <Divider />
                                                                </>
                                                            )
                                                        })
                                                    }
                                            </Stack>
                                        </Board>

                                        <HStack mb="7" justifyContent={"space-between"}>
                                            <Stack spacing="0">
                                                <Text fontSize="sm" color="gray.800">Faturamento:</Text>
                                                <Text fontSize="sm" color="gray.800" fontWeight="bold">{ profit ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(profit) : '--'}</Text>
                                            </Stack>

                                            {/* <Stack spacing="0">
                                                <Text fontSize="sm" color="gray.800">Lucro:</Text>
                                                <Text fontSize="sm" color="gray.800" fontWeight="bold">{ realProfit ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(realProfit) : '--'}</Text>
                                            </Stack> */}

                                            <Stack spacing="0">
                                                <Text fontSize="sm" color="gray.800">Lucro Total:</Text>
                                                <Text fontSize="sm" color="gray.800" fontWeight="bold">{ quotaSaleCosts.reduce((amount:number, cost) => {return amount + (cost.profit ?? 0)}, 0) ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSaleCosts.reduce((amount:number, cost) => {return amount + (cost.profit ?? 0)}, 0)) : '--'}</Text>
                                            </Stack>

                                            <HStack spacing="6">
                                                {/* <Stack spacing="0">
                                                    <Text fontSize="sm" color="gray.800">Custo do parceiro:</Text>
                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_cost ? quota.partner_cost : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="sm" color="gray.800">Percentual do parceiro:</Text>
                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quota.partner_percent ? quota.partner_percent : 0)}</Text>
                                                </Stack>

                                                <Stack spacing="0">
                                                    <Text fontSize="sm" color="gray.800">Faturamento do parceiro:</Text>
                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(partnerProfit ? partnerProfit : 0)}</Text>
                                                </Stack> */}
                                            </HStack>
                                        </HStack>

                                        <Divider mb="8"/>

                                        <Stack id="quotaSale" as="form" spacing="6" mb="14" onSubmit={handleSubmit(handleCreateNewQuotaSale)}>
                                            <HStack spacing="4" align="center">
                                                <ControlledInput control={control} value={saleAmount} mask="money" name="value" type="text" placeholder="Valor Total da Venda" variant="outline" error={formState.errors.value} focusBorderColor="blue.800"/>
                                            
                                                <Input register={register} name="sale_date" type="date" placeholder="Data da venda" variant="outline" error={formState.errors.sale_date} focusBorderColor="blue.800"/>
                                            
                                                {
                                                    usersOptions && (
                                                        <ReactSelect options={usersOptions} placeholder="Interno" control={control} label="Contato" name="salesman_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.salesman_id}/>
                                                    )
                                                }

                                                {
                                                    brokersOptions && (
                                                        <ReactSelect options={brokersOptions} placeholder="Corretor" control={control} label="Contato" name="broker_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.broker_id}/>
                                                    )
                                                }
                                            
                                                {/* <Input register={register} name="profit" type="text" placeholder="Lucro" variant="outline" error={formState.errors.profit} focusBorderColor="blue.800"/> */}
                                            </HStack>

                                            <HStack spacing="4" align="center">
                                                {/* <Input register={register} name="tax" type="text" placeholder="Taxa" variant="outline" error={formState.errors.tax} focusBorderColor="blue.800"/> */}
                                                {/* <ControlledInput control={control} value={quota.tax ? quota.tax.toFixed(2).replace('.', ',') : ''} name="tax" type="text" placeholder="Taxa" mask="money" variant="outline" error={formState.errors.tax} focusBorderColor="blue.800"/> */}

                                                <Select register={register} isRequired h="45px" name="buyer_type" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" error={formState.errors.buyer_type}>
                                                    <option value="" selected>Comprado por</option>
                                                    <option value="App\Models\Partners">Comprado por Parceiro</option>
                                                    <option value="App\Models\Brokers">Comprado por Corretor</option>
                                                    <option value="App\Models\Investor">Comprado por Investidor</option>
                                                    <option value="App\Models\Customers">Comprado por Cliente</option>
                                                </Select>

                                                {
                                                    (watch("buyer_type") === "") && (
                                                        <ReactSelect options={nullOptions} disabled={true} placeholder="Selecione" control={control} label="Contato" name="buyer_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full" error={formState.errors.buyer_id}/>
                                                    )
                                                }

                                                {
                                                    (partnersOptions && watch("buyer_type") == "App\\Models\\Partners") && (
                                                        <ReactSelect options={partnersOptions} placeholder={watch('buyer_type') === "" ? "Selecione o Tipo" : "Selecione o Parceiro"} disabled={watch('buyer_type') === "" ? true : false} control={control} label="Contato" name="buyer_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.buyer_id}/>
                                                    )
                                                }

                                                {
                                                    (brokersOptions && watch("buyer_type") == "App\\Models\\Brokers") && (
                                                        <ReactSelect options={brokersOptions} placeholder={watch('buyer_type') === "" ? "Selecione o Tipo" : "Selecione o Corretor"} disabled={watch('buyer_type') === "" ? true : false} control={control} label="Contato" name="buyer_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.buyer_id}/>
                                                    )
                                                }

                                                {
                                                    (investorsOptions && watch("buyer_type") == "App\\Models\\Investor") && (
                                                        <ReactSelect options={investorsOptions} placeholder={watch('buyer_type') === "" ? "Selecione o Tipo" : "Selecione o Investidor"} disabled={watch('buyer_type') === "" ? true : false} control={control} label="Contato" name="buyer_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.buyer_id}/>
                                                    )
                                                }

                                                {
                                                    (customersOptions && watch("buyer_type") == "App\\Models\\Customers") && (
                                                        <ReactSelect options={customersOptions} placeholder={watch('buyer_type') === "" ? "Selecione o Tipo" : "Selecione o Cliente"} disabled={watch('buyer_type') === "" ? true : false} control={control} label="Contato" name="buyer_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.buyer_id}/>
                                                    )
                                                }

                                                {/* <ControlledInput control={control} value={Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSaleCosts.reduce((amount:number, costs) => {return amount + (costs.salesman_commission ? costs.salesman_commission : 0)}, 0))} name="broker_value" type="text" placeholder="Comissão do vendedor" mask="money" variant="outline" error={formState.errors.broker_value} focusBorderColor="blue.800"/> */}
                                                <Stack spacing="0" minW="200px">
                                                    <Text fontSize="sm" color="gray.800">Comissão total:</Text>
                                                    <Text fontSize="sm" color="gray.800" fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(quotaSaleCosts.reduce((amount:number, costs) => {return amount + (costs.salesman_commission ? costs.salesman_commission : 0)}, 0))}</Text>
                                                </Stack>
                                            </HStack>

                                            {/* <HStack spacing="4" align="center">
                                                {
                                                    coordinatorsOptions && (
                                                        <ReactSelect options={coordinatorsOptions} placeholder="Coordenador" control={control} label="Contato" name="coordinator_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.coordinator_id}/>
                                                    )
                                                }

                                                <ControlledInput control={control} name="coordinator_value" value={coordinatorValue ? coordinatorValue.toFixed(2).replace('.', ',') : ''} type="text" placeholder="Comissão coordenador" mask="money" variant="outline" error={formState.errors.coordinator_value} focusBorderColor="blue.800"/>

                                                {
                                                    supervisorsOptions && (
                                                        <ReactSelect options={supervisorsOptions} placeholder="Supervisor" control={control} label="Contato" name="supervisor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.supervisor_id}/>
                                                    )
                                                }

                                                <ControlledInput control={control} value={supervisorValue ? supervisorValue.toFixed(2).replace('.', ',') : ''} name="supervisor_value" type="text" placeholder="Comissão do supervisor" mask="money"  variant="outline" error={formState.errors.supervisor_value} focusBorderColor="blue.800"/>
                                            </HStack> */}

                                            {/* <HStack spacing="4" align="center">
                                                 {
                                                    brokersOptions && (
                                                        <ReactSelect options={brokersOptions} placeholder="Corretor" control={control} label="Contato" name="broker_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.broker_id}/>
                                                    )
                                                } 

                                                 <Input register={register} name="broker_id" type="text" placeholder="Corretor" variant="outline"error={formState.errors.broker_id} focusBorderColor="blue.800"/> 

                                                <ControlledInput control={control} name="broker_value" type="text" placeholder="Comissão corretor" mask="money" variant="outline" error={formState.errors.broker_value} focusBorderColor="blue.800"/>
                                            
                                                 <Input register={register} name="partner_value" type="text" placeholder="Comissão do Parceiro" variant="outline" mask="money" error={formState.errors.partner_value} focusBorderColor="blue.800"/>
                                            </HStack> */}

                                            <HStack spacing="4" align="center">
                                                <Input register={register} name="description" type="text" placeholder="Descrição" variant="outline" error={formState.errors.description} focusBorderColor="blue.800"/>
                                            </HStack>
                                        </Stack>

                                        <HStack justifyContent="space-between" align="baseline">
                                            <Text mb="6" fontWeight="bold" fontSize="lg">Pagamentos a receber</Text>
                                        </HStack>

                                        <Board background="transparent" border="1px solid" borderColor="gray.300" boxShadow="none">
                                            {
                                                paymentsOfQuota && paymentsOfQuota.map((paymentOfQuota:PaymentOfQuota, index: number) => {
                                                    const paymentCategory:undefined|BillCategory = categories.filter(obj => obj.id === paymentOfQuota.category)[0];

                                                    return (
                                                        <Stack key={`${paymentOfQuota.expire}-${paymentOfQuota.value}-${index}`} borderBottom="1px solid" pb="4" borderColor="gray.300" mb="14" spacing="4">
                                                            <HStack spacing="12" justifyContent="space-between">
                                                                <Text fontWeight="bold">{formatBRDate(new Date(paymentOfQuota.expire).toISOString())}</Text>

                                                                <Text>{paymentOfQuota.title}</Text>

                                                                <Text fontWeight="bold">{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL' }).format(parseFloat(paymentOfQuota.value))}</Text>

                                                                <Flex fontWeight="500" alignItems="center" color="gray.800" fontSize="sm">
                                                                    <TagIcon stroke="#4e4b66" fill="none" width="17px"/>
                                                                    <Text ml="2">{paymentCategory ? paymentCategory.name : ''}</Text>
                                                                </Flex>
                                                            
                                                                <RemoveButton alignSelf="right" onClick={() => handleRemovePayment(index)}/>
                                                            </HStack>

                                                            <Text fontWeight="regular" fontSize="sm">{paymentOfQuota.observation}</Text>
                                                        </Stack>
                                                    );
                                                })
                                            }

                                            {/* <Divider/> */}

                                            <Stack as="form" onSubmit={paymentsForm.handleSubmit(handleAddPayment)}>
                                                <Stack spacing="6">
                                                    <Text>Adicionar recebimento</Text>

                                                    <HStack>
                                                        <ControlledInput control={paymentsForm.control} name="title" type="text" placeholder="Título" variant="outline" error={paymentsForm.formState.errors.title} focusBorderColor="blue.800"/>

                                                        <ControlledInput control={paymentsForm.control} name="value" type="text" placeholder="Valor" variant="outline" mask="money" error={paymentsForm.formState.errors.value} focusBorderColor="blue.800"/>
                                                        
                                                        <ControlledInput control={paymentsForm.control} name="expire" type="date" placeholder="Data do pagamento" variant="outline" error={paymentsForm.formState.errors.expire} focusBorderColor="blue.800"/>
                                                    </HStack>

                                                    <HStack>

                                                        <Select register={paymentsForm.register} h="45px" value="0" name="category" w="100%" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="outline" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full" placeholder="Categoria" error={paymentsForm.formState.errors.category}>
                                                            {categories && categories.map((category:BillCategory) => {
                                                                return (
                                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                                )
                                                            })}
                                                        </Select>

                                                        <ControlledInput control={paymentsForm.control} name="observation" type="text" placeholder="Observação" variant="outline" error={paymentsForm.formState.errors.observation} focusBorderColor="blue.800"/>

                                                        <SolidButton mr="6" color="white" bg="blue.800" colorScheme="blue" type="submit" isLoading={formState.isSubmitting}>
                                                            Adicionar
                                                        </SolidButton>
                                                    </HStack>
                                                </Stack>
                                            </Stack>
                                        </Board>
                                    </>
                                ) : (
                                    <Text>Nenhuma cota encontrada.</Text>
                                )
                            )
                }
            

            <SolidButton form="quotaSale" mt="14" mr="6" color="white" bg="green.500" colorScheme="green" type="submit" isLoading={formState.isSubmitting}>
                Cadastrar Venda
            </SolidButton>
        </MainBoard>
    )
}