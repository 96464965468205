import { useQuery } from "react-query";
import { api } from "../services/api";

export const getInvestors = async () => {
    const { data } = await api.get('/investors');

    return data.data;
}

export function useInvestors(){
    return useQuery(['investors'], () => getInvestors(), {
        staleTime: 1000 * 5 * 60, //fresh
    });
}