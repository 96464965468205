import { SolidButton } from "../../../components/Buttons/SolidButton";
import { MainBoard } from "../../../components/MainBoard";

import { Coordinator, Provider } from "../../../types";

import { ReactComponent as PlusIcon } from '../../../assets/icons/Plus.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/Back Arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';

import { Flex, HStack, Link, SimpleGrid, Text } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import { Input } from "../../../components/Forms/Inputs/Input";
import {  useState } from "react";
import { api } from "../../../services/api";

import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from "@chakra-ui/toast";
import { useErrors } from "../../../hooks/useErrors";
import { useHistory } from "react-router";
import { Spinner } from "@chakra-ui/spinner";
import { ConfirmCoordinatorRemoveModal } from "./ConfirmCoordinatorRemoveModal";
import { useProviders } from "../../../hooks/useProviders";
import { usePartners } from "../../../hooks/usePartners";
import { useCoordinators } from "../../../hooks/useCoordinators";
import { EditCoordinatorModal } from "./EditCoordinatorModal";

interface CreateNewCoordinatorFormData{
    name: string;
}

const CreateNewCoordinatorFormSchema = yup.object().shape({
    name: yup.string().required('Insira um nome para o coordenador.'),
});

export default function Coordinators(){
    const coordinators = useCoordinators();
    const toast = useToast();
    const history = useHistory();
    const { showErrors } = useErrors();

    const [isEditProviderModalOpen, setIsEditProviderModalOpen] = useState(false);
    const [editProviderData, setEditProviderData] = useState<Provider>(() => {

        const data: Provider = {
            name: '',
            id: 0,
        };
        
        return data;
    });

    function OpenEditProviderModal(providerId: number){
        handleChangeProvider(providerId);
        setIsEditProviderModalOpen(true);
    }
    function CloseEditProviderModal(){
        setIsEditProviderModalOpen(false);
    }

    const [ProviderId, setProviderId] = useState(0);
    const [isConfirmProviderRemoveModalOpen, setIsConfirmCoordinatorRemoveModalOpen] = useState(false);

    function OpenConfirmCoordinatorRemoveModal(providerId: number){
        setProviderId(providerId);
        setIsConfirmCoordinatorRemoveModalOpen(true);
    }
    function CloseConfirmProviderRemoveModal(){
        setIsConfirmCoordinatorRemoveModalOpen(false);
    }


    function handleChangeProvider(providerId:number){
        const selectedProviderData = coordinators.data.filter((provider:Provider) => provider.id === providerId)[0];

        setEditProviderData(selectedProviderData);
    }

    const { register, handleSubmit, reset, formState} = useForm<CreateNewCoordinatorFormData>({
        resolver: yupResolver(CreateNewCoordinatorFormSchema),
    });

    const handleCreateProvider = async (providerData: CreateNewCoordinatorFormData) => {

        try{
            await api.post('/coordinators', providerData);

            toast({
                title: "Sucesso",
                description: `O coordenador ${providerData.name} foi cadastrado`,
                status: "success",
                duration: 12000,
                isClosable: true,
            });

            reset();
            coordinators.refetch();
        }catch(error:any) {
            showErrors(error, toast);

            if(error.response.data.access){
                history.push('/');
            }
        }
    }

    return (
        <MainBoard sidebar="quotas" header={
            (
                <HStack>
                    <Link href="/contempladas"><BackArrow width="20px" stroke="#4e4b66" fill="none"/></Link>
                    <Text color="gray.800" ml="4">
                        / Coordenadores
                    </Text>
                </HStack>
            )
        }>
            <EditCoordinatorModal afterEdit={coordinators.refetch} toEditCoordinatorData={editProviderData} isOpen={isEditProviderModalOpen} onRequestClose={CloseEditProviderModal}/>
            <ConfirmCoordinatorRemoveModal afterRemove={coordinators.refetch} toRemovePartnerId={ProviderId} isOpen={isConfirmProviderRemoveModalOpen} onRequestClose={CloseConfirmProviderRemoveModal}/>


            <HStack as="form" spacing="4" mb="10" onSubmit={handleSubmit(handleCreateProvider)}>
                <Input name="name" register={register} type="text" placeholder="Coordenador" variant="outline" maxW="200px" error={formState.errors.name}/>
                <SolidButton type="submit" mb="10" color="white" bg="blue.700" icon={PlusIcon} colorScheme="blue">
                    Adicionar
                </SolidButton>
            </HStack>

            <SimpleGrid columns={3} minChildWidth="260px" gap={6}>
            {
                !coordinators.data ? (
                    <Flex justify="center" flexWrap="wrap">
                        <Spinner/>
                    </Flex>
                ) : coordinators.data.map((coordinator:Coordinator) => {
                    return (
                        <Flex key={coordinator.name} w="100%" justify="space-between" fontWeight="500" alignItems="center" bg="white" borderRadius="full" shadow="xl" h="54px" px="8">
                            <Flex alignItems="center" cursor="pointer" onClick={() => OpenEditProviderModal(coordinator.id)}>
                                <Text mx="4">{coordinator.name}</Text>
                            </Flex>
                            
                            <IconButton onClick={() => OpenConfirmCoordinatorRemoveModal(coordinator.id)} h="24px" w="23px" p="0" float="right" aria-label="Excluir coordenador" border="none" icon={ <CloseIcon width="20px" stroke="#C30052" fill="none"/>} variant="outline"/>
                        </Flex>
                    )
                })
            }
            </SimpleGrid>
        </MainBoard>
    );
}