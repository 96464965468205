import { QuotaFilterData } from "../../../hooks/useQuotas";

import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Flex, Stack, HStack } from "@chakra-ui/react";
import { Input } from "../../../components/Forms/Inputs/Input";
import { Select } from "../../../components/Forms/Selects/Select";
import { OutlineButton } from "../../../components/Buttons/OutlineButton";
import { QuotaSaleFilterData } from "../../../hooks/useQuotaSales";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { ReactSelect, SelectOption } from "../../../components/Forms/ReactSelect";
import { Broker, Coordinator, Partner, Supervisor } from "../../../types";

interface SearchQuotasProps{
    filter: QuotaSaleFilterData;
    handleSearchQuotas: (filter: QuotaSaleFilterData) => void;
}

const FilterQuotasFormSchema = yup.object().shape({
    search: yup.string(),
    start_date: yup.string(),
    end_date: yup.string(),
    category: yup.string(),
    company: yup.string(),
    contract: yup.string(),
    group: yup.string(),
    quote: yup.string(),
    status: yup.string(),
    pay_to_user: yup.string(),
    cancelled: yup.string(),
    partner_id: yup.string(),
    coordinator_id: yup.string(),
    broker_id: yup.string(),
    supervisor_id: yup.string()
});

export function SearchSales({filter, handleSearchQuotas}: SearchQuotasProps){

    const { register, handleSubmit, control, reset, formState} = useForm<QuotaSaleFilterData>({
        resolver: yupResolver(FilterQuotasFormSchema),
    });

    const [partnerOptions, setPartnerOptions] = useState<SelectOption[]>([{
        value: 0,
        label: "Selecionar parceiro"
    }]);

    const loadPartners = async () => {
        api.get('/partners').then(response => {
            setPartnerOptions(response.data.data.map((partner: Partner) => {
                return {
                    value: partner.id,
                    label: partner.name
                }
            }))
        });
    }

    const [coordinatorsOptions, setCoordinatorOptions] = useState<SelectOption[]>([{
        value: 0,
        label: "Selecionar coordenador"
    }]);

    const loadCoordinators = async () => {
        api.get('/coordinators').then(response => {
            setCoordinatorOptions([...[{value: "",label: "Selecionar coordenador"}],...response.data.data.map((coordinator: Coordinator) => {
                return {
                    value: coordinator.id,
                    label: coordinator.name
                }
            })])
        });
    }

    const [supervisorsOptions, setSupervisorsOptions] = useState<SelectOption[]>([{
        value: 0,
        label: "Selecionar supervisor"
    }]);

    const loadSupervisors = async () => {
        api.get('/supervisors').then(response => {
            setSupervisorsOptions([...[{value: "",label: "Selecionar supervisor"}], ...response.data.data.map((supervisor: Supervisor) => {
                return {
                    value: supervisor.id,
                    label: supervisor.name
                }
            })])
        });
    }

    const [brokersOptions, setBrokersOptions] = useState<SelectOption[]>([{
        value: 0,
        label: "Selecionar corretor"
    }]);

    const loadBrokers = async () => {
        api.get('/brokers').then(response => {
            setBrokersOptions([...[{value: "",label: "Selecionar corretor"}],...response.data.data.map((broker: Broker) => {
                return {
                    value: broker.id,
                    label: broker.name
                }
            })])
        });
    }

    useEffect(() => {
        loadPartners();
        loadCoordinators();
        loadSupervisors();
        loadBrokers();
    }, []);

    return (
        <Flex as="form" mb="20" onSubmit={handleSubmit(handleSearchQuotas)}>

            <Stack spacing="6" w="100%">
                <HStack spacing="6">
                    <Input register={register} name="search" type="text" placeholder="Procurar" variant="filled" error={formState.errors.search} focusBorderColor="blue.800"/>

                    <Input register={register} name="start_date" type="date" placeholder="Data Inicial" variant="filled" error={formState.errors.start_date} focusBorderColor="blue.800"/>
                    <Input register={register} name="end_date" type="date" placeholder="Data Final" variant="filled" error={formState.errors.end_date} focusBorderColor="blue.800"/>

                    <Select register={register} defaultValue={0} h="45px" name="segment" error={formState.errors.segment} w="100%" maxW="200px" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="filled" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full">
                        <option value="">Todos</option>
                        <option value="Imóvel">Imóvel</option>
                        <option value="Veículo">Veículo</option>
                    </Select>

                </HStack>

                <HStack spacing="6">
                    <Input register={register} name="group" type="text" placeholder="Grupo" variant="filled" error={formState.errors.group} focusBorderColor="blue.800"/>
                        
                    <Input register={register} name="quote" type="text" placeholder="Cota" variant="filled" error={formState.errors.quote} focusBorderColor="blue.800"/>
                    <Input register={register} name="contemplated_type" type="text" placeholder="Contrato" variant="filled" error={formState.errors.contemplated_type} focusBorderColor="blue.800"/>

                    <Select register={register} defaultValue="" h="45px" name="cancelled" error={formState.errors.cancelled} w="100%" maxW="200px" fontSize="sm" focusBorderColor="blue.800" bg="gray.400" variant="filled" _hover={ {bgColor: 'gray.500'} } size="lg" borderRadius="full">
                        <option value="">Todos</option>
                        <option value="false">Concluídas</option>
                        <option value="true">Canceladas</option>
                    </Select>
                </HStack>

                <HStack>
                    {
                        partnerOptions && (
                            <ReactSelect options={partnerOptions} placeholder="Parceiro" control={control} label="Parceiro" name="partner_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.partner_id}/>
                        )
                    }

{
                        coordinatorsOptions && (
                            <ReactSelect options={coordinatorsOptions} placeholder="Coordenador" control={control} label="Coordenador" name="coordinator_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.coordinator_id}/>
                        )
                    }

{
                        supervisorsOptions && (
                            <ReactSelect options={supervisorsOptions} placeholder="Supervisor" control={control} label="Supervisor" name="supervisor_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.supervisor_id}/>
                        )
                    }

{
                        brokersOptions && (
                            <ReactSelect options={brokersOptions} placeholder="Corretor" control={control} label="Corretor" name="broker_id" bg="gray.400" variant="outline" _hover={{ bgColor: 'gray.500' }} borderRadius="full"error={formState.errors.broker_id}/>
                        )
                    }

                    <OutlineButton type="submit" mb="10" color="blue.800" borderColor="blue.800" colorScheme="blue">
                        Filtrar
                    </OutlineButton>
                </HStack>
            </Stack>

        </Flex>
    )
}